import React from "react";
import { Modal } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";
import _ from "lodash";

export default class PredictionConfirmation extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  selectedOptionName(options, question_option_id) {
    var name = "";
    _.map(options, (item, idx) => {
      if (item.contest_question_option_id == question_option_id) {
        name = item.name;
      }
    });
    return name;
  }

  render() {
    const {
      IsPredictionCheckShow,
      IsPredictionCheckHide,

    } = this.props;

    const {
      ContestData,
      QuestionList,
      isPredictionEdit,
      NextAction
    } = this.props.predConfrmData

    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsPredictionCheckShow}
              onHide={IsPredictionCheckHide}
              bsSize="large"
              dialogClassName="contest-detail-modal prediction-modal"
            >
              <Modal.Header>
                <Modal.Title>
                  <img src={Images.LOGO} alt="" className="vissel-img" />
                  <a
                    href
                    onClick={IsPredictionCheckHide}
                    className="modal-close"
                  >
                    <i className="icon-close" />
                  </a>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="heading-section-sm">
                  {ContestData.match_data.home}
                  <span> v </span>
                  {ContestData.match_data.away}
                </div>
                <div className="prediction-quiz">
                  <div className="edit-prediction-text">
                    Tap on category to change/edit your prediction
                  </div>
                  <div>
                    {_.map(QuestionList, (item, index) => {
                      return item.question_type == "radio" ? (
                        <ul key={index} className="quiz-section">
                          <li onClick={IsPredictionCheckHide}>
                            <div className="numbers-text">{index + 1}</div>
                          </li>
                          <li className="team-score-list" onClick={IsPredictionCheckHide}>
                            {this.selectedOptionName(
                              item.options,
                              item.question_option_id
                            ) == ContestData.match_data.home ? (
                                <img
                                  src={ContestData.match_data.home_flag}
                                  alt=""
                                />
                              ) : this.selectedOptionName(
                                item.options,
                                item.question_option_id
                              ) == ContestData.match_data.away ? (
                                  <img
                                    src={ContestData.match_data.away_flag}
                                    alt=""
                                  />
                                ) : (
                                  <i className="icon-stop blocked" />
                                )}

                            <div className="que-name">{item.question_name}</div>
                            <div className="quiz-query">
                              {this.selectedOptionName(
                                item.options,
                                item.question_option_id
                              ) == ContestData.match_data.home
                                ? ContestData.match_data.home_team ||
                                ContestData.match_data.home
                                : this.selectedOptionName(
                                  item.options,
                                  item.question_option_id
                                ) == ContestData.match_data.away
                                  ? ContestData.match_data.away_team ||
                                  ContestData.match_data.away
                                  : this.selectedOptionName(
                                    item.options,
                                    item.question_option_id
                                  )}
                            </div>
                          </li>

                        </ul>
                      ) : (
                          <ul key={index} className="quiz-section">
                            <li onClick={IsPredictionCheckHide} >
                              <div className="numbers-text">{index + 1}</div>
                            </li>
                            <li className="team-score-list" onClick={IsPredictionCheckHide}>
                              <img
                                src={ContestData.match_data.home_flag}
                                alt=""
                                className="fl1"
                              />

                              <img
                                src={ContestData.match_data.away_flag}
                                alt=""
                                className="fl2"
                              />

                              <div className="que-name">{item.question_name}</div>
                              <div className="quiz-query preview-final">
                                <div style={{ marginRight: 20 }}>
                                  {ContestData.match_data.home_team ||
                                    ContestData.match_data.home}{" "}
                                  - {item.user_home_score}
                                </div>
                                <div>
                                  {ContestData.match_data.away_team ||
                                    ContestData.match_data.away}{" "}
                                  - {item.user_away_score}
                                </div>
                              </div>
                            </li>
                          </ul>
                        );
                    })}
                  </div>
                </div>

                <a
                  href
                  onClick={() => {
                    NextAction();
                    IsPredictionCheckHide();
                  }}
                  className="btn-block btn-primary bottom btn btn-default"
                >
                  {isPredictionEdit ? "Submit" : "Watch Video & Submit"}
                </a>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
