import React, { Component } from 'react';
import { MyProvider } from './context';
import Main from './Main';
import './assets/scss/style.scss';
import CacheBuster from './CacheBuster';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCZFAxmS1fdqkDZ0l1ZHqs3EEDHNa8mNqc",
  authDomain: "refchat-65440.firebaseapp.com",
  databaseURL: "https://refchat-65440-default-rtdb.firebaseio.com",
  projectId: "refchat-65440",
  storageBucket: "refchat-65440.appspot.com",
  messagingSenderId: "97954112605",
  appId: "1:97954112605:web:5600a2d820e898f352d9b4",
  measurementId: "G-KE8X7BZ6XD"
};

firebase.initializeApp(firebaseConfig);

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <MyProvider>
              <BrowserRouter>
                <Switch>
                  <Route exact component={Main} />
                  <Redirect from="*" to='/' />
                </Switch>
              </BrowserRouter>

            </MyProvider>

          )

        }}
      </CacheBuster>
    );
  }
}

export default App;
