import React, { Component } from "react";
import WSManager from "./helper/WSManager";
import * as translationEN from "./locale/en";
import * as translationAR from "./locale/ar_AE";
import Login from "./Modals/login";
import Thankyou from "./Modals/Thankyou";
export const MyContext = React.createContext();
const history = require("history").createBrowserHistory;

export class MyProvider extends Component {
  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  getCookie = cname => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  state = {
    language: this.getCookie("lang") === "" ? "en" : this.getCookie("lang"),
    locale: "",
    format: "MMM D,ddd, h:mm a",
    loggedInStatus: WSManager.loggedIn(),
    userInfo: WSManager.getProfile(),

    LoginModalShow: false,
    ThankyouModalShow: false,
    AppData: "",
    AppDataPosting: false,
    BucketData: {}
  };

  modalShow = (Modal, BucketData) => {
    this.setState({
      [Modal]: true,
      BucketData: BucketData || {}
    });
  };
  modalHide = (Modal, BucketData) => {
    this.setState({
      [Modal]: false,
      BucketData: BucketData || {}
    });
  };

  // LOCALE METHOD(S)
  selectedLocale = language => {
    let lang = "";
    switch (language) {
      case "en":
        lang = translationEN.default;
        break;

      default:
        lang = translationAR.default;
        break;
    }
    return lang;
  };

  changeLanguage = e => {
    document.documentElement.setAttribute("lang", e);
    document.documentElement.setAttribute("dir", e == "ar" ? "rtl" : "ltr");
    this.setCookie("lang", e, 365);
    this.setState({
      language: e,
      locale: this.selectedLocale(e)
    });
    // NC.Language.update(this.selectedLocale(e));
    history.go();
  };

  // LIFECYLE METHOD(s)
  componentWillMount = async () => {
    document.documentElement.setAttribute("lang", this.state.language);
    document.documentElement.setAttribute(
      "dir",
      this.state.language == "ar" ? "rtl" : "ltr"
    );
    this.setCookie("lang", this.state.language, 365);
    this.setState({
      locale: this.selectedLocale(this.state.language)
    });
  };

  render() {
    const { LoginModalShow, ThankyouModalShow } = this.state;
    // Context Props
    const ContextProps = {
      state: this.state,
      locale: this.state.locale,

      changeLanguage: this.changeLanguage,
      changeDocumentTitle: this.changeDocumentTitle,

      modalShow: this.modalShow,
      setCookie: this.setCookie,
      getCookie: this.getCookie
    };
    // Modal(s) Props
    const LoginProps = {
      modalShow: this.modalShow,
      modalHide: this.modalHide,
      LoginModalShow: this.state.LoginModalShow,
      BucketData: this.state.BucketData,
      loggedInStatusUpdate: this.loggedInStatusUpdate
    };
    const ThankyouProps = {
      modalShow: this.modalShow,
      modalHide: this.modalHide,
      ThankyouModalShow: this.state.ThankyouModalShow
    };

    return (
      <MyContext.Provider value={ContextProps}>
        {this.props.children}
        {/* MODALS */}
        {LoginModalShow && <Login {...LoginProps} />}
        {ThankyouModalShow && <Thankyou {...ThankyouProps} />}
      </MyContext.Provider>
    );
  }
}
