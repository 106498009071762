import validator from "validator";

export default class Validation {
  static validate(type, value) {
    let isValidate = null;
    switch (type) {
      case "required":
        isValidate = validator.isEmpty(value) ? "error" : "success";
        break;
      case "email":
        isValidate = validator.isEmail(value) ? "success" : "error";
        break;
      case "password":
        isValidate =
          value.length >= 4 && value.length <= 50 ? "success" : "error";
        break;
      case "first_name":
      case "last_name":
        isValidate =
          value.length >= 1 && value.length <= 50 && validator.isAlpha(value)
            ? "success"
            : "error";
        break;
      case "team_name":
        isValidate = value.length >= 4 ? "success" : "error";
        break;
      case "contest_name":
        isValidate = value.length >= 3 && value.length <= 50 ? "success" : "error";
        break;
      case "userName":
        isValidate =
          value.length >= 2 && value.length <= 12 ? "success" : "error";
        break;
      case "referral":
        isValidate = value.length >= 3 ? "success" : "error";
        break;

      case "user_name":
        isValidate =
          value.length >= 2 &&
            value.length <= 12 &&
            value.match(/^[a-zA-Z]*[a-zA-Z0-9]*[a-zA-Z0-9_.-]*$/)
            ? "success"
            : "error";
        break;
      case "phone_no":
        isValidate =
          // value.length == 11 && value.match(/^[0][1-9]\d{9}$|^[1-9]\d{9}$/)
          value.length <= 11 && value.length >= 6
            ? "success"
            : "error";
        break;
      case "otp":
        isValidate =
          value.length == 6 &&
            value.match(
              /^(?:(\+)?([0-9]{1,3})?[-.● ]?)?\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/
            )
            ? "success"
            : "error";
        break;

      case "url":
        isValidate = value.match(
          /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/
        )
          ? "success"
          : "error";
        break;
      default:
        break;
    }
    return isValidate;
  }
}
