import React from "react";
import { Modal, Form, ControlLabel, Button, FormGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MyContext } from "../context";
import { RKFloatingLabelInput } from "../components";

export default class WithdrawModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accPin: "",
      isForm: props.isFrom || "withdraw"
    };
    this.onChangePin = this.onChangePin.bind(this);
  }
  onSubmit = e => {
    if (this.state.accPin.length < 4) {
      e.preventDefault();
    } else {
      this.props.onSubmit(e);
      this.props.IsWithdrawModalHide();
    }
  }
  onChangePin(e) {
    this.setState({ accPin: e.target.value });
    this.props.onChange(e);
  }

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            bsSize={"sm"}
            show={this.props.IsWithdrawModalShow}
            onHide={() => this.props.IsWithdrawModalHide()}
            className="verify-modal refpay-web-container center-modal"
          >
            <Modal.Header>
              <Modal.Title>
                {this.state.isForm}
                {this.state.isForm != 'withdraw' && <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-right">
                      <strong>
                        {this.state.isForm == 'transfer' ? 'You can transfer the amount within ReFPredictor site to other users.' : 'You can gift subscription to your friends from here.'}
                      </strong>
                    </Tooltip>
                  }
                >
                  <i className="icon-info cursor-pointer tooltip-info" />
                </OverlayTrigger>}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={this.onSubmit} className="form-input-center">
                <FormGroup className="input-label-center input-transparent font-14" controlId="formInlineName">
                  <ControlLabel>
                    Enter your PIN to {this.state.isForm}{this.state.isForm == 'subscribe' ? '' : ' amount'}.
                    </ControlLabel>
                  {/* <FormControl type="password" onChange={this.onChangePin} /> */}
                  <RKFloatingLabelInput
                    className="rkfloatingLabelInput withdraw m-t-n"
                    id="pin"
                    autocomplete="off"
                    type="password"
                    isNumberOnly={true}
                    name="pin"
                    value={this.state.accPin}
                    placeholder=""
                    onChange={this.onChangePin}
                    maxLength={4}
                  />
                </FormGroup>
              </Form>
              <div className="input-suggestion">PIN must be 4 digit</div>
              <Button
                onClick={this.onSubmit}
                className="btn btn-rounded btn-primary text-white"
                disabled={this.state.accPin.length < 4}
                type="submit"
              >
                Confirm
                </Button>
            </Modal.Body>
            <i
              className="icon-close icc"
              onClick={() => this.props.IsWithdrawModalHide()}
            />
          </Modal>
        )}
      </MyContext.Consumer>
    );
  }
}
