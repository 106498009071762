import React from "react";
import { MyContext } from "../context";

export default class NoDataScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const {
      Content
    } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            {Content ? <div
              className={
                "no-data-outer-wrapper" + (Content.WithFilter == 1 ? ' no-data-outer-wrapper-lg' : '') + (Content.WithFilter == 2 ? ' no-data-outer-wrapper-xlg' : ''
                )}>
              <div className="no-data-container text-center">
                <div className="background-image">
                  <img src={Content.ImgShown} alt="blank" className="bg-image" />
                </div>
                <h3>{Content.Title}</h3>
                <h3>{Content.NoDataContent}</h3>
                <div>
                  {Content.IsButton == true &&
                    <a
                      // href
                      onClick={() => Content.BtnAction()}
                      className="btn btn-rounded btn-primary"
                    >
                      {Content.ButtonText}
                    </a>
                  }
                </div>
              </div>
            </div> : ''}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

