import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import ConfigureStore from './ReduxStore/ConfigureStore';
import { Provider } from 'react-redux';

window.jQuery = $;
require('bootstrap')


const store = ConfigureStore();



ReactDOM.render(
    <Provider store={store}><App /></Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
