import React from 'react';
import { MyContext } from "../context";
import { Images,TextEditor } from '../components';

import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import ReactHtmlParser from 'react-html-parser';
import UtilityFunctions from '../helper/UtilityFunctions';
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import WSManager from "../helper/WSManager";
import Emojis from 'emoji-picker-react';

class CommentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: WSManager.getProfile(),
            showEmojis: false,
            feedIndex: props.feedIndex,
            feedItem: props.feedItem,
        }

    }

   


  



  
    render() {



        const { feedItem, replyLists, selectedItem, hasMoreReply, commentReply, selectedData, showHideTxt, showEditorwrapper,isShown,toggleCommentHandler } = this.props
        const { showEmojis} = this.state
        
        let editorhandler = null;
        if (showEditorwrapper) {
          editorhandler = (
          <div className="editor-div">
                  <div className="create-post-footer-actions">
                        <div>
                          <i className={`icon-text ${!isShown ? 'active' : ''}`} onClick={() => this.props.showToolbar()} />
                          <i className="icon-aligment" />
                          <label htmlFor="upload-button">
                            <i className="icon-attach" />
                          </label>
                          {/* <input
                            type="file"
                            accept="image/x-png, image/jpeg,image/jpg"
                            ref={(ref) => this.uploadPostRef = ref}
                            multiple
  
                            onChange={this.onChangeOfMedia}
                            id="upload-button"
                            style={{ display: "none" }}
                          /> */}
                          
                          <i className={`icon-smile ${!isShown ? 'active' : ''}`} onClick={() => this.props.showToolbar()} />
                          {
                            showEmojis && <div className='emojis' id='emojis'>
                              <img src={Images.CLOSE_ICON} className='cursor-p cross' onClick={() => { this._hideEmojis() }} />
  
                              <Emojis
                                disableSearchBar={true}
                                disableAutoFocus={true}
                                preload={false}
                                skinTone={'SKIN_TONE_LIGHT'}
                                onEmojiClick={this._onEmojisClicked}
                                id='emojis'
                              />
                            </div>
                          }
                        </div>
                       
                      </div>
          </div>
          );
        }

        
        return (

            <MyContext.Consumer>

                {context => (
                    <React.Fragment>
                        <div className={'feed-box comments-list'}>

                            <div className={'feed-hdr'}>
                                <div className={'l-p'}>
                                    <span className={'prof-pic'}>
                                        <img src={feedItem.user_image != null && feedItem.user_image !== "" ? feedItem.user_image : Images.DEFAULT_USER} />
                                    </span>
                                    {/* <div className={'prof-det'}>
                                        <h6 Style={'cursor: pointer;'} onClick={() => this.props.goToUserProfile((feedItem.user_id))}>{feedItem.user_name}</h6>
                                        <p>{feedItem.post_comment}</p>



                                    </div> */}
                                     <div className={'prof-det'}>
                                        <h6 Style={'cursor: pointer;'} onClick={() => this.props.goToUserProfile((feedItem.parent_activity_id && feedItem.parent_activity_id != '' && feedItem.parent_activity_id != '0' ? feedItem.parent_user_id :feedItem.user_id))}>{feedItem.display_name}</h6>
                                        {/* <p>{feedItem.post_comment}</p> */}
                                        <div className={'usrnm-lb'}>{'@' + feedItem.user_name}</div>
                                        <div className={'tm-ago'}>{UtilityFunctions.getUtcToLocalFromNow(feedItem.created_date)}</div>
                                    </div>
                                </div>
                                {feedItem.user_id !== WSManager.getProfile().user_id &&  <div className={'r-p'}>
                                     
                                        <span   
                                        className={'ad-ic'}><i className={ feedItem.is_follower == 0 ?
                                            'icon-refer-ic' : 'icon-user-select'
                                        } /></span>
                                    
                                    <span>
                                        <UncontrolledDropdown direction={'left'} className={'act-dropdown'}>
                                            <DropdownToggle> <i className={'icon-toggle-vertical'} /></DropdownToggle>
                                            <DropdownMenu>
                                                <div><i className={'icon-hide'} />Hide</div>
                                                <div><i className={'icon-block'} />Block</div>
                                                <div><i className={'icon-report'} />Report</div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </span>
                                </div>}


                            </div>
                            <div className="feed-cntnt">
                                <div className={'content-text'}>
                                    {ReactHtmlParser(feedItem.post_comment)}
                                </div>
                            </div>
                            <div className={'feed-footer comments'}>
                                <div className='row-container'>
                                    <div className="item-container">
                                    {/* <div className='items'>
                                            <span className="time">{UtilityFunctions.getUtcToLocalFromNow(feedItem.created_date)}</span>
                                        </div> */}
                                    <div className="items">
                                        <i onClick={() => this.props.commentLike(feedItem)} className={"icon-like1  " + (feedItem.is_like == 0 ? '' : 'active')}></i>
                                            {/* <i className={feedItem.is_like == 0 ? ' icon-like-empty' : 'icon-like11'} ></i> */}
                                            <span>
                                               
                                                {feedItem.no_of_likes > 0 ? " (" + feedItem.no_of_likes + ")" : ''}
                                            </span>
                                        </div>
                                        <div className = {'items'}>
                                        <i  className={`icon-repost ${Number(feedItem.is_share) !== 0 ? ' ':''}`}></i>
                                        <span>{feedItem.no_of_share}</span>
                                        </div>
                                        <div className="items">
                                            <i className="icon-repost scale-icon" onClick={() => this.props.showReply(this.props.feedIndex)}></i>
                                            <span>
                                        
                                                {feedItem.no_of_replies > 0 ? " (" + feedItem.no_of_replies + ")" : ''}
                                            </span>

                                        </div>
                                        <div className = {'items'}>
                                                   <i className="icon-share-fill"></i>
                                            </div>
                                            <div className = {'items'}>
                                            {
                                                <i className="icon-bookmaker" ></i>

                                            }
                                            
                                            </div>
                                        {/* <div className="items">
                                            <i className="icon-comment-fill   scale-icon" ></i>
                                            <span>
                                                {feedItem.no_of_replies > 0 ? " (" + feedItem.no_of_replies + ")" : ''}
                                            </span>

                                        </div>  */}
                                    </div>
                                </div>
                            {/* </div>
                            <div className="feed-footer comments"> */}


                                {/* <div className='row-container'>
                                    <div className="item-container">
                                        <div className='items'>
                                            <span className="time">{UtilityFunctions.getUtcToLocalFromNow(feedItem.created_date)}</span>
                                        </div>
                                         <div className={'content-text'}>
                                         <p>{feedItem.post_comment}</p>
                                </div>
                                        <div className="items">
                                        <i onClick={() => this.props.commentLike(feedItem)} className={"icon-like1 lg " + (feedItem.is_like == 0 ? '' : 'active')}></i>
                                            <i className={feedItem.is_like == 0 ? ' icon-like-empty' : 'icon-like11'} ></i>
                                            <span>
                                                {feedItem.no_of_likes > 0 ? " (" + feedItem.no_of_likes + ")" : ''}
                                            </span>
                                        </div>
                                        <div className="items">
                                            <i className="icon-repost scale-icon" onClick={() => this.props.showReply(this.props.feedIndex)}></i>
                                            <span>
                                                {feedItem.no_of_replies > 0 ? " (" + feedItem.no_of_replies + ")" : ''}
                                            </span>

                                        </div>
                                        <div className="items">
                                            <i className="icon-comment-fill   scale-icon" onClick={() => this.props.showReply(this.props.feedIndex)}></i>
                                            <span>
                                                {feedItem.no_of_replies > 0 ? " (" + feedItem.no_of_replies + ")" : ''}
                                            </span>

                                        </div>
                                    </div>
                                </div> */}

                            </div>
                            <div>
                            {
                                    Number(selectedData) === Number(this.props.feedIndex) &&
                                    <div className="feed-comment-box">
                                        <div className="user-comment">
                                        <div className="left-icon-div">
                                            <i className="icon-plus" onClick={()=>toggleCommentHandler()}></i>

                                        </div>
                                            {/* <img src={
                                                profile.image != null && profile.image !== ""
                                                    ? profile.image
                                                    : Images.DEFAULT_USER
                                            } alt="" /> */}
                                             {/* <img src={Images.CHAT_SMILE} className='cursor-p ' onClick={() => { this._hideEmojis() }} />
                                                {
                                                    this.state.showEmojis && <div className='emojis' id='emojis'>
                                                    <img src={Images.CLOSE_ICON} className='cursor-p cross' onClick={() => {this._hideEmojis()}} />

                                                    <Emojis
                                                        disableSearchBar={true}
                                                        disableAutoFocus={true}
                                                        preload={false}
                                                        skinTone={'SKIN_TONE_LIGHT'}
                                                        onEmojiClick={this._onEmojisClicked}
                                                        id='emojis'
                                                    />
                                                    </div>
                                                } */}
                                        </div>
                                        {/* <FormGroup controlId="formControlsTextarea"> */}
                                            {/* <FormControl
                                                componentClass="textarea"
                                                rows={1}
                                                placeholder=""
                                                value={commentReply}
                                                onChange={this.props.onChangecommentReply}
                                                onFocus={() => {this.setState({showEmojis : false}) }}
                                            /> */}
                                            <div className={`comments-area-section ${showEditorwrapper ? 'fullheight':''}`}>
                                                <TextEditor {...this.props} />
                                            <div className={(showEditorwrapper ? ' editor-div-show ': ' editor-div-hide')}>
                                                {editorhandler}
                                            </div>
                                                </div>
                                            <div>
                                                <i className={"icon-paper-plan " + (this.props.checkValidReply()  ? 'disable' : '')} onClick={() => this.props.addReplay(feedItem)}></i>
                                            </div>
                                        {/* </FormGroup> */}
                                    </div>
                                }
                            </div>

                            <div className="replies-wrapper">
                                {/* _.isEmpty(replyLists) && */}
                                {(feedItem.no_of_replies > 0 && selectedItem !== feedItem.comment_id) && 
                                    <a className="view-replys" onClick={() => this.props.getReplysList(feedItem)}>Show replies
                                    </a>
                                }
                                {
                                    (showHideTxt && selectedItem == feedItem.comment_id) && 
                                    <a className="view-replys" onClick={() => this.props.hideReplys()}>Hide replies
                                    </a>
                                }
                                { (selectedItem == feedItem.comment_id) &&
                                    <div className="reply-list">
                                        <InfiniteScroll
                                    dataLength={replyLists.length}
                                    next={this.props.fetchMoreDataReply.bind(this)}
                                    hasMore={hasMoreReply}

                                >
                                    {_.map(replyLists, (it, idx) => {
                                        return (
                                                <div className={'feed-hdr'} key={idx}>
                                                    <div className={'l-p'}>
                                                        <span className={'prof-pic'}>
                                                            <img src={it.user_image != null && it.user_image !== "" ? it.user_image : Images.DEFAULT_USER} />
                                                        </span>
                                                        <div className={'prof-det'}>
                                                            <h6 Style={'cursor: pointer;'} onClick={() => this.props.goToUserProfile((feedItem.user_id))}>{it.display_name}</h6>
                                                            <p>{ReactHtmlParser(it.post_comment)}</p>
                                                            



                                                        </div>
                                                    </div>

                                                </div>
                                          
                                        )
                                    })}
                                    </InfiniteScroll>
                                    </div>
                                }

                                </div>
                        </div>
                    </React.Fragment>
                )}
            </MyContext.Consumer>

        )
    }
}

export default CommentList;