import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, ComingSoon, Images } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";
import Config from '../Config';
import WSManager from "../helper/WSManager";

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentWillMount() {
        if (WSManager.loggedIn()) {
            this.props.history.push("/feeds");
        } else {
            // for now
            window.location.href = Config.RefpredictorUrl
        }

    }

    render() {
        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container lobby-web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.help.title}</title>
                            <meta name="description" content={MetaData.dashboard.description} />
                            <meta name="keywords" content={MetaData.dashboard.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="Dashboard" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
