import React from "react";
import { Images } from "../components";
import { MyContext } from "../context";
import * as Constants from "../helper/Constants";
export default class ComingSoon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container coming-soon-container">
            <div className="coming-soon-inner-content">
              <img src={Images.COMING_SOON} alt="" />
              <div className="m-t-20">{Constants.Messages.coming_soon}</div>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
