import React from "react";
import { Modal } from "react-bootstrap";
import { MyContext } from "../context";

export default class AlertModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { IsAlertModalShow, IsAlertModalHide, AlertData } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsAlertModalShow}
              onHide={IsAlertModalHide}
              dialogClassName="alert-modal modal-sm"
              className="center-modal"
            >
              <Modal.Body>
                <h2>Alert</h2>
                <p>{AlertData.message}</p>
                <div className="modal-footer">
                  <a href
                    onClick={() => { AlertData.ModalAction(); IsAlertModalHide() }}
                    className="btn bnt-rounded">
                    Yes
                        </a>
                  <a href
                    onClick={IsAlertModalHide}
                    className="btn bnt-rounded">
                    No
                        </a>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
