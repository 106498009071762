import React from 'react';
import { Modal } from 'react-bootstrap';
import { Images } from '../components';
import { MyContext } from '../context';

export default class ShareLinkModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        };
    }

    render() {

        const { IsShareLinkModalShow, IsShareLinkModalHide } = this.props;
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>

                        <Modal
                            show={IsShareLinkModalShow}
                            onHide={IsShareLinkModalHide}
                            bsSize="small"
                            className="center-modal"
                            dialogClassName="share-modal-bg"
                        >
                            <Modal.Body>
                                <div className="share-link-body">
                                    <h4>Invite Your Friends and play with them</h4>
                                    <div className="match-detail-section">
                                        <div className="match-sub-detail-section">
                                            <img src={Images.TEAM_HYDERABAD} alt="" />
                                        </div>
                                        <div className="team-detail match-sub-detail-section">
                                            <div className="leaguename">TOT vs LIV</div>
                                            <div className="team-name">TOT vs LIV</div>
                                            <div className="match-schedule">sat, Jan 01-08:00 pm</div>
                                        </div>
                                        <div className="match-sub-detail-section">
                                            <img src={Images.TEAM_HYDERABAD} alt="" />
                                        </div>
                                    </div>

                                    <div className="win-prize">
                                        <h1 className="ribbon">
                                            <strong className="ribbon-content">Win Exciting Prizes</strong>
                                        </h1>
                                    </div>
                                    <div className="social-linking">
                                        <div className="link-heading">Invite Your Friends Via</div>
                                        <ul className="social-icons">
                                            <li>
                                                <div className="social-circle icon-link">

                                                </div>
                                                <label>Linking</label>
                                            </li>
                                            <li>
                                                <div className="social-circle icon-facebook"></div>
                                                <label>Facebook</label>
                                            </li>
                                            <li>
                                                <div className="social-circle icon-whatsapp" ></div>
                                                <label>Whatsapp</label>
                                            </li>

                                            <li>
                                                <div className="social-circle icon-email" ></div>
                                                <label>Email</label>
                                            </li>
                                        </ul>
                                        <div className="referal-code">
                                            <div className="referal-body">
                                                <div>Share referral code</div>

                                                <div>
                                                    <div className="copy-text">Copy</div>
                                                    <i className="icon-copy-file"> <h1>ABD12F4</h1> </i>
                                                </div>
                                            </div>
                                            <div className="referal-footer">
                                                Tell your friends to enter this code on Signup.
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <i
                                className="icon-close icc"
                                onClick={() => {
                                    IsShareLinkModalHide()
                                }
                                }
                            />
                        </Modal>
                    </div>
                )}
            </MyContext.Consumer>
        );
    }
}