import React from "react";
import { Row, Col, FormGroup, Button } from "react-bootstrap";
import FloatingLabel, { floatingStyles, focusStyles, inputStyles, labelStyles } from "floating-label-react";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import { Header, Images, Loader, RKFloatingLabelInput } from "../components";
import moment from "moment";
import Select from "react-select";
import Moment from "react-moment";
import _ from "lodash";
import DatePicker from "react-date-picker";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import Validation from "./../helper/validation";
import PhoneVerify from "../Modals/PhoneVerify";

const today = new Date(moment().subtract(18, "years"));
const formInputStyle = {
  floating: {
    ...floatingStyles,
    color: "#9E9E9E",
    fontSize: "12px",
    borderBottomColor: "#e1e1e1",
    fontFamily: "MuliRegular"
  },
  focus: {
    ...focusStyles,
    borderColor: "#023320"
  },
  input: {
    ...inputStyles,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(255, 255, 255, 0.4)",
    width: "100%",
    fontSize: "16px",
    color: "#333333",
    fontFamily: "MuliBold",
    padding: "16px 0px 10px",
    backgroundColor: "transparent"
  },
  label: {
    ...labelStyles,
    paddingBottom: "0px",
    marginBottom: "0px",
    width: "100%",
    fontSize: "12px",
    color: "#333333",
    fontFamily: "MuliRegular"
  }
};

const formTextAreaStyle = {
  floating: {
    ...floatingStyles,
    color: "#9E9E9E",
    fontSize: "12px",
    borderBottomColor: "rgba(153, 153, 153, 0.5)",
    fontFamily: "MuliRegular",
    backgroundColor: "#fff"
  },
  focus: {
    ...focusStyles,
    borderColor: "#023320"
  },
  input: {
    ...inputStyles,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(153, 153, 153, 0.5)",
    borderColor: "rgba(153, 153, 153, 0.5)",
    width: "100%",
    fontSize: "16px",
    color: "#333333",
    fontFamily: "MuliBold",
    padding: "10px 0px 2px",
    backgroundColor: "transparent",
    resize: "none",
    textAlign: "center"
  },
  label: {
    ...labelStyles,
    paddingBottom: "0px",
    marginBottom: "0px",
    width: "100%",
    fontSize: "12px",
    color: "#333333",
    fontFamily: "MuliRegular"
  }
};

const Genders = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" }
];

export default class ProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: WSManager.getProfile(),
      email: "",
      firstName: "",
      lastName: "",
      phoneNo: "",
      username: "",
      dobdate: new Date(moment().subtract(18, "years")),
      bannerImageFile: "",
      bannerImage: "",
      profileImageFile: "",
      profileImage: "",
      memberSince: "",
      selectedLanguage: "",
      selectedLeague: "",
      selectedClub: "",
      selectedCountry: "",
      selectedState: "",
      selectedNetwork: "",
      selectedGender: "",
      isLoading: false,
      tagline: "",
      website: "",
      showDatePicker: false,
      clubs: [],
      countryList: [],
      stateList: [],
      showPhoneVerifyModal: false,
      posting: false,
      phoneNoValidate: false,
      display_name: '',
      PhoneCode: '',
      phone_code_list: [],
      sports_list: Constants.sports_list,
      selectedSports:""
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  showPhoneVerifyModal = () => {
    this.setState({
      showPhoneVerifyModal: true
    });
  };
  PhoneVerifyModalHide = () => {
    this.setState({
      showPhoneVerifyModal: false
    });
  };

  componentWillMount() {
    this.callWSGetCountryData();
    this.fillUserData();
  }

  handleChange = date => {
    this.setState({
      dobdate: date,
      showDatePicker: false
    });
  };

  fillUserData() {
    let { userProfile } = this.state;

    let selLeague = "";
    if (userProfile.fav_league && Constants.leagues != undefined) {
      let findLeagueObj = Constants.leagues.find(obj => obj.league_id === userProfile.fav_league);
      if (findLeagueObj) {
        selLeague = findLeagueObj;
      }

    }

    let selClub = "";
    if (userProfile.fav_club && Constants.clubsData != undefined) {
      let getLeagueClub = Constants.clubsData[userProfile.fav_league];
      if (getLeagueClub) {
        this.setState({ clubs: getLeagueClub })
        let findClubObj = getLeagueClub.find(obj => obj.id === userProfile.fav_club);
        if (findClubObj) {
          selClub = findClubObj;
        }
      }


    }


    let selSports = "";
    if(userProfile.fav_sport && Constants.sports_list != undefined){
      let findSportsObj = Constants.sports_list.find(obj => obj.sports_id === userProfile.fav_sport);
      if (findSportsObj) {
        selSports = findSportsObj;
      }
    }

    

    this.setState(
      {
        bannerImage: userProfile.cover_image || "",
        profileImage: userProfile.image || "",
        firstName: userProfile.first_name || "",
        lastName: userProfile.last_name || "",
        phoneNo: userProfile.phone_no || "",
        username: userProfile.user_name || "",
        memberSince: userProfile.member_since || "",
        tagline: userProfile.about || "",
        website: userProfile.user_website || "",
        dobdate: moment(userProfile.dob).toDate() || today,
        email: userProfile.email || "",
        display_name: userProfile.display_name || "",
        selectedNetwork: userProfile.phone_network
          ? {
            label: userProfile.phone_network,
            value: userProfile.phone_network
          }
          : "",
        selectedGender: userProfile.gender
          ? {
            label: userProfile.gender.replace(/./, first_letter =>
              first_letter.toUpperCase()
            ),
            value: userProfile.gender
          }
          : "",
        selectedLanguage: userProfile.language
          ? {
            label:
              userProfile.language &&
              userProfile.language.replace(/./, first_letter =>
                first_letter.toUpperCase()
              ),
            value: userProfile.language
          }
          : "",
        selectedLeague: selLeague,
        selectedClub: selClub,
        selectedSports:selSports
      },
      // () => {
      //   let tmpArray = [];
      //   _.map(
      //     Constants.clubsData[this.state.selectedLeague.value],
      //     (item, idx) => {
      //       tmpArray.push({ label: item, value: item });
      //     }
      //   );
      //   this.setState({
      //     clubs: tmpArray
      //   });
      // }
    );
  }

  IsFormValid() {
    const {
      email,
      firstName,
      lastName,
      phoneNo,
      username,
      dobdate,
      selectedNetwork,
      selectedGender,
      selectedLanguage,
      selectedCountry,
      selectedState,
      selectedLeague,
      selectedClub,
      selectedSports
    } = this.state;

    var isValid = true;
    if (
      Validation.validate("required", firstName) !== "success" ||
      Validation.validate("first_name", firstName) !== "success"
    ) {
      isValid = false;
    } else if (
      Validation.validate("required", lastName) !== "success" ||
      Validation.validate("last_name", lastName) !== "success"
    ) {
      isValid = false;
    } else if (
      Validation.validate("required", phoneNo) !== "success" ||
      Validation.validate("phone_no", phoneNo) !== "success"
    ) {
      isValid = false;
    } else if (
      Validation.validate("required", username) !== "success" ||
      Validation.validate("userName", username) !== "success"
    ) {
      isValid = false;
    } else if (!selectedGender) {
      isValid = false;
    } 
    // else if (!selectedNetwork) {
    //   isValid = false;
    // } 
    else if (
      email !== "" &&
      Validation.validate("email", email) !== "success"
    ) {
      isValid = false;
    } else if (!dobdate) {
      isValid = false;
    } else if (!selectedLanguage) {
      isValid = false;
    } else if (!selectedCountry) {
      isValid = false;
    } else if (!selectedState) {
      isValid = false;
    } else if (!selectedLeague) {
      isValid = false;
    } else if (!selectedClub) {
      isValid = false;
    }
    else if (!selectedSports) {
      isValid = false;
    }
    return isValid;
  }

  onSubmit = e => {
    e.preventDefault();
    let {
      selectedGender,
      selectedClub,
      selectedLeague,
      selectedState,
      selectedCountry,
      selectedLanguage,
      selectedNetwork,
      profileImage,
      bannerImage,
      email,
      firstName,
      lastName,
      username,
      dobdate,
      phoneNo,
      tagline,
      website,
      display_name,
      PhoneCode,
      selectedSports
    } = this.state;


    if (this.IsFormValid()) {
      this.setState({ isLoading: true, posting: true });
      let param = {
        image: profileImage,
        cover_image: bannerImage,
        master_country_id: selectedCountry.value,
        master_state_id: selectedState.value,
        language: selectedLanguage.value,
        fav_league: selectedLeague.league_id,
        fav_club: selectedClub.id,
        first_name: firstName,
        last_name: lastName,
        user_name: username,
        dob: moment(dobdate).format("MMM DD, YYYY"),
        gender: selectedGender.value,
        phone_no: phoneNo,
        tagline: tagline,
        about: tagline,
        user_website: website,
        // phone_network: selectedNetwork.value,
        display_name: display_name,
        phone_code: PhoneCode ? this.state.PhoneCode.phonecode : '',
        fav_sport:selectedSports.sports_id
      };

      if (email) {
        param.email = email;
      }

      WSManager.Rest(NC.baseURL + NC.UPDATE_PROFILE, param).then(
        responseJson => {
          this.setState({ isLoading: false, posting: false });
          if (responseJson && responseJson.response_code === NC.successCode) {
            WSManager.setProfileCompleted("1");
            // this.props.history.push("/profile");
            this.props.history.goBack()
            notify.show(responseJson.message, "success", 3000);
          }
        }
      );
    }
  };

  onSelectProfilePic(e) {
    e.preventDefault();
    let reader = new FileReader();
    let mfile = e.target.files[0];
    reader.onloadend = () => {
      if (mfile.size / 1024000 > 40) {
        notify.show(Constants.Messages.file_size, "error", 5000);
      } else {
        this.setState(
          {
            profileImageFile: mfile,
            profileImage: reader.result
          },
          () => {
            this.upload.value = "";
            this.uploadImage(this);
          }
        );
      }
    };
    reader.readAsDataURL(mfile);
  }

  uploadImage(globalThis) {
    globalThis.setState({ isLoading: true });
    var data = new FormData();
    data.append("userfile", globalThis.state.profileImageFile);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        globalThis.setState({ isLoading: false });
        var response = JSON.parse(this.responseText);
        if (response !== "" && response.response_code === NC.successCode) {
          var imagePath = response.data.image_path;
          globalThis.setState({ profileImage: imagePath });
        } else {
          var errorMsg = WSManager.parseErrorMessage(response)
          if (response.response_code == NC.sessionExpireCode) {
          } else {
            notify.show(errorMsg, "error", 5000);
          }
          globalThis.setState(
            {
              profileImageFile: '',
              profileImage: ''
            });

        }
      }
    });

    xhr.open("POST", NC.baseURL + NC.DO_UPLOAD);
    xhr.setRequestHeader("session_key", WSManager.getToken());
    xhr.send(data);
  }

  onSelectBannerPic(e) {
    e.preventDefault();
    let reader = new FileReader();
    let mfile = e.target.files[0];
    reader.onloadend = () => {
      if (mfile.size / 1024000 > 40) {
        notify.show(Constants.Messages.file_size, "error", 5000);
      } else {
        this.setState(
          {
            bannerImageFile: mfile,
            bannerImage: reader.result
          },
          () => {
            this.bannerUpload.value = "";
            this.uploadBannerImage(this);
          }
        );
      }
    };
    reader.readAsDataURL(mfile);
  }

  uploadBannerImage(globalThis) {
    globalThis.setState({ isLoading: true });
    var data = new FormData();
    data.append("usercoverfile", globalThis.state.bannerImageFile);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        globalThis.setState({ isLoading: false });
        var response = JSON.parse(this.responseText);
        if (response !== "" && response.response_code === NC.successCode) {
          var imagePath = response.data.image_path;
          globalThis.setState({ bannerImage: imagePath });
        } else {
          var errorMsg = WSManager.parseErrorMessage(response)
          if (response.response_code == NC.sessionExpireCode) {
          } else {
            notify.show(errorMsg, "error", 5000);
          }
          globalThis.setState(
            {
              bannerImageFile: '',
              bannerImage: ''
            });

        }
      }
    });

    xhr.open("POST", NC.baseURL + NC.DO_UPLOAD_BANNER);
    xhr.setRequestHeader("session_key", WSManager.getToken());
    xhr.send(data);
  }

  callWSGetCountryData() {
    WSManager.Rest(NC.baseURL + NC.GET_ALL_COUNTRY).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        let tmpArray = [];
        _.map(responseJson.data.country_list, (item, idx) => {
          tmpArray.push({
            label: item.country_name,
            value: item.master_country_id
          });
          if (
            WSManager.getProfile().master_country_id == item.master_country_id
          ) {

            this.handleCountryChange({
              label: item.country_name,
              value: item.master_country_id
            });
          }
        });

        let phoneCode = "";
        if (this.state.userProfile.phone_code) {
          let getObj = responseJson.data.country_list.find(ob => ob.phonecode == this.state.userProfile.phone_code)
          if (getObj) {
            phoneCode = getObj
          }
        }


        this.setState({
          countryList: tmpArray,
          phone_code_list: responseJson.data.country_list,
          PhoneCode: phoneCode
        });
      }
    });
  }

  selectChange = (val, key) => {
    this.setState({
      [key]: val,
      [`selected${key}`]: val
    })
  }

  callWSGetStateData(master_country_id) {
    this.setState({ isLoading: true });
    WSManager.Rest(NC.baseURL + NC.GET_ALL_STATE, {
      master_country_id: master_country_id
    }).then(responseJson => {
      this.setState({ isLoading: false });

      if (responseJson.response_code === NC.successCode) {
        let tmpArray = [];
        _.map(responseJson.data.state_list, (item, idx) => {
          tmpArray.push({
            label: item.state_name,
            value: item.master_state_id
          });
          if (WSManager.getProfile().master_state_id == item.master_state_id) {
            this.handleStateChange({
              label: item.state_name,
              value: item.master_state_id
            });
          }
        });
        this.setState({ stateList: tmpArray });
      }
    });
  }

  ResendOtp = () => {
    let param = {
      phone_no: this.state.phoneNo,
      change_number: 1
    };
    WSManager.Rest(NC.baseURL + NC.RESEND_OTP, param).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        this.showPhoneVerifyModal();
      }
    });
  };

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    if (e.target.name == "phoneNo") {
      this.setState({
        phoneNoValidate:
          Validation.validate("required", value) &&
          Validation.validate("phone_no", value)
      });
    }
  }

  handleNetworkChange = selectedNetwork => {
    this.setState({
      selectedNetwork: selectedNetwork
    });
  };

  handleGenderChange = selectedOption => {
    this.setState({
      selectedGender: selectedOption
    });
  };

  handleLanguageChange = selectedOption => {
    this.setState({ selectedLanguage: selectedOption });
  };

  handleLeagueChange = selectedOption => {
    // let tmpArray = [];
    // _.map(Constants.clubsData[selectedOption.value], (item, idx) => {
    //   tmpArray.push({ label: item, value: item });
    // });
    this.setState({
      selectedLeague: selectedOption,
      clubs: Constants.clubsData[selectedOption.league_id],
      selectedClub: ""
    });
  };

  handleClubChange = selectedOption => {
    this.setState({ selectedClub: selectedOption });
  };

  handleCountryChange = selectedOption => {
    this.setState({ selectedCountry: selectedOption, selectedState: "" });
    this.callWSGetStateData(selectedOption.value);
  };

  handleStateChange = selectedOption => {
    this.setState({ selectedState: selectedOption });
  };


  handleSportsChange = selectedOption => {
    this.setState({ selectedSports: selectedOption });
  };
  

  updatePhoneLocaly = () => {
    this.state.userProfile['phone_no'] = this.state.phoneNo;
    this.setState({ userProfile: this.state.userProfile });
    WSManager.setProfile(this.state.userProfile);
  };

  render() {
    const {
      userProfile,
      email,
      firstName,
      lastName,
      phoneNo,
      username,
      dobdate,
      bannerImage,
      profileImage,
      memberSince,
      selectedNetwork,
      selectedGender,
      selectedLanguage,
      countryList,
      selectedCountry,
      selectedState,
      stateList,
      selectedLeague,
      selectedClub,
      clubs,
      isLoading,
      tagline,
      website,
      showDatePicker,
      posting,
      showPhoneVerifyModal,
      display_name,
      PhoneCode,
      phone_code_list,
      selectedSports,
      sports_list
    } = this.state;
    const HeaderOption = {
      back: true,
      Notification: false,
      Logo: true
    };

    const user_permissions = WSManager.getUserPermissions();

    console.log(sports_list, selectedSports)

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container profile-web-container">
            {isLoading && <Loader />}
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.editprofile.title}</title>
              <meta name="description" content={MetaData.editprofile.description} />
              <meta name="keywords" content={MetaData.editprofile.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <div
                    style={{
                      backgroundImage: `url(${bannerImage})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}
                    className="page-inner-header"
                  >
                    {!bannerImage && (
                      <img
                        src={Images.HEADER_BG_FULL}
                        alt=""
                        className="header-bg"
                      />
                    )}
                    <input
                      id="myInput"
                      type="file"
                      accept="image/*"
                      ref={ref => (this.bannerUpload = ref)}
                      style={{ display: "none" }}
                      onChange={e => this.onSelectBannerPic(e)}
                    />
                    <a
                      href
                      className="banner-change-section"
                      onClick={() => this.bannerUpload.click()}
                    >
                      <i className="icon-camera" />
                    </a>
                  </div>
                </Col>
              </Row>
              <div className="profile-header profile-header-without-bg">
                <div className="user-thumbnail">
                  <img
                    style={{ cursor: "pointer" }}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = Images.DEFAULT_USER;
                    }}
                    onClick={() => this.upload.click()}
                    src={profileImage || Images.DEFAULT_USER}
                    alt=""
                  />
                  <input
                    id="myInput"
                    type="file"
                    accept="image/*"
                    ref={ref => (this.upload = ref)}
                    style={{ display: "none" }}
                    onChange={e => this.onSelectProfilePic(e)}
                  />
                  <a
                    href
                    onClick={() => this.upload.click()}
                    className="goto-msg-section"
                  >
                    <i className="icon-camera" />
                  </a>
                </div>
                <div className="user-detail">
                  <div className="user-name" style={{
                    fontFamily:
                      // (userProfile.plan_id == 3 || userProfile.plan_id == 2) ? 
                      // Constants.PAID_PLANS_ID.includes(userProfile.plan_id) 
                      user_permissions.username_bold == '1'
                      ?
                        'MuliBlack' : 'MuliRegular'
                  }}>
                     {username} {
                    // userProfile.plan_id == 3 
                    user_permissions.premium_badge == '1' && <img src={Images.P_PLUS} style={{marginTop:-5}} alt="" />}
                  </div>
                  <div className="member-since">
                    Member Since -{" "}
                    <Moment date={memberSince} format="MMM, YYYY" />
                  </div>
                </div>
              </div>
              <form className="form-section p-h-md" onSubmit={this.onSubmit}>
                <div className="verification-block double-input-container">
                  <Row>
                    <Col xs={6} className="position-relative">
                      <FormGroup
                        validationState={
                          Validation.validate("required", firstName) &&
                          Validation.validate("first_name", firstName)
                        }
                        className="input-label-center "
                        controlId="formBasicText"
                      >
                        <FloatingLabel
                          styles={formInputStyle}
                          id="first-name"
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                          value={firstName}
                          maxLength="50"
                          autocomplete="off"
                          onChange={this.onChange}
                        />
                      </FormGroup>
                      <div className="dual-input-separater" />
                    </Col>
                    <Col xs={6}>
                      <FormGroup
                        validationState={
                          Validation.validate("required", lastName) &&
                          Validation.validate("last_name", lastName)
                        }
                        className="input-label-center "
                        controlId="formBasicText"
                      >
                        <FloatingLabel
                          styles={formInputStyle}
                          id="last-name"
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                          maxLength="50"
                          value={lastName}
                          autocomplete="off"
                          onChange={this.onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className="input-label-center "
                        controlId="formBasicText"
                      >
                        <FloatingLabel
                          element="textarea"
                          styles={formTextAreaStyle}
                          id="aboutus"
                          name="tagline"
                          placeholder="About Me"
                          autocomplete="off"
                          onChange={this.onChange}
                          value={tagline}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className="input-label-center "
                        controlId="formBasicText"
                      >
                        <FloatingLabel
                          element="textarea"
                          styles={formTextAreaStyle}
                          id="display_name"
                          name="display_name"
                          placeholder="Display Name"
                          autocomplete="off"
                          onChange={this.onChange}
                          value={display_name}
                          maxLength={15}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={selectedLeague === "" ? "error" : null}
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>
                            Phone Code
                          </label>
                          <div className="genderStyle">
                            <Select
                              onChange={e => this.selectChange(e, 'PhoneCode')}
                              options={phone_code_list}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="+232"
                              isSearchable={true}
                              isClearable={false}
                              value={PhoneCode}
                              getOptionLabel={(option) => option.phonecode}
                              getOptionValue={(option) => option.phonecode}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className="input-label-center "
                        controlId="formBasicText"
                        validationState={
                          Validation.validate("required", phoneNo) &&
                          Validation.validate("phone_no", phoneNo)
                        }
                      >
                        <RKFloatingLabelInput className="rkfloatingLabelInput editp" id="phoneno" autocomplete="off" type="text"
                          isNumberOnly={true} name="phoneNo" value={phoneNo} placeholder="Phone Number" onChange={this.onChange} />
                      </FormGroup>
                      {phoneNo != userProfile.phone_no &&
                        this.state.phoneNoValidate == "success" && (
                          <span
                            className="verify-text"
                            onClick={() => this.ResendOtp()}
                          >
                            Verify
                          </span>
                        )}
                      <Col xs={12}>

                        {phoneNo != userProfile.phone_no &&
                          this.state.phoneNoValidate != "success" ?
                          <div className="help-text">Please provide valid phone number as account verification link will be sent to you via SMS. Eg. 01234567892</div> :
                          <div className="help-text">Eg. 01234567892</div>}
                      </Col>
                    </Col>
                  </Row>
                </div>
                {/* <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={
                          selectedNetwork === "" ? "error" : "success"
                        }
                        className="input-label-center input-transparent font-14"
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>
                            Phone Network
                          </label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleNetworkChange}
                              options={Constants.networks}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Phone Network"
                              isSearchable={false}
                              isClearable={false}
                              value={selectedNetwork}
                              menuPlacement="auto"
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div> */}

                <div style={{ pointerEvents: 'none', opacity: 0.7 }} className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className="input-label-center"
                        controlId="formBasicText"
                        validationState={
                          Validation.validate("required", username) &&
                          Validation.validate("userName", username)
                        }
                      >
                        <FloatingLabel
                          styles={formInputStyle}
                          id="username"
                          name="username"
                          placeholder="Username"
                          type="text"
                          value={username}
                          maxLength="12"
                          autocomplete="off"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <div className="help-text">
                        Username can’t be greater than 12 characters
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className="input-label-center "
                        controlId="formBasicText"
                        validationState={
                          email !== ""
                            ? Validation.validate("email", email)
                            : "success"
                        }
                      >
                        <FloatingLabel
                          maxLength={100}
                          required
                          styles={formInputStyle}
                          id="email"
                          name="email"
                          placeholder="Email"
                          autocomplete="off"
                          onChange={this.onChange}
                          type="email"
                          value={email}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={
                          selectedGender === "" ? "error" : "success"
                        }
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>Gender</label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleGenderChange}
                              options={Genders}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Select Gender"
                              isSearchable={false}
                              isClearable={false}
                              value={selectedGender}
                              menuPlacement="auto"
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block">
                  <Row>
                    <Col sm={12}>
                      <FormGroup className="input-label-center input-transparent font-14 dob-date-picker custom-date-picker">
                        <div className="datepicker_display datepicker_display_default">
                          <label>Date of birth</label>
                          <div className="dobField">
                            <DatePicker
                              className="date-picker-custom edit-profile"
                              required={true}
                              activeStartDate={today}
                              minDetail="decade"
                              locale="en-IN"
                              onChange={this.handleChange}
                              maxDate={today}
                              value={dobdate}
                              isOpen={showDatePicker}
                            />

                            <label
                              onClick={() =>
                                this.setState({
                                  showDatePicker: !showDatePicker
                                })
                              }
                              className="dob-text"
                            >
                              <Moment date={dobdate} format="MMM DD, YYYY" />
                            </label>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={selectedLanguage === "" ? "error" : null}
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>Language</label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleLanguageChange}
                              options={Constants.languages}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Select Language"
                              isSearchable={false}
                              isClearable={false}
                              value={selectedLanguage}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={selectedSports === "" ? "error" : null}
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>Choose Favorite Sports</label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleSportsChange}
                              options={sports_list}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Select Sports"
                              isSearchable={false}
                              isClearable={false}
                              getOptionLabel={(option) => option.sports_name}
                              getOptionValue={(option) => option.sports_id}
                              value={selectedSports}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}

                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={selectedCountry === "" ? "error" : null}
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>Country</label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleCountryChange}
                              options={countryList}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Select Country"
                              value={selectedCountry}
                              isSearchable={false}
                              isClearable={false}
                              menuPlacement="auto"
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={selectedState === "" ? "error" : null}
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>Location</label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleStateChange}
                              options={stateList}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Select Location"
                              value={selectedState}
                              isSearchable={false}
                              isClearable={false}
                              menuPlacement="auto"
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={selectedLeague === "" ? "error" : null}
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>
                            Favourite League
                          </label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleLeagueChange}
                              options={Constants.leagues}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Select League"
                              isSearchable={false}
                              isClearable={false}
                              value={selectedLeague}
                              getOptionLabel={(option) => option.league_abbr}
                              getOptionValue={(option) => option.league_id}
                              menuPlacement="auto"
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="verification-block mt-0 p-0">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={selectedClub === "" ? "error" : null}
                        className="input-label-center input-transparent font-14 "
                      >
                        <div className="select-field-default">
                          <label style={formInputStyle.label}>
                            Favourite Club
                          </label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleClubChange}
                              options={clubs}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              placeholder="Select Club"
                              isSearchable={false}
                              isClearable={false}
                              value={selectedClub}
                              getOptionLabel={(option) => option.club_name}
                              getOptionValue={(option) => option.id}
                              menuPlacement="auto"
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#023320"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="gender-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className="input-label-center"
                        controlId="formBasicText"
                        validationState={
                          website === "" ||
                            Validation.validate("url", website) === "success"
                            ? "success"
                            : "error"
                        }
                      >
                        <FloatingLabel
                          styles={formInputStyle}
                          id="website"
                          name="website"
                          placeholder="Website"
                          type="text"
                          maxLength={100}
                          value={website}
                          autocomplete="off"
                          onChange={this.onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="text-center profile-btn-section mb-5">
                  <Button
                    disabled={posting}
                    onClick={this.onSubmit}
                    type="submit"
                    className="btn btn-primary "
                  >
                    Save Changes
                  </Button>
                </div>
              </form>
            </div>
            {showPhoneVerifyModal && (
              <PhoneVerify
                phoneNo={phoneNo}
                updatePhoneLocaly={this.updatePhoneLocaly.bind(this)}
                IsPhoneVerifyModalShow={showPhoneVerifyModal}
                IsPhoneVerifyModalHide={this.PhoneVerifyModalHide}
              />
            )}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
