import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../../context';
import { Images, Header } from '../../components';
import { Helmet } from "react-helmet";
import MetaData from "../../helper/MetaData";
import Config from '../../Config';
import WSManager from "../../helper/WSManager";
import GroupChatHeader from './GroupChatHeader';
import GroupChatFooter from './GroupChatFooter';
import Slider from "react-slick";
import _ from 'lodash';
import Moment from 'react-moment';
import JoinGroupModal from '../../Modals/JoinGroupModal'


export default class GroupChatParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            whichTab: 1,
            joinGroup: false,
            myGroups: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,],
            allGroups: [1, 1, 1, 1, 1, 1, 1],
        }
    }


    componentWillMount() {
        this.setState({
            propsData: this.props.location.state
        })
        // Header.updateHeaderShadow(false);
    }

    _isActiveTab = (tab) => {
        this.setState({
            whichTab: tab
        })
    }
    _isShowModal = () => {
        this.setState({
            joinGroup: true
        })
    }
    _isHideModal = () => {
        this.setState({
            joinGroup: false
        })
    }

    _myGroups = () => {
        return <div>
            {
                _.map(this.state.myGroups, (item, idx) => {
                    return (
                        <div className='list-holder cursor-p'>
                            <div className='profile-holder-list'>
                                <img src={Images.DEFAULT_USER} className='profile-h' />
                            </div>
                            <div className='m-l-15'>
                                <div className='group-name'>
                                    Group Name here {idx}
                                </div>
                                <div className='create-by'>
                                    Created by: Shubham Yadav
                                 </div>
                                <div className='group-members'>
                                    <img src={Images.GROUP_CHAT} />
                                    <text className='m-l-5'>102 Members</text>
                                </div>

                            </div>
                            <div className='right-div'>
                                <div className='chat-time'>
                                    <Moment fromNow>"Wednesday, Oct 25, 2017, 8:19 PM"</Moment>
                                </div>
                                <div className='msg-count'>
                                    15
                                 </div>
                            </div>
                            <div className='hr-line'></div>
                        </div>
                    )
                })
            }

        </div>


    }

    _allGroups = () => {
        return <div>
            {
                _.map(this.state.myGroups, (item, idx) => {
                    return (
                        <div className='list-holder'>
                            <div className='profile-holder-list'>
                                <img src={Images.DEFAULT_USER} className='profile-h' />
                            </div>
                            <div className='m-l-15'>
                                <div className='group-name'>
                                    Group Name here
                    </div>
                                <div className='create-by'>
                                    Created by: Shubham Yadav
                    </div>
                                <div className='group-members'>
                                    <img src={Images.GROUP_CHAT} />
                                    <text className='m-l-5'>102 Members</text>
                                </div>

                            </div>
                            <div className='right-div-join ' onClick={() => { this._isShowModal() }}>
                                Join Now
                            </div>
                            <div className='hr-line'></div>
                        </div>
                    )
                })
            }

        </div>


    }

    render() {
        const HeaderOption = {
            menu: false,
            Notification: false,
            Logo: true,
            isShowHeader: false
        };

        const joinModalProps = {
            modalHide: this._isHideModal,
            modalShow: this._isShowModal
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container">
                        {
                            this.state.joinGroup && <JoinGroupModal {...joinModalProps} />
                        }
                        {/* <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.Chat.title}</title>
                            <meta name="description" content={MetaData.Chat.description} />
                            <meta name="keywords" content={MetaData.Chat.keywords} />
                        </Helmet> */}
                        <Header
                            {...this.props}
                            showNavigation={true}
                            HeaderOption={HeaderOption}
                            showFilter={this.showFilter}
                        />
                        <div className='red-add-div cursor-p'>
                            <img src={Images.REDADD} className='red-add' onClick={() => { this.props.history.push('/user-profile/group-chat/new-group') }} />
                        </div>
                        <div>
                            <GroupChatHeader {...this.props} />
                        </div>
                        <div>
                            <div className='tab-custome'>
                                <div className='tab'>
                                    <div className={this.state.whichTab == 1 ? 'tab-inner-active' : 'tab-inner-deactive'} onClick={() => { this._isActiveTab(1) }}>
                                        <div className='title'>
                                            MY GROUPS
                                    </div>
                                        <div className='active-line'></div>
                                    </div>
                                </div>
                                <div className='tab'>
                                    <div className={this.state.whichTab == 2 ? 'tab-inner-active' : 'tab-inner-deactive'} onClick={() => { this._isActiveTab(2) }}>
                                        <div className='title'>
                                            ALL GROUPS
                                    </div>
                                        <div className='active-line'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='body-list m-t-10'>
                                {
                                    this.state.whichTab == 1 && this._myGroups()
                                }
                                {
                                    this.state.whichTab == 2 && this._allGroups()
                                }

                            </div>
                        </div>



                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
