import React from "react";
import { Modal } from "react-bootstrap";
import { MyContext } from "../context";

export default class ProfileView extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { profileModalShow, profileModalHide, ProfileViewData } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={profileModalShow}
              onHide={profileModalHide}
              dialogClassName="custom-modal-with-bg profile-view-modal"
              animation={false}
            >
              <Modal.Body>
                <a href className="modal-close-btn" onClick={() => { profileModalHide(); }}>
                  <i className="icon-close" />
                </a>
                <img src={ProfileViewData.image.replace('/thumb' , '')} alt="" />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
