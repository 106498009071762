import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";
import * as Constants from "../helper/Constants";

export default class ConfirmationModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      IsConfirmationModalShow,
      IsConfirmationModalHide,
      countineAction,
      countineData,
      countineType,
      contestEntryFee,
      contestName
    } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsConfirmationModalShow}
              onHide={IsConfirmationModalHide}
              bsSize="small"
              className="center-modal"
              dialogClassName=" user-action-modal "
            >
              <div className="modal-overlay" />
              <Modal.Body>
                <div className="user-action-body">
                  <img src={Images.CIRCULAR_TICK} alt="" />
                  <h4>Confirmation</h4>
                  <ul>
                    <li>
                      <div>Contest Name</div>
                      <div>{contestName}</div>
                    </li>
                    <li>
                      <div>Wallet Balance</div>
                      <div>₦{parseFloat(Constants.userBalanceInfo.user_balance.real_amount) + parseFloat(Constants.userBalanceInfo.user_balance.winning_amount)}</div>
                    </li>
                    <li>
                      <div>Entry Fee</div>
                      <div>{contestEntryFee > 0 ? ('₦' + contestEntryFee) : 'Free'}</div>
                    </li>
                  </ul>
                  <Button onClick={() => {
                    countineAction(countineData, countineType);
                    IsConfirmationModalHide();
                  }} className="btn btn-rounded btn-primary">
                    Join Contest
                  </Button>
                </div>
              </Modal.Body>
              <i
                className="icon-close icc"
                onClick={() => {
                  IsConfirmationModalHide()
                }
                }
              />
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
