import React from 'react';
import { MyContext } from "../context";
import { Header, Images, Loader } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";
import { Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import FeedList from './../components/FeedList';
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import * as NC from '../helper/NetworkingConstants'
import { Col, Row } from 'react-bootstrap';
import { encode } from 'base-64';



class Feeds extends React.Component {
    constructor(props) {
        super(props);
        const html = '';
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const postComment = EditorState.createWithContent(contentState);
        this.state = {
            postComment,
            showSearchBar: false,
            feedList: [],
            tagsArray: [],
            selectedTags: [],
            selectedItem: '',
            isLoading: false,
            hasMore: false,
            offsetFeed:1,
            isShown: true,
            showEmojis: false,
            onlyTxt: false,
            onlyEmoji: false,
            showEditorwrapper: false
        }
        this.searchRef = React.createRef();
    }

    ShowUnfollowModal = (unfollowAction) => {
   
        this.setState({
          showUnfollowModal: true,
          unfollowAction: unfollowAction
        });
      };

    componentWillMount() {
        this.getFeedList()
        this.getTagsList('')
    }
    getFeedList = () => {
        const {offsetFeed} = this.state
        this.setState({ isLoading: true });
        let params = {
            page : offsetFeed,
            limit: 20,
            sort_order: "DESC",
            sort_field: "created_date",
            search_keyword: '',
            //country_id :WSManager.getProfile().master_country_id
        }

        WSManager.Rest(NC.baseURL + NC.ACTIVITY, params)
            .then((response) => {
                if (response.response_code === NC.successCode) {
                    //hasMore = response.data.isloadmore,
                    this.setState({
                        hasMore:response.data.isloadmore,
                        isLoading:false,
                        offsetFeed: response.data.page
                    })
                    if (this.state.offsetFeed > 0) {
                        this.setState({
                          feedList: [
                            ...this.state.feedList,
                            ...response.data.list
                          ]
                        });
                      } else {
                        this.setState({
                          feedList: response.data.list
                        });
                      }

                }
               

            })
            .catch((error) => {
                console.log('getFeedList Error=======>', error);
            })
    }

    fetchMoreData = () => {
        this.getFeedList();
      };

    searchToggleHandler = () => {
        this.setState(
            { showSearchBar: !this.state.showSearchBar },
            () => {
                if (this.state.showSearchBar) {
                    this.searchRef.current.focus();
                }
            }
        )
    }


    likePost = (feedItem, feedIndex) => {
        const { activity_id: entity_id, activity_type_id: entity_type } = feedItem
        let params = {
            "entity_id": entity_id,
            "entity_type": entity_type
        }

        WSManager.Rest(NC.baseURL + NC.TOGGLE_LIKE, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    feedItem.is_like = feedItem.is_like == 0 ? 1 : 0
                    feedItem.no_of_likes = feedItem.is_like == 0 ? Number(feedItem.no_of_likes) - 1 : Number(feedItem.no_of_likes) + 1
                    this.setState({ feedList: this.state.feedList })
                }
            })
            .catch((error) => {

            })
    }



    reSharePost = (feedItem) => {
        let params = {
            "activity_id": feedItem.activity_id,
            "share_type":1
        }

        WSManager.Rest(NC.baseURL + NC.RESHARE_ACTIVITY, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    if(_.isEmpty(response.data)){
                        notify.show(response.error, "error", 2500);
                    }
                    
                    let tempArr = this.state.feedList
                    let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                    tempArr[itemIndex].no_of_share = response.data[0].no_of_share;
                    tempArr[itemIndex].is_share = response.data[0].is_share;
                    this.setState({
                        feedList:tempArr
                    })
                }
            })
            .catch((error) => {

            })
    }

    addComment = (feedItem) => {
        this.setState({
            isLoading:true
        })
        const {postComment} = this.state
        let params = {
            "activity_unique_id":feedItem.activity_unique_id,
            "comment":draftToHtml(convertToRaw(postComment.getCurrentContent())),

        }

        WSManager.Rest(NC.baseURL + NC.ADD_COMMENT, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    let tempArr = this.state.feedList
                    let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                    tempArr[itemIndex].no_of_comments = response.data.activity_comment_count;
                    tempArr[itemIndex].is_comment = response.data.is_comment;
                    this.setState({
                        feedList:tempArr,
                        postComment:'',
                        isShown: true,
                        showEmojis: false,
                        onlyTxt: false,
                        onlyEmoji: false,
                        showEditorwrapper: false

                    },()=>{
                        this.setState({
                            isLoading:false
                        })
                    })
                }
                else{
                    this.setState({
                        isLoading:false
                    })
                }
            })
            .catch((error) => {

            })
    }

    followUnfollowUser = (feedItem) => {
        

        let params = {
          follower_id: feedItem.user_id
        }
        let api = NC.baseURL + NC.USER_FOLLOW
        if (feedItem.is_follower == 1)
        {
          api = NC.baseURL + NC.USER_UNFOLLOW
        }
        WSManager.Rest(api, params).then(responseJson => {
          if (responseJson.response_code === NC.successCode) {

            let tempArr = this.state.feedList
            tempArr.forEach((element, index) => {
                if(element.user_id === feedItem.user_id) {
                    tempArr[index].is_follower = responseJson.data.is_follow;
                }
            });
            this.setState({
                        feedList:tempArr,
                        postComment:''
                    })
          }
        });
      }


    showComment = (feedItem) => {
        const {selectedItem} = this.state
        if (selectedItem === feedItem) {
            this.setState({
                selectedItem: ''
            })
        } else {
            this.setState({
                selectedItem: feedItem
            })

        }

    }


    bookMarkPost = (feedItem) => {
        
         
        let params = {
            "activity_id": feedItem.activity_id,
        }

        WSManager.Rest(NC.baseURL + NC.BOOKMARK_ACTIVITY, params)
            .then((response) => {
                
                if (response.response_code == NC.successCode) {
                    //notify.show(response.message, "success", 2500);
                    let tempArr = this.state.feedList
                    let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                    tempArr[itemIndex].is_bookmark = response.data.is_bookmark;
                    this.setState({
                        feedList:tempArr
                    })
                }
            })
            .catch((error) => {

            })
    }

    getTagsList = (search_key) => {

        let params = {
            //is_activity_tag: 0,
            //offset: 0,
            //limit: 10,
            search_key: search_key,
            country_id :WSManager.getProfile().master_country_id
        }

        WSManager.Rest(NC.baseURL + NC.GET_TAG_LIST, params)
            .then((response) => {
                

                this.setState({
                    tagsArray: response.data.tags
                })

            })
            .catch((error) => {
                console.log('GET_TAG_LIST Error=======>', error);
            })
    }

    selectUnselectTags = (item) => {
        if (this.state.selectedTags.length <= 0) {
            this.state.selectedTags.push(item)
            this.setState({ selectedTags: this.state.selectedTags })
        }
        else {
            let tagIndex = this.state.selectedTags.findIndex(element => element.tag_id == item.tag_id)
            if (tagIndex != -1) {
                let filteredTagsArray = this.state.selectedTags.filter(element => element.tag_id != item.tag_id)
                this.setState({
                    selectedTags: filteredTagsArray
                })
            }
            else {
                this.state.selectedTags.push(item)
                this.setState({ selectedTags: this.state.selectedTags })
            }
        }
    }

    onEditorStateChange = (postContent) => {
        this.setState({
          postComment: postContent
        })
    }

    
    _hideEmojis = (e) => {
        this.setState({
          onlyEmoji:!this.state.onlyEmoji,
          onlyTxt:false,
          isShown: !this.state.isShown
         })
    }
  
   showToolbar = () => {
      this.setState({
        onlyEmoji:false,
        onlyTxt:!this.state.onlyTxt,
        isShown: !this.state.isShown
      })
    }

    toggleCommentHandler = () => {
        this.setState({
          showEditorwrapper: !this.state.showEditorwrapper,
          
          },()=>{
              if(!this.state.showEditorwrapper){
                this.setState({
                    isShown: true
                })
              }
          });
        }

        checkValid = ()=>{
            
            let {postComment} = this.state
            if(postComment !== ''){
                let checkstr = draftToHtml(convertToRaw(postComment.getCurrentContent()))
                let content =  checkstr.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "")
                //console.log(content.trim().length,"content.trim().lengthcontent.trim().length")
              
                 if (content.trim().length == 0 ) {
                  return true
                }
            }
            
          }


    goToPostDetail = (feedItem) =>{
        this.props.history.push({
            pathname:'/post-detail', state:{feedItem:feedItem}
        })
    }

    goToUserProfile = (user_id) => {
        this.props.history.push('/user-profile?id='+encode(user_id))

    }

    render() {

        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
            showNavigation: true,
            setting: false
        }

        const { showSearchBar, selectedItem, postComment, isLoading,  feedList, hasMore, isShown, onlyTxt, onlyEmoji, showEditorwrapper } = this.state;

        const feedItemFooterProps = {
            likePost: this.likePost,
            showComment: this.showComment,
            reSharePost: this.reSharePost,
            bookMarkPost: this.bookMarkPost,
            
        }
        const FeedCommentPostProps = {
            addComment:this.addComment,
            checkValid:this.checkValid,
            showToolbar:this.showToolbar,
            toggleCommentHandler:this.toggleCommentHandler,
            postContent: postComment,
            isShown: isShown,
            showEditorwrapper: showEditorwrapper,
            onlyTxt: onlyTxt,
            onlyEmoji: onlyEmoji,
            placeholder:'Write a comment...',
            onEditorStateChange: this.onEditorStateChange
        }

        return (

            <MyContext.Consumer>

                {context => (
                    <React.Fragment>
                        {isLoading && <Loader />}
                        <div className="web-container feeds-web-container">
                            <Helmet titleTemplate={`${MetaData.template} | %s`}>
                                <title>{MetaData.Feeds.title}</title>
                                <meta name="description" content={MetaData.Feeds.description} />
                                <meta name="keywords" content={MetaData.Feeds.keywords}></meta>
                            </Helmet>
                            <Header {...this.props} HeaderOption={HeaderOption} />

                            <div className={'exp-bar'}>
                                <div className={'l-p'}>
                                    <i className={'icon-search'} onClick={this.searchToggleHandler} />
                                    {
                                        showSearchBar && <div className={'search-bar'}>
                                            <Input type={'text'} placeholder={'Search...'} ref={this.searchRef} />
                                        </div>
                                    }
                                </div>
                                <div className={'r-p'}>
                                    <div className={'exp-tags'}>
                                        {/* <span>#Soccer</span>
                                        <span>#Basketball</span>
                                        <span>#Sports</span>
                                        <span>#Soccer</span>
                                        <span>#Basketball</span>
                                        <span>#Sports</span> */}
                                        {
                                            this.state.tagsArray.length > 0
                                                ?
                                                this.state.tagsArray.map((item, index) => {

                                                    return (this.state.selectedTags.findIndex(ele => ele.tag_id == item.tag_id) != -1
                                                        ?
                                                        // <div onClick = {() => this.selectUnselectTags(item)} Style = {'display: flex; text-align: center; background-color: #03F5E7; color: #000000; padding: 0px 10px; border-radius: 50px; margin-right: 5px; font-size: 12px; font-family: MuliSemiBold; cursor: pointer;'}>
                                                        <span Style={'color: #000000;'} onClick={() => this.selectUnselectTags(item)}>{'#' + item.name}</span>
                                                        // {/* </div> */}
                                                        :
                                                        // <div onClick = {() => this.selectUnselectTags(item)} Style = {'display: flex; text-align: center; border-color: #03F5E7; border-style: solid; border-width: 1px; color: #03F5E7; padding: 0px 10px; border-radius: 50px; margin-right: 5px; font-size: 12px; font-family: MuliSemiBold; cursor: pointer;'}>
                                                        <span  onClick={() => this.selectUnselectTags(item)}>{'#' + item.name}</span>
                                                        // </div>
                                                    )

                                                })
                                                :
                                                null
                                        }
                                    </div>

                                </div>
                            </div>
                            {/* exp bar ends */}


                            <div className={'feeds-list-container'}  id="scrollableDiv" >
                            <InfiniteScroll
                                dataLength={feedList.length}
                                next={this.fetchMoreData.bind(this)}
                                hasMore={hasMore}
                                
                                
                            >
                                {
                                    feedList.map((feedItem, feedIndex) => {
                                        return (
                                            <FeedList 
                                            {...this.props} 
                                            feedItem={feedItem} 
                                            feedIndex={feedIndex} 
                                            FeedCommentPostProps={FeedCommentPostProps} 
                                            feedItemFooterProps={feedItemFooterProps} 
                                            selectedItem={selectedItem} 
                                            goToPostDetail={this.goToPostDetail} 
                                            followUnfollowUser={() => {
                                                if (feedItem.is_follower == 1)
                                                {
                                                  Header.ShowUnfollowModal((() => this.followUnfollowUser(feedItem)))
                                                }
                                                else
                                                {
                                                  this.followUnfollowUser(feedItem)
                                                }
                                              }}
                                            //followUnfollowUser={this.followUnfollowUser} 
                                            isShowRepost={true} 
                                            goToUserProfile={this.goToUserProfile}/>
                                        )
                                    })
                                }
                                </InfiniteScroll>
                                <Row>
                                    <Col xs={11}></Col>
                                    <Col xs={1}>
                                        <div onClick={() => this.props.history.push('/create-post')} className={'create-post-icon'}>
                                            <i className={'icon-plus'}></i>
                                        </div>
                                    </Col>
                                </Row>

                            </div>


                        </div>
                       

                    </React.Fragment>
                    
                )}
            </MyContext.Consumer>

        )
    }
}

export default Feeds;