import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";

export default class PaymentConfirmModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentDidMount() { }

  render() {
    const {
      IsPaymentModalShow,
      IsPaymentModalHide,
      confirmAction,
      plan_id,
      countineType,
      amount
    } = this.props;

    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsPaymentModalShow}
              onHide={IsPaymentModalHide}
              bsSize="small"
              className="center-modal"
              dialogClassName="joined-modal payment-confirm-modal"
            >
              <Modal.Body>
                <div className="text-center">
                  <img src={Images.THUMBSUP} alt="" className="thumbs-up-img" />
                  <div className="name">Awesome</div>
                  <p className="joined-txt">
                    {/* Funds are added successfully! */}
                  </p>
                  <Row className="subscription-text">
                    <Col xs={6}>
                      <div className="text-left subscription-for">
                        Premium{plan_id == 3 ? "+" : ""} Subscription
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="amt text-right">
                        <span>
                          <i className="icon-Rits" />
                          {amount}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <a
                    href
                    onClick={() => {
                      confirmAction(plan_id, countineType);
                      IsPaymentModalHide();
                    }}
                    className="btn btn-rounded btn-block btn-xlg"
                  >
                    Confirm Payment
                  </a>
                </div>
              </Modal.Body>
              <i
                className="icon-close icc"
                onClick={() => {
                  IsPaymentModalHide()
                }
                }
              />
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
