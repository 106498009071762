import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../../context';
import { Images } from '../../components';
// import { Header, SubHeader, ComingSoon, Images } from '../components';
// import { Helmet } from "react-helmet";
// import MetaData from "../helper/MetaData";
// import Config from '../Config';
// import WSManager from "../helper/WSManager";

export default class GroupChatHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentWillMount() {

    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div>
                        <div className='header-parent'>
                            <div className='profile-holder'>
                                <img src={Images.DEFAULT_USER} className='profile-h'/>
                            </div>
                            <div className='m-l-15'>
                                <img src={Images.MENUICON} />
                            </div>
                            <div>

                            </div>

                        </div>

                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
