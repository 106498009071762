import * as NC from "./NetworkingConstants";
import ls from "local-storage";
import { notify } from "react-notify-toast";
import base64 from "base-64";
import * as Constants from "./Constants";
import Config from '../Config';

import {encode} from 'base-64'

require("es6-promise").polyfill();
require("isomorphic-fetch");

const Accept_Type = "application/json, text/plain, */*";
const Content_Type = "application/json;charset=UTF-8";


export default class WSManager {
  constructor() {
    this.getToken = this.getToken.bind(this);
  }

  //multipartPost
  static multipartPost(url, body = {}) {
    const auth = this.getToken();
    const token = (!!auth && auth) || null;
    const settings =
        token !== null
            ? {
                method: "POST",
                body: body,
                headers: {
                    session_key: this.getToken() || this.getTempToken() || "",
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*",
                    // 'role': 2, /**Added for pickem tournament */
                }
            }
            : {
                method: "POST",
                body: body,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*",
                }
            };

    return fetch(url, settings)
        .then((response) => {
            return response.json()
        })
        .then(responseJson => {
            if (responseJson.response_code != NC.successCode) {
                var errorMsg = responseJson.message != '' ? responseJson.message : responseJson.global_error

                if (errorMsg == '') {
                    for (var key in responseJson.error) {
                        errorMsg = responseJson.error[key];
                    }
                }
                if (responseJson.response_code == NC.sessionExpireCode) {
                } else {
                    console.log('errorMsg', errorMsg);
                    // notify.show(errorMsg, "error", 5000);
                }
            }
            return responseJson;
        })
}


  // API
  static Rest(url, param) {
    return fetch(url, {
      method: "POST",
      headers: {
        Accept: Accept_Type,
        "Content-Type": Content_Type,
        session_key: this.getToken() || this.getTempToken() || ""
      },
      body: JSON.stringify(param)
    })
      .then(response => response.json())

      .then(responseJson => {
        if (responseJson.response_code != NC.successCode) {
          var errorMsg = this.parseErrorMessage(responseJson)
          if (responseJson.response_code == NC.sessionExpireCode) {
            WSManager.logout();
            notify.show(errorMsg, "error", 2500);
            setTimeout(() => {
              window.location.assign("/dashboard/0");
            }, 500);
          } else {
            if (errorMsg != "") {
              notify.show(errorMsg, "error", 5000);
            }
          }
        }

        return responseJson;
      })
      .catch(error => {
        console.error(error);
        // notify.show(Constants.Messages.wrong_error, "error", 4000);
        return { response_code: '500' };
      });
  }

  static RestGet(url) {
    return fetch(url, {
      method: "GET",
      headers: {
        Accept: Accept_Type,
        "Content-Type": Content_Type,
        session_key: this.getToken() || this.getTempToken() || ""
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.response_code != NC.successCode) {
          var errorMsg = this.parseErrorMessage(responseJson)
          if (responseJson.response_code == NC.sessionExpireCode) {
            WSManager.logout();
            notify.show(errorMsg, "error", 2500);
            setTimeout(() => {
              window.location.assign("/dashboard/0");
            }, 500);
          } else {
            notify.show(errorMsg, "error", 5000);
          }
        }
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
  }

  static parseErrorMessage(response) {
    var errorMsg = response.message != "" ? response.message : response.global_error;
    if (errorMsg == "") {
      for (var key in response.error) {
        errorMsg = response.error[key];
        if (errorMsg != "") {
          break;
        }
      }
    }
    return errorMsg
  }

  static encrypt(data) {
    return base64.encode(data);
  }

  static decrypt(data) {
    return base64.decode(data);
  }

  static isProfileCompleted() {
    return localStorage.getItem(Constants.localStorageKeys.refP_is_profile_complete) === "1";
  }

  static setProfileCompleted(is_profile_complete) {
    return localStorage.setItem(Constants.localStorageKeys.refP_is_profile_complete, is_profile_complete);
  }

  static setMasterData(master_data) {
    localStorage.setItem(Constants.localStorageKeys.refP_master_data, JSON.stringify(master_data));
  }

  static getMasterData() {
    const refP_master_data = localStorage.getItem(Constants.localStorageKeys.refP_master_data);
    return refP_master_data ? JSON.parse(refP_master_data) : "";
  }

  static loggedIn() {
    return localStorage.getItem(Constants.localStorageKeys.refP_session_key) !== null;
  }

  static setProfile(profile) {
    localStorage.setItem(Constants.localStorageKeys.refP_profile, JSON.stringify(profile));
  }

  static getProfile() {
    const profile = localStorage.getItem(Constants.localStorageKeys.refP_profile);
    return profile ? JSON.parse(profile) : {};
  }

  static setToken(idToken) {
    localStorage.setItem(Constants.localStorageKeys.refP_session_key, idToken);
  }

  static getToken = () => {
    return localStorage.getItem(Constants.localStorageKeys.refP_session_key) || "";
  };

  static setNotificationCount(count) {
    localStorage.setItem(Constants.localStorageKeys.refP_notification_count, count);
  }

  static getNotificationCount = () => {
    return localStorage.getItem(Constants.localStorageKeys.refP_notification_count) || "";
  };

  static setPlanForPurchase(data) {
    localStorage.setItem(Constants.localStorageKeys.refP_plan_selected_for_purchase, JSON.stringify(data));
  }

  static getPlanForPurchase = () => {
    const data = localStorage.getItem(Constants.localStorageKeys.refP_plan_selected_for_purchase);
    return data ? JSON.parse(data) : {};
  };


  static setUserPermissions(data) {
    localStorage.setItem(Constants.localStorageKeys.refP_user_permissions, data);
  }

  static getUserPermissions = () => {
    const data = localStorage.getItem(Constants.localStorageKeys.refP_user_permissions);
    return data ? JSON.parse(data) : {};
  };


  static setTempToken(idToken) {
    localStorage.setItem("id_temp_token", idToken);
  }

  static getTempToken() {
    return localStorage.getItem("id_temp_token");
  }

  static logout() {
    sessionStorage.clear();
    localStorage.clear();
    ls.clear();
  }

  static goToAppUrl(key, url = ``, ownProfile = false) {

    let nwUrl = `authenticate?token=${this.getToken()}&`+ url;

    if (key == 'refpredictor') {
      window.location.href = Config.RefpredictorUrl+ nwUrl
    }
    if (key == 'refleague') {
      window.location.href = Config.RefleagueUrl + nwUrl
      // if (ownProfile)
      //   window.location.href = Config.RefleagueUrl + url + `&token=${encode(this.getToken())}`
      // else
      //   window.location.href = Config.RefleagueUrl + nwUrl
    }
    else if (key == 'refpay') {
      window.location.href = Config.RefpayUrl + nwUrl
    }
    else if (key == 'refsocial') {
      window.location.href = Config.RefsocialUrl + nwUrl
    }
    else if (key == 'reflive') {
      window.location.href = Config.RefleagueUrl + `${nwUrl}topage=my-games/0`
    }
  }
}
