import React from 'react';
import { MyContext } from "../context";
import { Images } from '../components';

import {  UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import FeedFooterRow from './../components/FeedFooterRow';
import FeedCommentPost from './../components/FeedCommentPost';
import UtilityFunctions from '../helper/UtilityFunctions';
import _ from "lodash";

import WSManager from "../helper/WSManager";
import ReactHtmlParser from 'react-html-parser';
import * as NC from '../helper/NetworkingConstants'
import { SRLWrapper } from "simple-react-lightbox";

import SimpleReactLightbox from 'simple-react-lightbox'
import { UnFollowModal } from '../Modals';


class FeedList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showUnFollowModal:false,
        }

    }

    componentDidMount() {

    }




     showUnFollowModal = () => {
        this.setState({
          showUnFollowModal: true,
        });
      };
    
      UnFollowModalHide = () => {
        this.setState({
            showUnFollowModal: false
        });
      };
    


    


    render() {



        const {feedItem, feedIndex, feedItemFooterProps, FeedCommentPostProps, selectedItem, goToPostDetail, isShowRepost,showUnFollowModal  } = this.props
        

        const options = {
            settings: {
                overlayColor: "rgb(0, 0, 0)",
              },
            buttons:{
            showAutoplayButton:false,
            showDownloadButton:false,
            showThumbnailsButton:false,
            showFullscreenButton:false
            },
            
            disablePanzoom: false,
            disableWheelControls: false,
            hideControlsAfter: true,
            showThumbnailsButton :false,
            thumbnails: {
                showThumbnails: false
              }
        } 
        
        const optionsSignle = {
            settings: {
                overlayColor: "rgb(0, 0, 0)",
              },
            buttons:{
            showAutoplayButton:false,
            showDownloadButton:false,
            showThumbnailsButton:false,
            showFullscreenButton:false,
            showNextButton:false,
            showPrevButton:false,
            },
            
            disablePanzoom: false,
            disableWheelControls: false,
            hideControlsAfter: true,
            showThumbnailsButton :false,
            thumbnails: {
                showThumbnails: false
              }
        } 
        

        return (

            <MyContext.Consumer>

                {context => (
                    <React.Fragment>
                        <div className={'feed-box ' + (!isShowRepost ? 'comments-list' : '')}>
                            {
                                (!_.isNull(feedItem.parent_activity_id) && Number(feedItem.parent_activity_id) !== 0 && isShowRepost ) &&
                                <div className="respost-feed-block">
                                    <img src={require('../assets/img/repost.svg')}></img>
                                    <span onClick={() => this.props.goToUserProfile((feedItem.user_id))} className = {'user-name'}>{feedItem.parent_display_name}</span> 
                                    <span>
                                        {' '+ 'reposted'}
                                    </span>
                                </div>

                            }
                            <div className={'feed-hdr'}>
                                <div className={'l-p'}>
                                    <span className={'prof-pic'}>
                                        <img onClick={() => this.props.goToUserProfile((feedItem.parent_activity_id && feedItem.parent_activity_id != '' && feedItem.parent_activity_id != '0' ? feedItem.parent_user_id : feedItem.user_id))} src={feedItem.user_image != null && feedItem.user_image !== "" ? feedItem.user_image : Images.DEFAULT_USER} />
                                    </span>
                                    <div className={'prof-det'}>
                                        <h6 Style={'cursor: pointer;'} onClick={() => this.props.goToUserProfile((feedItem.parent_activity_id && feedItem.parent_activity_id != '' && feedItem.parent_activity_id != '0' ? feedItem.parent_user_id :feedItem.user_id))}>{feedItem.display_name}</h6>
                                        <div className={'usrnm-lb'}>{'@' + feedItem.user_name}</div>
                                        <div className={'tm-ago'}>{UtilityFunctions.getUtcToLocalFromNow(feedItem.created_date)}</div>
                                    </div>
                                </div>
                                { <div className={'r-p'}>
                                     
                                        {feedItem.user_id !== WSManager.getProfile().user_id && <span onClick = {() => this.props.followUnfollowUser(feedItem)}  
                                        className={'ad-ic'}><i className={ feedItem.is_follower == 0 ?
                                            'icon-refer-ic' : 'icon-user-select'
                                        } /></span>}
                                        {/* <i className="icon-refer-ic" onClick={this.showUnFollowModal}></i>
                                        <UnFollowModal
                                            IsUnFolloModalShow={showUnFollowModal}
                                            IsUnFolloModalHide={this.UnFollowModalHide}
                                            /> */}
                                    
                                    <span>
                                        <UncontrolledDropdown direction={'left'} className={'act-dropdown'}>
                                            <DropdownToggle> <i className={'icon-toggle-vertical'} /></DropdownToggle>
                                            {feedItem.user_id !== WSManager.getProfile().user_id ?<DropdownMenu>
                                                <DropdownItem><i className={'icon-hide'} />Hide</DropdownItem>
                                                <DropdownItem><i className={'icon-block'} />Block</DropdownItem>
                                                <DropdownItem><i className={'icon-report'} />Report</DropdownItem>
                                            </DropdownMenu>:
                                            <DropdownMenu>
                                                <DropdownItem><i className={'icon-hide'} />Edit</DropdownItem>
                                                <DropdownItem><i className={'icon-block'} />Delete</DropdownItem>
                                            </DropdownMenu>
                                            }
                                        </UncontrolledDropdown>
                                    </span>
                                </div>}
                            </div>
                            <div className={'feed-cntnt ' + (isShowRepost ? 'cursor-pointer' : '')}>
                                <div className={'content-text'} onClick={() => isShowRepost ? goToPostDetail(feedItem) : ''}>
                                    <span>
                                        {ReactHtmlParser(feedItem.post_content)}
                                            
                                    </span>
                                </div>
                                <div className={'content-tags'} onClick={() => isShowRepost ? goToPostDetail(feedItem) : ''}>

                                    {
                                        feedItem.activity_tags.map((tagItem, tagIndex) => {
                                            return (
                                                <span>{'#' + tagItem.tag_name + ' '}</span>
                                            )
                                        })
                                    }

                                </div>
                                {
                                    feedItem.gallery && feedItem.gallery.length ==1 
                                        ? 
                                        <div className={'content-media single'}>
                                             <SimpleReactLightbox>
                                             <SRLWrapper  options={optionsSignle}> 
                                            {
                                                feedItem.gallery.map((imageItem, imageIndex) => {
                                                    return (
                                                        <div className={'content-image '} key={imageIndex}>
                                                            <img alt="" src={NC.s3_dev + imageItem.media_path}/>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </SRLWrapper>
                                            </SimpleReactLightbox>
                                            
                                        </div>
                                        : 
                                        <div className={'content-media'}>
                                            <SimpleReactLightbox>
                                             <SRLWrapper  options={options}> 
                                            {
                                                //feedItem.gallery.slice(0, 4).map((imageItem, imageIndex) => {
                                                    feedItem.gallery.map((imageItem, imageIndex) => {
                                                    const slicedArray =  feedItem.gallery.slice(0, 4);
                                                    return (
                                                        <div className={`content-image ${feedItem.gallery.length>4 && imageIndex == 3 ? 'overlay-count': imageIndex > 3 ? 'none': ''}` } key={imageIndex}>
                                                            <img  src={NC.s3_dev + imageItem.media_path} />
                                                            <h4 className="count-image">+{feedItem.gallery.length-slicedArray.length}</h4>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </SRLWrapper>
                                            </SimpleReactLightbox>
                                        </div>
                                        
                                }
                                {/* for video view  */}
                                {/* {
                                    feedItem.gallery && feedItem.gallery.length > 0
                                        ?
                                        <div className={''}>    
                                        <div className="post-view-wrapper">
                                            <div className="viewer-wrapper">
                                                <i className="icon-eye"></i>
                                                <span>15.4 k</span>

                                            </div>
                                            <div className = {'followed-by-container'}>
                                                <div className = 'followed-by-users-image'> 
                                                    <img src ={ Images.DEFAULT_USER}/> 
                                                    <img src ={ Images.DEFAULT_USER}/> 
                                                    <img src ={ Images.DEFAULT_USER}/> 
                                                </div>
                                                </div>
                                            </div>  
                                            
                                        </div>
                                        :
                                        null
                                } */}


                            </div>
                            <div className={'feed-footer'}>
                                <FeedFooterRow feedItemFooterProps={feedItemFooterProps} feedItem={feedItem} feedIndex={feedIndex} ></FeedFooterRow>
                            </div>
                            {
                                (selectedItem === feedIndex || !isShowRepost) &&
                                <FeedCommentPost FeedCommentPostProps={FeedCommentPostProps} feedItem={feedItem} />


                            }
                        </div>
                    </React.Fragment>
                )}
            </MyContext.Consumer>

        )
    }
}

export default FeedList;