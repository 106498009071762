import React from "react";
import { Modal } from "react-bootstrap";
import { MyContext } from "../context";

export default class UnFollowModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    // showUnfollowModal: true,
    //   unfollowAction: unfollowAction
    const { showUnfollowModal, unfollowModalHide, unfollowAction, AlertData } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={showUnfollowModal}
              onHide={unfollowModalHide}
              dialogClassName="alert-modal modal-sm"
              className="center-modal unfollow-modal"
            >
              <Modal.Body>
                <h2>Alert</h2>
                <p>Are you sure you want to Unfollow?</p>
                <div className="modal-footer">
                  <a href
                    onClick={() => { unfollowAction(); unfollowModalHide(); }}
                    className="btn bnt-rounded">
                    Yes
                        </a>
                  <a href
                    onClick={unfollowModalHide}
                    className="btn bnt-rounded">
                    No
                        </a>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
