
import { createStore, applyMiddleware, compose } from 'redux';

import thunkMiddleware from 'redux-thunk';
import Reducers from './Reducers';
import { combineReducers } from 'redux';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const ConfigureStore = () => {

    const middlewares = [
        thunkMiddleware,
    ];
    const reducersArr = combineReducers({
        store:Reducers,
    })

    
    const store =  createStore(
        reducersArr, composeEnhancer(applyMiddleware(...middlewares))     
    )
    return store;
    
};

export default ConfigureStore;