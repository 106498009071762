import React from "react";
import { MyContext } from "../context";
import { FacebookProvider, Page } from 'react-facebook';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import * as Constants from "../helper/Constants";
import _ from "lodash";
import Images from "../components/images";
import { connect } from 'react-redux';
import {  UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import UtilityFunctions from "../helper/UtilityFunctions";



class FeedFooterRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        feedItem: props.feedItem,
        feedIndex: props.feedIndex,
    };
    
  }


  render() {

    const {
      feedItem,
      feedIndex
    } = this.state;
    
    return (
      <MyContext.Consumer>
        {context => (
          <div className = {'row-container'}>
              <div className = {'item-container'}>
                    <div className = {'items'}>
                      <i onClick = {() => this.props.feedItemFooterProps.likePost(feedItem, feedIndex)} className={"icon-like1 lg " + (feedItem.is_like == 0 ? '' : 'active')}></i>
                      <span>{UtilityFunctions.abbreviateNumber(feedItem.no_of_likes)}</span>
                    </div>
                    <div className = {'items'}>
                      {/* <i onClick={()=>this.props.feedItemFooterProps.reSharePost(feedItem, feedIndex)} className={`icon-repost ${Number(feedItem.is_share) !== 0 ? 'active':''}`}></i>
                      <span>{feedItem.no_of_share}</span> */}

                     <span>
                          <UncontrolledDropdown direction={'buttom'} className={'act-dropdown'}>
                              <DropdownToggle> <i className={`icon-repost ${Number(feedItem.is_share) !== 0 ? 'active':''}`}  />
                              <span>{feedItem.no_of_share}</span> 
                              </DropdownToggle>
                             <DropdownMenu>
                                  <DropdownItem  onClick={()=>this.props.feedItemFooterProps.reSharePost(feedItem, feedIndex)}><i className={'icon-repost'}  />Repost</DropdownItem >
                                  <DropdownItem ><i className={'icon-quote'}  style={{'font-size': '13px'}}/>Quote Post</DropdownItem >
                              </DropdownMenu>
                             
                              
                          </UncontrolledDropdown>
                      </span>
                    </div>
                    <div className = {'items'}>
                      <i  onClick={()=>this.props.feedItemFooterProps.showComment(feedIndex)} className={`icon-comment-fill  ${Number(feedItem.is_comment) !== 0 ? 'active' : '' }`}></i>
                      <span>{UtilityFunctions.abbreviateNumber(feedItem.no_of_comments)}</span>
                    </div>
                    <div className = {'items'}>
                      <i className="icon-share-fill"></i>
                    </div>
                    <div className = {'items'}>
                      {
                        feedItem.is_bookmark == 0 ? <i className="icon-bookmaker" onClick={()=>this.props.feedItemFooterProps.bookMarkPost(feedItem)}></i> :
                        <i className="icon-saved active" onClick={()=>this.props.feedItemFooterProps.bookMarkPost(feedItem)}></i>

                      }
                      
                    </div>
              </div>
          </div>
        )}

      </MyContext.Consumer>
    );
  }
}

export default FeedFooterRow
