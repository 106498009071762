import React from "react";
import { Modal } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";

export default class Thankyou extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { IsThankyouModalShow, IsThankyouModalHide } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsThankyouModalShow}
              onHide={this.props.IsThankyouModalHide}
              dialogClassName="custom-modal-with-bg thank-you-modal"
            >
              <div className="modal-overlay" />
              <Modal.Body>
                <a
                  href
                  className="modal-close-btn"
                  onClick={() => {
                    this.props.history.goBack();
                    IsThankyouModalHide();
                  }}
                >
                  <i className="icon-close" />
                </a>
                <div className="thank-you-body">
                  <img src={Images.DONE} alt="" />
                  <h4>Thank You!</h4>
                  <p>
                    Your withdrawal request has been submitted successfully for
                    admin approval.
                  </p>
                  <a
                    href
                    onClick={() => this.props.history.goBack()}
                    className="btn btn-rounded"
                  >
                    Done
                  </a>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
