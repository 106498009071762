import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";

export default class SubmitModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      IsSubmitModalShow,
      IsSubmitModalHide,
      message,
      countineType
    } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsSubmitModalShow}
              onHide={() => {
                this.props.history.push("/my-games/0");
                IsSubmitModalHide()
              }
              }
              bsSize="small"
              className="center-modal"
              dialogClassName=" user-action-modal"
            >
              <div className="modal-overlay" />

              <Modal.Body>
                <div className="user-action-body">
                  <img src={Images.CIRCULAR_TICK} alt="" />
                  <h4>Game on!</h4>
                  <div className="modal-msg">{message}</div>
                  {countineType == "prediction" ? (
                    <Button
                      onClick={() => {
                        IsSubmitModalHide();
                        this.props.history.push("/dashboard/1");
                      }}
                      className="m-b-20 btn btn-rounded btn-primary"
                    >
                      Join Contests
                    </Button>
                  ) : (
                      <Button
                        onClick={() => {
                          IsSubmitModalHide();
                          window.location.assign(
                            "/dashboard/" +
                            (countineType == "contest" ? "1" : "0")
                          );
                        }}
                        className="m-b-20 btn btn-rounded btn-primary"
                      >
                        Go Back To Lobby
                    </Button>
                    )}
                  {countineType == "prediction" ? (
                    <div className="footer-action">
                      <a
                        href
                        onClick={() => {
                          this.props.history.push("/my-games/0");
                          IsSubmitModalHide();
                        }}
                        className="link-anchor m-b-40"
                      >
                        Go to My Games
                      </a>
                      <a
                        href
                        onClick={() => {
                          this.props.history.push("/dashboard/0");
                          IsSubmitModalHide();
                        }}
                        className="link-anchor m-b-40"
                      >
                        Predict More Games
                      </a>
                    </div>
                  ) : (
                      <div className="footer-action">
                        <a
                          href
                          onClick={() => {
                            this.props.history.push("/my-contest");
                            IsSubmitModalHide();
                          }}
                          className="link-anchor m-b-40"
                        >
                          Go to My Contest
                      </a>
                      </div>
                    )}
                </div>
              </Modal.Body>
              <i
                className="icon-close icc"
                onClick={() => {
                  this.props.history.push("/my-games/0");
                  IsSubmitModalHide()
                }
                }
              />
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
