import React from "react";
import { Modal, Button } from "react-bootstrap";
import { MyContext } from "../context";
import { Images } from "../components";

export default class CongratsPopup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { IsCongratsModalShow, IsCongratsModalHide, message } = this.props;

    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            show={IsCongratsModalShow}
            onHide={IsCongratsModalHide}
            bsSize="small"
            className="center-modal"
            dialogClassName="joined-modal payment-confirm-modal congrats-popup"
          >
            <Modal.Body>
              <div className="text-center">
                <img
                  src={Images.CONGRATS_BG}
                  alt=""
                  className="thumbs-up-img"
                />
                <div className="name">CONGRATULATIONS!</div>
                <p className="joined-txt">{message}</p>

                <Button
                  onClick={() => {
                    IsCongratsModalHide();
                  }}
                  className="btn btn-rounded "
                >
                  OK
                </Button>
                {/* <div className="share-txt"><i className="icon-share"></i>Share</div> */}
              </div>
            </Modal.Body>
            <i
              className="icon-close icc"
              onClick={() => {
                IsCongratsModalHide()
              }
              }
            />
          </Modal>
        )}
      </MyContext.Consumer>
    );
  }
}
