import React from "react";
import { Button, Modal, Tabs, Tab, Table, ProgressBar, Panel } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import * as Constants from "../helper/Constants";
import InfiniteScroll from "react-infinite-scroll-component";

export default class ContestDetailModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ContestDetail: this.props.showContestDetailFor,
      ScoringRules: Constants.scoring_rules,
      userEntries: [],
      roundPredictionCount: this.props.roundPredictionCount,
      isFrom: this.props.isFrom,
      user_offset: 0,
      hasMore: false
    };
  }

  showProgressBar = (join, total) => {
    return (join * 100) / total;
  };

  componentDidMount() {
    if (this.state.isFrom == 'privatecontest' && (this.state.ContestDetail.is_user_joined != 0 || this.state.ContestDetail.contest_status != 0 || parseInt(this.state.ContestDetail.size) <= parseInt(this.state.ContestDetail.total_user_joined))) {
      this.setState({ isFrom: 'myContest' })
    }
    this.getGameUserDetail();
  }

  getGameUserDetail() {
    let param = {
      contest_unique_id: this.state.ContestDetail.contest_unique_id,
      limit: 50,
      offset: this.state.user_offset
    };
    WSManager.Rest(NC.baseURL + NC.GET_GAME_USER_DETAIL, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          if (this.state.user_offset == 0) {
            this.setState({
              userEntries: responseJson.data.users
            });
          } else {
            this.setState({
              userEntries: [
                ...this.state.userEntries,
                ...responseJson.data.users
              ]
            });
          }
          this.setState({
            user_offset: responseJson.data.next_offset,
            hasMore: responseJson.data.is_load_more
          });
        }
      }
    );
  }

  fetchMoreData = () => {
    this.getGameUserDetail();
  };

  render() {
    const { IsContestDetailShow, IsContestDetailHide } = this.props;
    const {
      ContestDetail,
      ScoringRules,
      userEntries,
      roundPredictionCount,
      isFrom,
      hasMore
    } = this.state;
    //
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsContestDetailShow}
              onHide={IsContestDetailHide}
              bsSize="large"
              dialogClassName={
                "contest-detail-modal" +
                (isFrom == "myContest" ? " contest-detail-without-btn" : "")
              }
            >
              <Modal.Header>
                <Modal.Title>
                  <img src={Images.LOGO} alt="" className="vissel-img" />
                  <a href onClick={IsContestDetailHide} className="modal-close">
                    <i className="icon-close" />
                  </a>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs
                  onSelect={this.ontabSelect}
                  defaultActiveKey={1}
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey={1} title="Winnings">
                    <div className="winning-section">
                      <div className="winning-tab-header">
                        <div className="winning-amt text-center text-uppercase">
                          <div>Prize pool</div>
                          <span>₦{ContestDetail.prize_pool}*</span>
                        </div>
                        {ContestDetail.is_custom_prize_pool == 0 &&
                          <div className="winner-count text-center">
                            Top{ContestDetail.number_of_winner} Winner(s)
                          </div>
                        }
                      </div>
                      <div className="table-heading">Winnings Distribution</div>
                      <Table responsive>
                        <tbody>
                          {_.map(
                            ContestDetail.prize_distibution_detail,
                            (item, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    {item.min}
                                    {item.min != item.max && (
                                      <span>- {item.max}</span>
                                    )}
                                  </td>
                                  <td className="text-right">{item.per}%</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>

                      <div className="tab-description">
                        *The Prize Calculation is according to minimum{" "}
                        {ContestDetail.prize_distibution_detail.min} entries and
                        number of participants joined. This will continuously
                        change as more participants join.
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey={2} title="Info">
                    <div className="info-section">
                      <div className="">
                        <Panel id="collapsible-panel-example-1" defaultExpanded>
                          <Panel.Heading>
                            <Panel.Title />
                            <Panel.Toggle componentClass="a">
                              Scoring Rules
                              <i className="icon-arrow-up" />
                              <i className="icon-arrow-down" />
                            </Panel.Toggle>
                          </Panel.Heading>
                          <Panel.Collapse>
                            <Panel.Body>
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th>Normal</th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {ScoringRules.map((item, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="">
                                          {item.question_name}
                                        </td>
                                        <td className="text-right">
                                          {item.score}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </Panel.Body>
                          </Panel.Collapse>
                        </Panel>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey={3} title="Entries">
                    <div className="entries-section">
                      <div className="progress-bar-default">
                        <ProgressBar
                          className={
                            parseInt(ContestDetail.total_user_joined) <
                              parseInt(ContestDetail.minimum_size)
                              ? "danger-area"
                              : ""
                          }
                          now={this.showProgressBar(
                            ContestDetail.total_user_joined,
                            ContestDetail.size
                          )}
                        />
                        <div className="progress-bar-value">
                          <span className="total-output">
                            {ContestDetail.total_user_joined}
                          </span>{" "}
                          /{" "}
                          <span className="total-entries">
                            {ContestDetail.size} Entries
                          </span>
                          <span className="min-entries">
                            Min.{ContestDetail.minimum_size}
                          </span>
                        </div>
                      </div>

                      <InfiniteScroll
                        dataLength={userEntries.length}
                        next={this.fetchMoreData.bind(this)}
                        hasMore={hasMore}
                        scrollableTarget="uncontrolled-tab-example-pane-3"
                      >
                        <Table responsive>
                          <tbody className="table-body">
                            {userEntries.length > 0 &&
                              userEntries.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className="user-entry">
                                      <img
                                        src={item.image || Images.DEFAULT_USER}
                                        alt=""
                                        className="user-img"
                                      />
                                      <span className="user-name">
                                        {item.user_name}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </Tab>
                </Tabs>
              </Modal.Body>
              {isFrom != "myContest" && (
                <Modal.Footer>
                  <Button
                    className="btn-block btn-primary"
                    onClick={event => {
                      IsContestDetailHide();
                      this.props.onJoinBtnClick(
                        ContestDetail.contest_id,
                        ContestDetail.round_id,
                        ContestDetail.entry_fee,
                        ContestDetail.contest_name,
                        roundPredictionCount,
                        event
                      );
                    }}
                  >
                    {ContestDetail.entry_fee > 0 ? (
                      <React.Fragment>
                        Join ₦{ContestDetail.entry_fee}
                      </React.Fragment>
                    ) : (
                        "Free Entry"
                      )}
                  </Button>
                </Modal.Footer>
              )}
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
