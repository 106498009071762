import React, { Component } from "react";
import ReactJWPlayer from "react-jw-player";
import { Player, BigPlayButton, ControlBar, Shortcut } from "video-react";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";

const YoutubeOpts = {
  width: "100%",
  playerVars: {
    autoplay: 1,
    controls: 0,
    disablekb: 1,
    modestbranding: 0
  }
};

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.newShortcuts = [
      {
        keyCode: 39,
        handle: () => { }
      },
      {
        keyCode: 37,
        handle: () => { }
      }
    ];
  }

  render() {
    const { playlist, playerType } = this.props;
    return playerType === "JWPlayer" ? (
      <ReactJWPlayer
        playerId="MYdlWK4b"
        playerScript="https://cdn.jwplayer.com/libraries/euov7Z4F.js"
        playlist={playlist}
        onPlay={this.props.videoStartCallback}
        onPause={this.props.videPauseCallback}
      />
    ) : playerType === "Youtube" ? (
      <YouTube
        autoplay
        opts={YoutubeOpts}
        videoId={playlist[0].videoID}
        onEnd={() => this.props.videoEndCallBack()}
        onPlay={this.props.videoStartCallback}
        onPause={this.props.videPauseCallback}
      />
    ) : playerType === "Vimeo" ? (
      <Vimeo
        video={playlist[0].videoID}
        autoplay
        onEnd={() => this.props.videoEndCallBack()}
        onPlay={this.props.videoStartCallback}
        onPause={this.props.videPauseCallback}
      />
    ) : (
            <Player
              loop={false}
              constrols={false}
              fluid={false}
              playsInline
              poster={playlist[0].image}
              autoPlay
              ref="player"
              src={playlist[0].file}
            >
              <BigPlayButton position="center" />
              <Shortcut shortcuts={this.newShortcuts} />
              <ControlBar disabled autoHide={false} className="video-controls" />
            </Player>
          );
  }
}

VideoPlayer.propTypes = {};

export default VideoPlayer;
