import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default class HighGraph extends Component {
  render() {
    const { HighGraphConfigOption } = this.props;
    return (
      <div>
        <Row>
          <Col sm={4} />
          <Col sm={4}>
            {HighGraphConfigOption.GraphHeaderTitle.map((headerdata, index) => (
              <div key={index}>{headerdata.title}</div>
            ))}
          </Col>
          <Col sm={4} />
        </Row>

        <HighchartsReact
          highcharts={Highcharts}
          options={HighGraphConfigOption}
        />

        <Row>
          {HighGraphConfigOption.LineData.map((linedata, index) => (
            <Col sm={6}>
              <div key={index}>
                {linedata.title} :{linedata.value}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}
