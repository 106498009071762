import React from "react";
import { MyContext } from "../context";
import { FacebookProvider, Page } from 'react-facebook';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import * as Constants from "../helper/Constants";
import _ from "lodash";
import Images from "../components/images";
import { connect } from 'react-redux';



class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContentFor: 0,
    };
  }



  showContent = (showfor) => {
    this.setState({
      showContentFor: showfor
    })
  }



  render() {

    const {
      showContentFor
    } = this.state;
    return (
      <MyContext.Consumer>
        {context => (
          <div className={`footer-container ${this.props.footCls}`} id="footerid">
            <div className={'d-none'}>
              <ul>
                {
                  _.map(Constants.FooterOptions, (item, idx) => {
                    return (
                      <li key={item.title}>
                        <a
                          href={item.href}
                          target="__blank"
                        >{item.title}</a>
                        {item.logo &&
                          <img src={Images.LOGO} alt="" />
                        }
                      </li>
                    )
                  })
                }
                <li className="footer-text">{Constants.Messages.footer_regulsn}</li>
                <li><a
                  // href 
                  className="copy-right-text">{Constants.Messages.copyright}</a>
                </li>
              </ul>
              <div className={"tweet-content" + (showContentFor === 1 ? " show-tweets-content" : " ")}>
                <p className="m-b-40">by @ReFPredictor
                <a
                    // href 
                    className="close-feeds" onClick={() => this.showContent(0)}>
                    <i className="icon-close"></i>
                  </a>
                </p>
                <div className="iframe-container">
                  <a className="twitter-timeline" href="https://twitter.com/ReFPredictor" data-widget-id="344991924744253441" data-height="220">Tweets by @ReFPredictor</a>
                  <TwitterTweetEmbed
                    tweetId={'344991924744253441'}
                  />
                </div>
              </div>
              <div className={"feed-content" + (showContentFor === 2 ? " show-tweets-content" : " ")}>
                <p className="m-b-40">by @ReFPredictor
                <a
                    // href 
                    className="close-feeds" onClick={() => this.showContent(0)}>
                    <i className="icon-close"></i>
                  </a>
                </p>
                <p className="iframe-container hidden-sm">
                  <FacebookProvider appId="441447502995003" width="500" >
                    <Page href="https://www.facebook.com/refpredictor/" tabs="timeline" width="500" />
                  </FacebookProvider>
                </p>
                <p className="iframe-container visible-sm">
                  <FacebookProvider appId="441447502995003" >
                    <Page href="https://www.facebook.com/refpredictor/" tabs="timeline" />
                  </FacebookProvider>
                </p>
              </div>
              <div className={"footer-bottom" + (showContentFor != 0 ? ' feed-shadow' : '')}>
                <a
                  // href 
                  className={showContentFor === 1 ? 'open tweets' : 'tweets'} onClick={() => this.showContent(1)}>
                  <i className="icon-twitter"></i>Tweets
              </a>
                <a
                  // href 
                  className={showContentFor === 2 ? 'open' : ''} onClick={() => this.showContent(2)}>
                  <i className="icon-facebook"></i>Feeds
              </a>
              </div>

            </div>
          </div>
        )}

      </MyContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
    footCls: state.store.UiReducer.footer_clsnm
  };
};


export default connect(mapStateToProps, null)(Footer);
