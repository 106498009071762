import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, Images } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container lobby-web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.privacypolicy.title}</title>
                            <meta name="description" content={MetaData.privacypolicy.description} />
                            <meta name="keywords" content={MetaData.privacypolicy.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="Privacy Policy" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                                    <div className="text-center m-t-30">
                                        <h2>Coming Soon</h2>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}
