import React from 'react';
import { MyContext } from "../context";
import { Header, Loader } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";
import FeedList from './../components/FeedList';
import CommentList from './../components/CommentList';
import _ from "lodash";
import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";
import { encode } from 'base-64';
import * as NC from '../helper/NetworkingConstants'
import InfiniteScroll from "react-infinite-scroll-component";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
class PostDetail extends React.Component {
    constructor(props) {
        super(props);
        const html = '';
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const postComment = EditorState.createWithContent(contentState);
        const commentReply = EditorState.createWithContent(contentState);
        this.state = {
            postComment,
            commentReply,
            feedList: [],
            commentLists: [],
            replyLists: [],
            selectedItem: '',
            selectedData: '',
            totalReplys: '',
            isLoading: false,
            showHideTxt: false,
            hasMoreComments: false,
            hasMoreReply: false,
            offsetReply: 1,
            offsetComment: 1,
            no_of_comments: '',
            isShown: true,
            showEmojis: false,
            onlyTxt: false,
            onlyEmoji: false,
            showEditorwrapper: false,
            isShownReply: true,
            showEmojisReply: false,
            onlyTxtReply: false,
            onlyEmojiReply: false,
            showEditorwrapperReply: false
        }

    }

    componentDidMount() {
        this.getComments(this.props.location.state.feedItem.activity_id)
        this.getFeedList(this.props.location.state.feedItem.activity_id)

    }

    getFeedList = (activity_id) => {
        this.setState({ isLoading: true });
        let params = {
            activity_id: activity_id
        }

        WSManager.Rest(NC.baseURL + NC.ACTIVITY, params)
            .then((response) => {
                if (response.response_code === NC.successCode) {
                    this.setState({
                        feedList: response.data.list,
                        isLoading: false,
                        no_of_comments:response.data.list[0].no_of_comments
                    })
                }
            })
            .catch((error) => {
                console.log('getFeedList Error=======>', error);
            })
    }



    likePost = (feedItem) => {
        const { feedList } = this.state
        const { activity_id: entity_id, activity_type_id: entity_type } = feedItem
        let params = {
            "entity_id": entity_id,
            "entity_type": entity_type
        }

        WSManager.Rest(NC.baseURL + NC.TOGGLE_LIKE, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    feedItem.is_like = feedItem.is_like == 0 ? 1 : 0
                    feedItem.no_of_likes = feedItem.is_like == 0 ? Number(feedItem.no_of_likes) - 1 : Number(feedItem.no_of_likes) + 1
                    this.setState({ feedList: feedList })
                }
            })
            .catch((error) => {

            })
    }
    commentLike = (feedItem) => {
        const { commentLists } = this.state
        let params = {
            "entity_id": feedItem.comment_id,
            "entity_type": 2
        }

        WSManager.Rest(NC.baseURL + NC.TOGGLE_LIKE, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    feedItem.is_like = feedItem.is_like == 0 ? 1 : 0
                    feedItem.no_of_likes = feedItem.is_like == 0 ? Number(feedItem.no_of_likes) - 1 : Number(feedItem.no_of_likes) + 1
                    this.setState({ commentLists: commentLists })
                }
            })
            .catch((error) => {

            })
    }



    reSharePost = (feedItem) => {
        let params = {
            "activity_id": feedItem.activity_id,
        }

        WSManager.Rest(NC.baseURL + NC.RESHARE_ACTIVITY, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    if(_.isEmpty(response.data)){
                        notify.show(response.error, "error", 2500);
                    }
                    let tempArr = this.state.feedList
                    let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                    tempArr[itemIndex].no_of_share = response.data[0].no_of_share;
                    tempArr[itemIndex].is_share = response.data[0].is_share;
                    this.setState({
                        feedList:tempArr
                    })
                }
            })
            .catch((error) => {

            })
    }

    addComment = (feedItem) => {

        this.setState({
            isLoading:true
        })
        const { postComment, feedList, commentLists } = this.state

        let params = {
            "activity_unique_id": feedItem.activity_unique_id,
            "comment": draftToHtml(convertToRaw(postComment.getCurrentContent())),

        }

        WSManager.Rest(NC.baseURL + NC.ADD_COMMENT, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    let tempArr = feedList
                    let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                    tempArr[itemIndex].no_of_comments = response.data.activity_comment_count;
                    tempArr[itemIndex].is_comment = response.data.is_comment;
                    let tempArrC = commentLists

                    tempArrC.unshift(response.data.list[0])

                    this.setState({
                        postComment: '',
                        commentLists: tempArrC,
                        feedList: tempArr,
                        no_of_comments:response.data.activity_comment_count,
                        isShown: true,
                        showEmojis: false,
                        onlyTxt: false,
                        onlyEmoji: false,
                        showEditorwrapper: false

                    }, () => {
                        this.setState({
                            isLoading:false
                        })
                    })

                }
                else{
                    this.setState({
                        isLoading:false
                    })
                }
            })
            .catch((error) => {

            })
    }



    showComment = (feedItem) => {
        const { selectedItem } = this.state
        if (selectedItem === feedItem) {
            this.setState({
                selectedItem: ''
            })
        } else {
            this.setState({
                selectedItem: feedItem
            })

        }

    }


    bookMarkPost = (feedItem) => {
        const { feedList } = this.state
        let params = {
            "activity_id": feedItem.activity_id,
        }

        WSManager.Rest(NC.baseURL + NC.BOOKMARK_ACTIVITY, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                   // notify.show(response.message, "success", 2500);
                    let tempArr = feedList
                    let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                    tempArr[itemIndex].is_bookmark = response.data.is_bookmark;
                    this.setState({
                        feedList: tempArr
                    })
                }
            })
            .catch((error) => {

            })
    }

    getComments = (activity_id) => {
        const {offsetComment} = this.state
        this.setState({ isLoading: true });
        let params = {
            "activity_id": activity_id,
            page: offsetComment,
            limit: 10,

        }

        WSManager.Rest(NC.baseURL + NC.GET_COMMENTS, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    this.setState({
                        hasMoreComments:response.data.isloadmore,
                        offsetComment:response.data.page,
                        isLoading: false
                    })
                    if (this.state.offsetComment > 0) {
                        this.setState({
                            commentLists: [
                            ...this.state.commentLists,
                            ...response.data.list
                          ]
                        });
                      } else {
                        this.setState({
                            commentLists: response.data.list
                        });
                      }

                }
            })
            .catch((error) => {

            })
    }

    hideReplys = ()=>{
        this.setState({
            replyLists:[],
            showHideTxt:false,
            offsetReply: 1,
            selectedItem:''
        })
    }
    getReplysList = (item)=>{
        this.setState({
            replyLists:[],
            offsetReply:1
        },()=>{
            this.getReplys(item)
        })

    }
    getReplys = (item) => {
        const {offsetReply} = this.state
        this.setState({ isLoading: true, selectedItem: item.comment_id, selectedData:item }, () => {
        });
        let params = {
            "activity_id": this.props.location.state.feedItem.activity_id,
            "parent_comment_id": item.comment_id,
            page: offsetReply,
            limit: 10,
        }

        WSManager.Rest(NC.baseURL + NC.GET_COMMENTS, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    this.setState({
                        isLoading: false,
                        hasMoreReply:response.data.isloadmore,
                        offsetReply:response.data.page,
                        totalReplys:response.data.total,
                        showHideTxt:true,
                    })
                    if (offsetReply > 0) {
                        this.setState({
                            replyLists: [
                            ...this.state.replyLists,
                            ...response.data.list
                          ]
                        });
                      } else {
                        this.setState({
                            replyLists: response.data.list
                        });
                      }

                }
            })
            .catch((error) => {

            })
    }

    checkValid = ()=>{
            
        let {postComment} = this.state
        if(postComment !== ''){
            let checkstr = draftToHtml(convertToRaw(postComment.getCurrentContent()))
            let content =  checkstr.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "")
            //console.log(content.trim().length,"content.trim().lengthcontent.trim().length")
          
             if (content.trim().length == 0 ) {
              return true
            }
        }
        
      }

    
    onEditorStateChange = (postContent) => {
        this.setState({
          postComment: postContent
        })
    }

    _hideEmojis = (e) => {
        this.setState({
          onlyEmoji:!this.state.onlyEmoji,
          onlyTxt:false,
          isShown: !this.state.isShown
         })
    }
  
   showToolbar = () => {
      this.setState({
        onlyEmoji:false,
        onlyTxt:!this.state.onlyTxt,
        isShown: !this.state.isShown
      })
    }

    toggleCommentHandler = () => {
        this.setState({
          showEditorwrapper: !this.state.showEditorwrapper,
          
          },()=>{
              if(!this.state.showEditorwrapper){
                this.setState({
                    isShown: true
                })
              }
          });
        }

        onEditorStateChangeReply = (postContent) => {
            this.setState({
                commentReply: postContent
            })
        }

        
    _hideEmojisReply = (e) => {
        this.setState({
          onlyEmojiReply:!this.state.onlyEmojiReply,
          onlyTxtReply:false,
          isShownReply: !this.state.isShownReply
         })
    }
  
   showToolbarReply = () => {
      this.setState({
        onlyEmojiReply:false,
        onlyTxtReply:!this.state.onlyTxtReply,
        isShownReply: !this.state.isShownReply
      })
    }

    toggleCommentHandlerReply = () => {
        this.setState({
          showEditorwrapperReply: !this.state.showEditorwrapperReply,
          
          },()=>{
              if(!this.state.showEditorwrapper){
                this.setState({
                    isShownReply: true
                })
              }
          });
        }


        checkValidReply = ()=>{
            
            let {commentReply} = this.state
            if(commentReply !== ''){
                let checkstr = draftToHtml(convertToRaw(commentReply.getCurrentContent()))
                let content =  checkstr.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "")
                //console.log(content.trim().length,"content.trim().lengthcontent.trim().length")
              
                 if (content.trim().length == 0 ) {
                  return true
                }
            }
            
          }

    addReplay = (feedItem) => {
        this.setState({
            isLoading:true
        })
        const { commentReply, replyLists, commentLists } = this.state
        
        let params = {
            "comment_id": feedItem.comment_id,
            "comment": draftToHtml(convertToRaw(commentReply.getCurrentContent())),
            

        }

        WSManager.Rest(NC.baseURL + NC.ADD_REPLY, params)
            .then((response) => {
                if (response.response_code == NC.successCode) {
                    
                    let tempArr = commentLists
                    let itemIndex = tempArr.findIndex(element => element.comment_id == feedItem.comment_id);
                    tempArr[itemIndex].no_of_replies = response.data.parent_no_of_reply;
                    let tempArrC = replyLists

                    tempArrC.unshift(response.data.list[0])

                    this.setState({
                        postComment: '',
                        replyLists: tempArrC,
                        commentLists: tempArr,
                        commentReply:'',
                        isLoading:false,
                        isShownReply: true,
                        showEmojisReply: false,
                        onlyTxtReply: false,
                        onlyEmojiReply: false,
                        showEditorwrapperReply: false
                    }, () => {

                    })

                    
                } else {
                    this.setState({
                        isLoading:false
                    })
                }
            })
            .catch((error) => {

            })
    }

    showReply = (feedItem) => {
        const { selectedData } = this.state
        
        
        
        if (selectedData === feedItem) {
            this.setState({
                selectedData: ''
            },()=>{
                
            })
        } else {
            this.setState({
                selectedData: feedItem
            },()=>{
                
            })

        }

    }

    followUnfollowUser = (feedItem) => {
        let params = {
          follower_id: feedItem.user_id
        }
        let api = NC.baseURL + NC.USER_FOLLOW
        if (feedItem.is_follower == 1)
        {
          api = NC.baseURL + NC.USER_UNFOLLOW
        }
        WSManager.Rest(api, params).then(responseJson => {
          if (responseJson.response_code === NC.successCode) {

            let tempArr = this.state.feedList
                    let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                    tempArr[itemIndex].is_follower = responseJson.data.is_follow;
                    this.setState({
                        feedList:tempArr,
                        postComment:''
                    })
          }
        });
      }



    fetchMoreData = () => {
        this.getComments(this.props.location.state.feedItem.activity_id);
    };
    fetchMoreDataReply = () => {
        this.getReplys(this.state.selectedData);
    };

    goToUserProfile = (user_id) => {
        this.props.history.push('/user-profile?id='+encode(user_id))

    }

    render() {

        const HeaderOption = {
            menu: false,
            Notification: false,
            Logo: true,
            back: true,
            showNavigation: false
        }

        const { selectedItem, postComment, commentLists, isLoading, replyLists, feedList, hasMoreComments, hasMoreReply, commentReply, selectedData, showHideTxt, no_of_comments, isShown, onlyTxt, onlyEmoji, showEditorwrapper, isShownReply,onlyTxtReply,onlyEmojiReply,showEditorwrapperReply  } = this.state;

        const feedItemFooterProps = {
            likePost: this.likePost,
            showComment: this.showComment,
            reSharePost: this.reSharePost,
            bookMarkPost: this.bookMarkPost,

        }
        const FeedCommentPostProps = {
            addComment:this.addComment,
            showToolbar:this.showToolbar,
            toggleCommentHandler:this.toggleCommentHandler,
            postContent: postComment,
            isShown: isShown,
            showEditorwrapper: showEditorwrapper,
            onlyTxt: onlyTxt,
            onlyEmoji: onlyEmoji,
            placeholder:'Write a comment...',
            onEditorStateChange: this.onEditorStateChange,
            checkValid: this.checkValid

        }

        const CommentListProps = {
            commentLike: this.commentLike,
            fetchMoreDataReply: this.fetchMoreDataReply,
            getReplys: this.getReplys,
            getReplysList: this.getReplysList,
            hideReplys: this.hideReplys,
            addReplay: this.addReplay,
            showReply: this.showReply,
            goToUserProfile: this.goToUserProfile,
            replyLists: replyLists,
            hasMoreReply:hasMoreReply,
            selectedData:selectedData,
            showHideTxt:showHideTxt,

            showToolbar:this.showToolbarReply,
            toggleCommentHandler:this.toggleCommentHandlerReply,
            postContent: commentReply,
            isShown: isShownReply,
            showEditorwrapper: showEditorwrapperReply,
            onlyTxt: onlyTxtReply,
            onlyEmoji: onlyEmojiReply,
            placeholder:'Write a reply...',
            onEditorStateChange: this.onEditorStateChangeReply,
            checkValidReply: this.checkValidReply


        }

        return (

            <MyContext.Consumer>

                {context => (
                    <React.Fragment>
                        {isLoading && <Loader />}
                        <div className="web-container feeds-web-container commemts-details-container">
                            <Helmet titleTemplate={`${MetaData.template} | %s`}>
                                <title>{MetaData.Feeds.title}</title>
                                <meta name="description" content={MetaData.Feeds.description} />
                                <meta name="keywords" content={MetaData.Feeds.keywords}></meta>
                            </Helmet>
                            <Header {...this.props} HeaderOption={HeaderOption} />


                        {<div className="post-detail-header">
                        Comments {no_of_comments > 0 ? '('+no_of_comments+')':''}
                        </div>}

                            <div className={'feeds-list-container'}>
                                {
                                    feedList.map((feedItem, feedIndex) => {
                                        return (
                                            <FeedList feedItem={feedItem} feedIndex={feedIndex} FeedCommentPostProps={FeedCommentPostProps} feedItemFooterProps={feedItemFooterProps} selectedItem={selectedItem} goToPostDetail={this.goToPostDetail} followUnfollowUser={this.followUnfollowUser} isShowRepost={false} goToUserProfile={this.goToUserProfile}/>
                                        )
                                    })
                                }
                                <InfiniteScroll
                                    dataLength={commentLists.length}
                                    next={this.fetchMoreData.bind(this)}
                                    hasMore={hasMoreComments}
                                    style={{overflow: 'unset'}}


                                >
                                    {
                                        _.map(commentLists, (feedItem, feedIndex) => {
                                            return (
                                                <CommentList {...CommentListProps} feedItem={feedItem} feedIndex={feedIndex+1} selectedItem={selectedItem} />
                                            )
                                        })

                                    }
                                </InfiniteScroll>


                            </div>


                        </div>
                    </React.Fragment>
                )}
            </MyContext.Consumer>

        )
    }
}

export default PostDetail;