import React from 'react';
import { Row, Col, PanelGroup, Panel} from 'react-bootstrap';
import {Images,SubHeader,Header} from '../components';
import { MyContext } from '../context';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class FQA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container lobby-web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.faqs.title}</title>
                            <meta name="description" content={MetaData.faqs.description} />
                            <meta name="keywords" content={MetaData.faqs.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} showFilter={this.showFilter} />
                        <div >
                            
                                <Row>
                                    <Col sm={12}> 
                                        <SubHeader Title="FAQ" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />   
                                        <div className="faq-container">
                                        <PanelGroup accordion id="accordion-uncontrolled-example" >

                                                <Panel eventKey="5">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            1. Why can’t I login to my account?
                                                            <i className="icon-plus"></i>
                                                            <i className="icon-remove"></i>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        Oops! Please check whether your registered email address and password are entered correctly and try again. If you've forgotten your password, click on 'Forgot Password', enter your registered email ID and we’ll send you a link to reset your password through an email within a few minutes. If you've forgotten your registered email id, reach out to us via Contact Us page.
							
                                                    </Panel.Body>
                                                </Panel>
                                                {/* <Panel eventKey="4">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            Round 4
                                                            <i className="icon-plus"></i>
                                                            <i className="icon-remove"></i>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <ul>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Panel.Body>
                                                </Panel>
                                                <Panel eventKey="3">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            Round 3
                                                            <i className="icon-plus"></i>
                                                        <i className="icon-remove"></i>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <ul>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Panel.Body>
                                                </Panel>
                                                <Panel eventKey="2">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            Round 2
                                                            <i className="icon-plus"></i>
                                                        <i className="icon-remove"></i>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <ul>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Panel.Body>
                                                </Panel>
                                                <Panel eventKey="1">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            Round 1
                                                            <i className="icon-plus"></i>
                                                        <i className="icon-remove"></i>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <ul>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="display-table">
                                                                    <div className="display-table-cell">GER <span>v</span> SWE</div>
                                                                    <div className="display-table-cell your-score">
                                                                        You Scored
                                                                        <p>8/15</p>
                                                                        <i className="icon-arrow-right"></i>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Panel.Body>
                                                </Panel> */}

                                            </PanelGroup>
                                        </div>
                                    </Col>
                                </Row>

                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
