import * as actionType from '../ActionTypes';

const initialState = {
    footer_clsnm: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FOOTER_CLASSNAME:
            return {
                ...state,
                footer_clsnm: action.payload
            }
    }
    return state;
}

export default reducer;
