import React from "react";
import { Row, Col, FormControl, FormGroup, Nav, NavItem, Tab, Media } from "react-bootstrap";
import {  UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images, ComingSoon, Loader } from "../components";
import MetaData from "../helper/MetaData";

import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";

import Select from 'react-select';
import _ from "lodash";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import PerformanceComponent from "./PerformanceComponent";

import 'react-bootstrap-typeahead/css/Typeahead.css';

import * as NC from '../helper/NetworkingConstants'
import UtilityFunctions from '../helper/UtilityFunctions';

import FeedList from './../components/FeedList';

import InfiniteScroll from "react-infinite-scroll-component";

import { decode, encode } from "base-64";
import Config from "../Config";

import { ProfileView, UnFollowModal } from "../Modals";

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

var mContext = null
var currentKey = 'RefSocial'

const queryString = require('query-string');

const profile = WSManager.getProfile();
export default class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    const html = '';
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const postComment = EditorState.createWithContent(contentState);
    this.state = {
        postComment,
        Tabkey: 1,
        userID: '',
        userProfile: '',
        categories_list: [
          { id: "1", category: "Refsocial", name: "Refsocial", value:"" },
          { id: "2", category: "RFL", name: "RFL", value: ""  },
          { id: "3", category: "Connect", name: "Connect", value:""  }
        ],
        selectedCategory: { id: "1", category: "Refsocial", name: "Refsocial", value:"" },

        selectedRefsocialTab: 'TIMELINE',

        timelineFilters: [
          {id: 1, title: 'Posts & Reposts'},
          {id: 2, title: 'Comments & Quotes'},
          {id: 3, title: 'Mentions'},
        ],
        selectedTimelineFilter: {id: 1, title: 'Posts & Reposts'},

        feedList: [],
        

        similarUserProfiles:[],

        isLoading: false,
        hasMore: false,
        offsetFeed:1,
        
        showUnfollowModal: false,
        unfollowAction: "",

        profileModalShow: false,
        ProfileViewData: "",

        isShown: true,
        showEmojis: false,
        onlyTxt: false,
        onlyEmoji: false,
        showEditorwrapper: false

        
    }
  }

  ShowUnfollowModal = (unfollowAction) => {
    this.setState({
      showUnfollowModal: true,
      unfollowAction: unfollowAction
    });
  };

  ShowProfileModal = (modalData) => {
    this.setState({
      profileModalShow: true,
      ProfileViewData: modalData
    });
  };

  componentWillMount() {
    let location = this.props.location;
    const parsed = queryString.parse(location.search);
    if (parsed.token && Object.hasOwnProperty.bind(parsed)('token')) {
      WSManager.logout();
      WSManager.setToken(decode(parsed.token));
    }
    if (parsed.id && Object.hasOwnProperty.bind(parsed)('id')) {
      this.setState({
        userID: decode(parsed.id)
      }, () => {
        this.callGetUserProfile();
        this.callGetSimilarProfiles();
        
      })
    }
    else {
      this.setState({
        userID: ''
      }, () => {
        this.callGetUserProfile();
        this.callGetSimilarProfiles();
          
        })
      
    }
  }

  getFeedList = () => {
    this.setState({ isLoading: true });
    const {offsetFeed} = this.state
    let params = {
        page : offsetFeed,
        // offset: 0,
        limit: 20,
        user_id: this.state.userProfile.user_id,
        sort_order: "DESC",
        sort_field: "created_date",
        search_keyword: ''
    }

    if (this.state.selectedRefsocialTab == 'LIKES')
    {
      params['user_like_activities'] = 1
    }


    WSManager.Rest(NC.baseURL + NC.ACTIVITY, params)
        .then((response) => {
          this.setState({
            hasMore:response.data.isloadmore,
            isLoading:false,
            offsetFeed: response.data.page
        })
        if (this.state.offsetFeed > 0) {
            this.setState({
              feedList: [
                ...this.state.feedList,
                ...response.data.list
              ]
            });
          } else {
            this.setState({
              feedList: response.data.list
            });
          }
            // this.setState({
            //     feedList: response.data.list,
            //     isLoading:false
            // })
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
            console.log('getFeedList Error=======>', error);
        })
}

  
  callGetUserProfile = () => {
    let params = {
      user_id: this.state.userID
      // user_id: '5523'
    }
    WSManager.Rest(NC.baseURL + NC.GET_MY_PROFILE, params).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        // WSManager.setProfile(responseJson.data.user_profile);
        this.setState({
          userProfile: responseJson.data.user_profile
        }, () => {
          this.state.feedList = []
          this.state.offsetFeed = 1
          this.getFeedList()
        });
      }
    });
  }
  callGetSimilarProfiles = () => {
    let params = {
      user_id: this.state.userID
      // user_id: '5523'
    }
    WSManager.Rest(NC.baseURL + NC.GET_SIMILAR_PROFILE, params).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        this.setState({
          similarUserProfiles: responseJson.data.users
        });
      }
    });
  }

  handleSelect = (Tabkey) => {
    this.setState({ Tabkey });
  }

  SkillBadgesTitle = percent => {
    if (percent < 40) {
      return "Amateur";
    }
    if (percent < 50) {
      return "Average";
    }
    if (percent < 60) {
      return "Advanced";
    }
    if (percent < 70) {
      return "Pro";
    }
    if (percent < 80) {
      return "Expert";
    }
    if (percent >= 80) {
      return "Genius";
    }
  };

  SkillBadgesStar = percent => {
    if (percent < 40) {
      return 0;
    }
    if (percent < 50) {
      return 20;
    }
    if (percent < 60) {
      return 40;
    }
    if (percent < 70) {
      return 60;
    }
    if (percent < 80) {
      return 80;
    }
    if (percent >= 80) {
      return 100;
    }
  };

  selectCategory = (obj) => {
    this.setState({
      selectedCategory:obj,
      // selectedRound: "",
      // selectedLeague: "",
      // selectedContest: "",
      // selectedMonth: "",
      // selectedFormat: "",
      // selectedFollow: "",
      // selectedContestType: "",
      // leaderboardResult:[],
      // ownLeaderboard:[],
      // offset: 0,
      // hasMore: false,
    }, () => {
      // this.callLeaderBoardApi()
    })
  }



  subscribeUnsubscribeUser = (userProfile) => {
    let params = {
      subscriber_id: userProfile.user_id
    }
    let api = NC.baseURL + NC.SUBSCRIBE_USER
    if (userProfile.is_subscribed == 1)
    {
      api = NC.baseURL + NC.UNSUBSCRIBE_USER
    }
    WSManager.Rest(api, params).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        this.state.userProfile.total_subscribers = userProfile.is_subscribed == 1 ?  Number(this.state.userProfile.total_subscribers) - 1 : Number(this.state.userProfile.total_subscribers) + 1
        this.state.userProfile.is_subscribed = userProfile.is_subscribed == 1 ? 0 : 1
        
        this.setState({
          userProfile: this.state.userProfile
        })
      }
    });
  }
  followUnfollowUser = (userProfile, is_connect = false, is_feed = false) => {
   
    let params = {
      follower_id: userProfile.user_id
    }
    let api = NC.baseURL + NC.USER_FOLLOW

    if (is_connect)
    {
      params = {
        follower_id: userProfile.item.user_id
      }
      if (userProfile.item.is_followed == 1)
      {
        api = NC.baseURL + NC.USER_UNFOLLOW
      }
    }
    else if (is_feed)
    {
      params = {
        follower_id: userProfile.item.user_id
      }
      if (userProfile.item.is_follower == 1)
      {
        api = NC.baseURL + NC.USER_UNFOLLOW
      }
    }
    else
    {
      
      if (userProfile.is_followed == 1)
      {
        api = NC.baseURL + NC.USER_UNFOLLOW
      }
    }

    
    WSManager.Rest(api, params).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {

        if (is_connect)
        {
          this.state.similarUserProfiles[userProfile.index].is_followed = this.state.similarUserProfiles[userProfile.index].is_followed == 1 ? 0 : 1

          this.setState({
            similarUserProfiles: this.state.similarUserProfiles
          })
        }
        else if (is_feed)
        {
          this.state.feedList[userProfile.index].is_follower = this.state.feedList[userProfile.index].is_follower == 1 ? 0 : 1

          this.setState({
            feedList: this.state.feedList
          })
        }
        else
        {
          this.state.userProfile.follower_count = userProfile.is_followed == 1 ?  Number(this.state.userProfile.follower_count) - 1 : Number(this.state.userProfile.follower_count) + 1
          this.state.userProfile.is_followed = userProfile.is_followed == 1 ? 0 : 1
          this.setState({
            userProfile: this.state.userProfile
          })
        }
      }
    });
  }

  goToUserProfile = (user_id) => {
    if (this.state.userProfile.user_id == user_id)
    {
      return 
    }

    this.state.userID = user_id
    // window.location.href = Config.RefsocialUrl + `user-profile?id=${encode(user_id)}`
    this.callGetUserProfile()
    this.callGetSimilarProfiles()
    this.props.history.push('/user-profile?id='+encode(user_id))
    
    
    // window.location.reload(false)
  }

  fetchMoreData = () => {
    console.log('currentKey====>', currentKey);
    if (currentKey == 'RefSocial')
    {
      this.getFeedList();
    }
  };

  render(){

    const { 
      userProfile, 
      categories_list, 
      selectedCategory, 
      selectedItem, 
      postComment, 
      hasMore, 
      isLoading, 
      showUnfollowModal, 
      unfollowAction ,
      isShown, 
      onlyTxt, 
      onlyEmoji, 
      showEditorwrapper,
      ProfileViewData,
      profileModalShow,
    } = this.state;

    const HeaderOption = {
        back: true,
        Notification: true,
        Logo: true,
        showNavigation: true,
        setting: false,
        isShowHeader: false
        
        
      };


        const feedItemFooterProps = {
            likePost: this.likePost,
            showComment: this.showComment,
            reSharePost: this.reSharePost,
            bookMarkPost: this.bookMarkPost,
            
        }
        const FeedCommentPostProps = {
          addComment:this.addComment,
          checkValid:this.checkValid,
          showToolbar:this.showToolbar,
          toggleCommentHandler:this.toggleCommentHandler,
          postContent: postComment,
          isShown: isShown,
          showEditorwrapper: showEditorwrapper,
          onlyTxt: onlyTxt,
          onlyEmoji: onlyEmoji,
          placeholder:'Write a comment...',
          onEditorStateChange: this.onEditorStateChange

        }

        const user_permissions = WSManager.getUserPermissions();

    return (
        <div className="web-container web-container-white feeds-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.myprofile.title}</title>
              <meta name="description" content={MetaData.myprofile.description} />
              <meta name="keywords" content={MetaData.myprofile.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showNavigation= {false}
              showFilter={this.showFilter}
            />
            {isLoading && <Loader />}
            {
              userProfile
              ?   
                  <div>
                    <Row>
                        <Col sm={12}>
                          <div>
                            <div className="user-profile-section">
                                <div Style = {'position: relative;'}>
                                  <div
                                      className="user-profile-page-inner-header"
                                      style={{
                                      backgroundImage: `url(${userProfile.cover_image})`,
                                      
                                      }}
                                      onClick={() => this.ShowProfileModal({ image: userProfile.cover_image || Images.HEADER_BG })}
                                      
                                  >
                                      {
                                          !userProfile.cover_image && 
                                          (<img
                                                  // src={Images.HEADER_BG_FULL}
                                                  src={Images.HEADER_BG}
                                                  alt=""
                                                  className="header-bg"
                                              />
                                          )
                                      }
                                      <div className = {'header-bar'}>
                                        <i onClick = {(e) => {
                                            e.stopPropagation();
                                            this.props.history.goBack()
                                          }}  className = {'icon-back-ic icons-i'}></i>
                                        {

                                          userProfile.user_id != WSManager.getProfile().user_id
                                          ?
                                            <div onClick = {e => e.stopPropagation()}>
                                            <UncontrolledDropdown direction={'left'} className={'act-dropdown'}>
                                              <DropdownToggle> <i onClick = {() => {}}  className = {'icon-more-post icons-i'}></i></DropdownToggle>
                                              <DropdownMenu>
                                                  {/* <div><i className={'icon-hide'} />Hide</div> */}
                                                  <DropdownItem><i className={'icon-block'} />Block</DropdownItem>
                                                  <DropdownItem><i className={'icon-report'} />Report</DropdownItem>
                                              </DropdownMenu>
                                          </UncontrolledDropdown>
                                          </div>
                                          :
                                            null
                                        }

                                      </div>
                                  </div>
                                  <div className = {'user-profile-header-image-icons'}>
                                    <div Style = {'display: flex; flex: 1; align-items: flex-end'}>
                                     { 
                                        userProfile.user_id != WSManager.getProfile().user_id
                                        ?
                                          <div className = {'profile-header-icons'} Style = {'padding: 0px 10px 0px 0px; justify-content: flex-start;'}>
                                            <div className = {'icon-box'}>
                                              {
                                                  <i className = {'icon-video'} Style = {'font-size: 18px;'}
                                                    onClick = {() => {}}>
                                                </i>  
                                              }
                                            </div>
                                            <div className = {'icon-box'}>
                                              {
                                                  <i className = {'icon-call'}
                                                    onClick = {() => {}}>                                                      
                                                  </i>
                                              }
                                            </div>
                                          </div>
                                        :
                                          null
                                      }
                                        
                                      
                                    </div>

                                    <div Style = {'display: flex; justify-content: center;'}>
                                      <img className = {'profile-user-image'} 
                                            onError={e => {
                                                e.target.onerror = null;
                                                e.target.src = Images.DEFAULT_USER;
                                              }}
                                              src={userProfile.image || Images.DEFAULT_USER}
                                              alt=""
                                              onClick={() => this.ShowProfileModal({ image: userProfile.image || Images.DEFAULT_USER })}
                                            >

                                        </img>
                                      </div>
                                      {
                                        userProfile.user_id != WSManager.getProfile().user_id
                                        ?
                                          <div className = {'profile-header-icons'} >
                                              {/* <div className = {'icon-box'}>
                                                <i className = {'icon-recorder'}></i>
                                              </div>
                                              <div className = {'icon-box'}>
                                                <i className = {'icon-Calls'}></i>
                                              </div> */}
                                              {
                                               
                                                <div className = {'icon-box'}>
                                                  <i className = {'icon-paper-plan'} 
                                                    onClick={() => {
                                                      this.props.history.push('/user-profile/chat', this.state.userProfile)
                                                    }}
                                                  ></i>
                                                </div>
                                                

                                              }
                                              <div className = {'icon-box'}>
                                                {
                                                  userProfile.is_subscribed == 0
                                                  ?
                                                    <i className = {'icon-bell1'}
                                                      onClick = {() => this.subscribeUnsubscribeUser(userProfile)}
                                                    ></i>  
                                                  :
                                                    <i className = {'icon-notification-bell selected-icon'}
                                                      onClick = {() => this.subscribeUnsubscribeUser(userProfile)}
                                                    ></i>  

                                                }
                                              </div>
                                              <div className = {'icon-box'}>
                                                {
                                                  userProfile.is_followed == 0
                                                  ?
                                                    <i className = {'icon-add-user'}
                                                      onClick = {() => {this.followUnfollowUser(userProfile)}}
                                                    ></i>
                                                  :
                                                    <i className = {'icon-user-select'}
                                                      // onClick = {() => this.followUnfollowUser(userProfile)}
                                                      onClick = {() => this.ShowUnfollowModal(() => this.followUnfollowUser(userProfile))}
                                                    ></i>

                                                }
                                              </div>
                                              
                                              
                                              
                                          </div>
                                        
                                        :
                                          <div className = {'edit-profile-buttons'} >
                                            <div onClick = {() => this.props.history.push('/edit-profile')} className = {'edit-profile'}>
                                              <span>EDIT PROFILE</span>
                                            </div>
                                          </div>
                                      }
                                  </div>
                                  <div className={'user-name-description'}>
                                      <div className={'user-name'}>
                                          <div className={'prof-det'}>
                                              {/* <h6>{userProfile.first_name + ' ' + userProfile.last_name}</h6> */}
                                              <h6>{userProfile.display_name}</h6>
                                              <div className={'usrnm-lb'}>
                                                {'@'+userProfile.user_name}
                                                {
                                                  // user_profile.plan_id == 3 
                                                  user_permissions.premium_badge == '1'
                                                  && (
                                                    <img
                                                      src={Images.P_PLUS}
                                                      alt=""
                                                      style={{ marginLeft: 5, marginTop: -5 }}
                                                    />
                                                  )
                                                }
                                                {
                                                  (userProfile.user_id != WSManager.getProfile().user_id && userProfile.is_following == 1)
                                                  ?
                                                    <span>
                                                      &nbsp;&nbsp;{'    follows you'}
                                                    </span>
                                                  :
                                                    null
                                                }
                                              </div>

                                          </div>
                                      </div>
                                    {
                                      userProfile.about
                                      ?
                                          <div className = {'user-description'}>
                                            <div className = {'content-text'}>
                                                <span>
                                                    {
                                                        userProfile.about
                                                    }
                                                </span>
                                            </div>
                                          </div>  
                                        :
                                          null
                                      }
                                  </div>  
                                  <div className = {'loc-web-date'}>
                                    {
                                      userProfile.country_name
                                      ?
                                        <div className = {'item-container'}>
                                          {/* <i Style = {'margin-right: 10px;'} className = {'icon-plus'}></i> */}
                                          <img  src = {Images.LOCATION_PIN}></img>
                                          <span>{userProfile.country_name}</span>
                                        </div>
                                      :
                                        null
                                    }
                                    {
                                        <div className = {'item-container flex2'}>
                                          <i className = {'icon-link'}></i>
                                          {
                                            userProfile.user_website
                                            ?
                                              <a href = {(userProfile.user_website).search(RegExp('^(http|https)://', 'i')) != -1 ? userProfile.user_website : 'http://' + userProfile.user_website } target = {'__blank'} rel="noopener noreferrer">
                                                <span className = {'website-span'}>
                                                  {
                                                    (userProfile.user_website).substr(0, 18)
                                                    + ((userProfile.user_website).length > 18 ? '...' : '')
                                                  }
                                                </span>
                                              </a>
                                            :
                                              <a href = {('')} >
                                                <span className = {'website-span'}>
                                                  {
                                                    ('refsocial.app/'+userProfile.user_name).substr(0, 18)
                                                    + (('refsocial.app/'+userProfile.user_name).length > 18 ? '...' : '')
                                                  }
                                                </span>
                                              </a>
                                          }
                                        </div>
                                      
                                    }
                                    {
                                      userProfile.member_since
                                      ?
                                        <div className = {'item-container'}>
                                          <i className = {'icon-birthday-cake'}></i>
                                          <span>{UtilityFunctions.getFormatedDateTime(userProfile.dob, 'DD MMM')}</span>
                                        </div>
                                      :
                                        null
                                    }
                                    
                                    
                                    
                                  </div>
                                  <div className = {'followers-row'}>
                                    <div>
                                      <span className = {'title'}>Followers</span>
                                      <span className = {'value'}>{UtilityFunctions.abbreviateNumber(userProfile.follower_count)}</span>
                                    </div>
                                    <div>
                                      <span className = {'title'}>Following</span>
                                      <span className = {'value'}>{UtilityFunctions.abbreviateNumber(userProfile.following_count)}</span>
                                    </div>
                                    <div>
                                      <span className = {'title'}>Connections</span>
                                      <span className = {'value'}>{UtilityFunctions.abbreviateNumber(userProfile.total_connection)}</span>
                                    </div>
                                    <div>
                                      <span className = {'title'}>Subscribers</span>
                                      <span className = {'value selected-value'}>{UtilityFunctions.abbreviateNumber(userProfile.total_subscribers)}</span>
                                    </div>
                                  </div>
                                  <div Style = {'display: flex; height: 1px; background-color: #EFEFEF;'}></div>
                                  {
                                    userProfile.followers.length > 0 && userProfile.user_id != WSManager.getProfile().user_id
                                    ?
                                      <div className = {'followed-by-container'}>
                                        <div className = {'followed-by-users-image'}>
                                          {
                                            userProfile.followers.map((item, index) => {
                                              if (index > 2)
                                              {
                                                return null
                                              }

                                              return (
                                                <img Style = {'z-index: '+(3-index)+';'}  src = {item.image ? item.image : Images.DEFAULT_USER}></img>
                                              );
                                            })
                                          }
                                          
                                        </div>
                                        <div>
                                          <span>
                                            {
                                              userProfile.follower_count >= 3
                                              ?
                                                'Followed by '+
                                                userProfile.followers[0].user_name +
                                                ' and ' + 
                                                UtilityFunctions.abbreviateNumber(userProfile.follower_count) +
                                                ' others you follow'
                                              :
                                                userProfile.follower_count >= 2
                                                ?
                                                  'Followed by '+
                                                  userProfile.followers[0].user_name +
                                                  ' and ' + 
                                                  UtilityFunctions.abbreviateNumber(userProfile.follower_count - 1) +
                                                  ' other you follow'
                                                :
                                                  userProfile.follower_count >= 1
                                                  ?
                                                    'Followed by '+ userProfile.followers[0].user_name 
                                                  :
                                                    null
                                            }
                                          </span>
                                        </div>
                                      </div>
                                  :
                                      null
                                  }
                                  {/* <div className={'lb_type_tab15'}>
                                    {categories_list.map((itm, i) => {
                                      return <div
                                        className={`ct-tab ${selectedCategory.id == itm.id ? 'active' : ''}`}
                                        onClick={() => this.selectCategory(itm)}
                                        key={i}>{itm.name}</div>
                                    })}
                                  </div> */}
                                </div>
                                <div>
                                  <Tab.Container
                                                  className="default-tab less-padding"
                                                  id="tabs-with-dropdown"
                                                  defaultActiveKey="RefSocial"
                                                  onSelect = {(event) => {
                                                    currentKey = event
                                                    this.state.feedList = [];
                                                    this.state.offsetFeed = 1
                                                    this.getFeedList()
                                                  }}
                                                >
                                                  <Row className="clearfix">
                                                    <Col sm = {12}>
                                                      <Row>
                                                        <Col sm={12}>
                                                          <Nav sticky="top" bsStyle="tabs new-shadow">
                                                            
                                                            <NavItem eventKey="RefSocial">REFSOCIAL</NavItem>
                                                            <NavItem eventKey="Performance">RFL</NavItem>
                                                            <NavItem eventKey="Connect">CONNECT</NavItem>
                                                          </Nav>
                                                        </Col>
                                                        </Row>
                                                        <Row>
                                                          <Col sm={12}>
                                                            <Tab.Content animation>
                                                              
                                                              <Tab.Pane eventKey="RefSocial">
                                                                  {/* <ComingSoon /> */}
                                                                  <div>
                                                                    <div className = {'refsocial-tabs-container'}>
                                                                      {
                                                                          <div onClick = {() => this.setState({selectedRefsocialTab: 'TIMELINE'}, () => {
                                                                            this.state.feedList = [];
                                                                            this.state.offsetFeed = 1
                                                                            this.getFeedList()
                                                                          })} className = {'refsocial-tabs'+ (this.state.selectedRefsocialTab == 'TIMELINE' ? ' selected-tab': '')}>
                                                                            <span>TIMELINE</span>
                                                                          </div>
                                                                      }
                                                                      {
                                                                        
                                                                          <div onClick = {() => this.setState({selectedRefsocialTab: 'LIKES'}, () => {
                                                                            
                                                                              this.state.feedList = [];
                                                                              this.state.offsetFeed = 1
                                                                              this.getFeedList()
                                                                          })} className = {'refsocial-tabs'+ (this.state.selectedRefsocialTab == 'LIKES' ? ' selected-tab': '')}>
                                                                            <span>LIKES</span>
                                                                          </div>
                                                                        
                                                                      }
                                                                      <div onClick = {() => this.setState({selectedRefsocialTab: 'POLLS'})} className = {'refsocial-tabs' + (this.state.selectedRefsocialTab == 'POLLS' ? ' selected-tab': '')}>
                                                                        <span>POLLS</span>
                                                                      </div>
                                                                      <div onClick = {() => this.setState({selectedRefsocialTab: 'MEDIA'})} className = {'refsocial-tabs' + (this.state.selectedRefsocialTab == 'MEDIA' ? ' selected-tab': '')}>
                                                                        <span>MEDIA</span>
                                                                      </div>
                                                                    </div>
                                                                    {
                                                                      true
                                                                      ?
                                                                        <div className = {'refocial-options-button-container'}>
                                                                          <div className = {'refsocial-buttons'}>
                                                                            {
                                                                              this.state.timelineFilters.map((item, index) => {
                                                                                return this.state.selectedTimelineFilter.id == item.id
                                                                                ?  
                                                                                  <span className = {'selected'} >
                                                                                    {
                                                                                      item.title
                                                                                    }
                                                                                  </span>
                                                                                :
                                                                                  <span onClick = {() => this.setState({ selectedTimelineFilter: item})} >
                                                                                    {
                                                                                      item.title
                                                                                    }
                                                                                  </span>
                                                                              })
                                                                            }
                                                                            
                                                                          </div>
                                                                        </div>
                                                                      :   
                                                                        null
                                                                    }
                                                                    <div className={'feeds-list-container'}>

                                                                    {
                                                                      (this.state.selectedRefsocialTab == 'TIMELINE' ||
                                                                      this.state.selectedRefsocialTab == 'LIKES')
                                                                      ?
                                                                        <InfiniteScroll
                                                                          dataLength={this.state.feedList.length}
                                                                          next={this.fetchMoreData}
                                                                          hasMore={hasMore}
                                                                          scrollableTarget="scrollableDiv"
                                                                        >
                                                                          {
                                                                              this.state.feedList && this.state.feedList.map((feedItem, feedIndex) => {
                                                                                return (
                                                                                    <FeedList 
                                                                                      {...this.props} 
                                                                                      feedItem={feedItem} 
                                                                                      feedIndex={feedIndex} 
                                                                                      FeedCommentPostProps={FeedCommentPostProps} 
                                                                                      feedItemFooterProps={feedItemFooterProps} 
                                                                                      selectedItem={selectedItem} 
                                                                                      goToPostDetail={this.goToPostDetail} 
                                                                                      isShowRepost={true} 
                                                                                      followUnfollowUser={() => {
                                                                                        if (feedItem.is_follower == 1)
                                                                                        {
                                                                                          this.ShowUnfollowModal((() => this.followUnfollowUser({item: feedItem, index: feedIndex}, false, true)))
                                                                                        }
                                                                                        else
                                                                                        {
                                                                                          this.followUnfollowUser({item: feedItem, index: feedIndex}, false, true)
                                                                                        }
                                                                                      }}
                                                                                      goToUserProfile = {this.goToUserProfile}
                                                                                    />
                                                                                )
                                                                            })
                                                                          }
                                                                        </InfiniteScroll>
                                                                      :
                                                                        <ComingSoon></ComingSoon>
                                                                    }
                                                                        

                                                                        {/* <Row>
                                                                            <Col xs={11}></Col>
                                                                            <Col xs={1}>
                                                                                <div onClick={() => this.props.history.push('/create-post')} className={'create-post-icon'}>
                                                                                    <i className={'icon-plus'}></i>
                                                                                </div>
                                                                            </Col>
                                                                        </Row> */}

                                                                    </div>
                                                                  </div>
                                                              </Tab.Pane>
                                                              <Tab.Pane eventKey="Performance">
                                                                <PerformanceComponent history={this.props.history} user_profile = {userProfile} />
                                                              </Tab.Pane>
                                                              <Tab.Pane eventKey="Connect">
                                                                {/* <ComingSoon /> */}
                                                                {
                                                                  this.state.similarUserProfiles.length > 0
                                                                  ?
                                                                    <div className="connect-post-section ">
                                                                      <div className="connect-post-card">
                                                                        <div className="similaruser-span">
                                                                          <span>Similar Profiles</span>
                                                                        </div>


                                                                        <div className="post-card-header">
                                                                          { 
                                                                            this.state.similarUserProfiles.map((item, index) => {
                                                                              return <div className="postuser-detail">
                                                                                <img src={ item.image ? item.image : Images.DEFAULT_USER} alt="" />
                                                                                <div onClick = {() => this.goToUserProfile(item.user_id)} Style = {'flex: 1; cursor: pointer;'}>
                                                                                  {/* <div className="user-name">{item.first_name + ' ' + item.last_name}</div> */}
                                                                                  <div className="user-name">{item.display_name}</div>
                                                                                  {/* <div className="post-time">1 min ago</div> */}
                                                                                </div>
                                                                                {
                                                                                  item.is_followed == 0
                                                                                  ?
                                                                                    <i className="icon-add-user" onClick = {() => this.followUnfollowUser({item: item, index: index}, true)} />
                                                                                  :
                                                                                    <i className="icon-paper-plan" 
                                                                                      onClick={() => {
                                                                                        this.props.history.push('/user-profile/chat', item)
                                                                                      }}
                                                                                    />
                                                                                }
                                                                              </div>
                                                                            })  
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  :
                                                                  null
                                                                }
                                                              
                                                              </Tab.Pane>
                                                            </Tab.Content>
                                                          </Col>
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                </Tab.Container>
                                              
                                </div>
                            </div>
                          </div>
                        </Col>
                    </Row>
                  </div>
                :
                    null
            }

            {profileModalShow && (
              <ProfileView
                history={this.props.history}
                profileModalShow={profileModalShow}
                profileModalHide={() => {
                  this.setState({ profileModalShow: false })
                }}
                ProfileViewData={ProfileViewData}
              />
            )}    

            {showUnfollowModal && (
              <UnFollowModal
                history={this.props.history}
                showUnfollowModal={showUnfollowModal}
                unfollowModalHide={() => {
                  this.setState({ showUnfollowModal: false })
                }}
                unfollowAction={unfollowAction}
              />
            )}


        </div>
    );

  }

  likePost = (feedItem, feedIndex) => {
    const { activity_id: entity_id, activity_type_id: entity_type } = feedItem
    let params = {
        "entity_id": entity_id,
        "entity_type": entity_type
    }

    WSManager.Rest(NC.baseURL + NC.TOGGLE_LIKE, params)
        .then((response) => {
            if (response.response_code == NC.successCode) {

              feedItem.is_like = feedItem.is_like == 0 ? 1 : 0
              feedItem.no_of_likes = feedItem.is_like == 0 ? Number(feedItem.no_of_likes) - 1 : Number(feedItem.no_of_likes) + 1
              if (this.state.selectedRefsocialTab == 'LIKES' && feedItem.is_like == 0)
              {
                  this.state.feedList.splice(feedIndex, 1)
              }
                this.setState({ feedList: this.state.feedList })
            }
        })
        .catch((error) => {

        })
  }



  reSharePost = (feedItem) => {
      let params = {
          "activity_id": feedItem.activity_id,
          "share_type":1
      }

      WSManager.Rest(NC.baseURL + NC.RESHARE_ACTIVITY, params)
          .then((response) => {
              if (response.response_code == NC.successCode) {
                  notify.show(response.message, "success", 2500);
              }
          })
          .catch((error) => {

          })
  }

  checkValid = ()=>{
            
    let {postComment} = this.state
    if(postComment !== ''){
        let checkstr = draftToHtml(convertToRaw(postComment.getCurrentContent()))
        let content =  checkstr.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "")
        //console.log(content.trim().length,"content.trim().lengthcontent.trim().length")
      
         if (content.trim().length == 0 ) {
          return true
        }
    }
    
  }

  showToolbar = () => {
    this.setState({
      onlyEmoji:false,
      onlyTxt:!this.state.onlyTxt,
      isShown: !this.state.isShown
    })
  }

  toggleCommentHandler = () => {
    this.setState({
      showEditorwrapper: !this.state.showEditorwrapper,
      
      },()=>{
          if(!this.state.showEditorwrapper){
            this.setState({
                isShown: true
            })
          }
      });
    }
   
    onEditorStateChange = (postContent) => {
      this.setState({
        postComment: postContent
      })
  }
  addComment = (feedItem) => {
      
      const {postComment} = this.state
      let params = {
          "activity_unique_id": feedItem.activity_unique_id,
          "comment":draftToHtml(convertToRaw(postComment.getCurrentContent())),
      }

      WSManager.Rest(NC.baseURL + NC.ADD_COMMENT, params)
          .then((response) => {
              if (response.response_code == NC.successCode) {
                let tempArr = this.state.feedList
                let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                tempArr[itemIndex].no_of_comments = response.data.activity_comment_count;
                tempArr[itemIndex].is_comment = response.data.is_comment;
                this.setState({
                    feedList:tempArr,
                    postComment:'',
                    isShown: true,
                    showEmojis: false,
                    onlyTxt: false,
                    onlyEmoji: false,
                    showEditorwrapper: false

                },()=>{
                    this.setState({
                        isLoading:false
                    })
                })

              }
          })
          .catch((error) => {

          })
  }


  showComment = (feedItem) => {
      const {selectedItem} = this.state
      if (selectedItem === feedItem) {
          this.setState({
              selectedItem: ''
          })
      } else {
          this.setState({
              selectedItem: feedItem
          })

      }

  }


  bookMarkPost = (feedItem) => {
      
      console.log(feedItem)
      let params = {
          "activity_id": feedItem.activity_id,
      }

      WSManager.Rest(NC.baseURL + NC.BOOKMARK_ACTIVITY, params)
          .then((response) => {
              
              if (response.response_code == NC.successCode) {
                let tempArr = [...this.state.feedList]
                let itemIndex = tempArr.findIndex(element => element.activity_id == feedItem.activity_id);
                
                console.log('itemIndex', itemIndex, tempArr[itemIndex].is_bookmark, response.data.is_bookmark);
                tempArr[itemIndex].is_bookmark = response.data.is_bookmark;
                this.setState({
                  feedList: tempArr
                })
                notify.show(response.message, "success", 2500);
              }
          })
          .catch((error) => {
            console.log('bookMarkPost Error:====', error);
          })
  }

  onChangePostComment = (event) => {
    this.setState({
      postComment: event.target.value
    })
  }

  goToPostDetail = (feedItem) =>{
      this.props.history.push({
          pathname:'/post-detail', state:{feedItem:feedItem}
      })
  }
}



