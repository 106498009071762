import React from "react";
import { MyContext } from "../context";

export default class SubHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      imgShown: '',
    };
  }
  render() {

    const {
      ImgShown,
      AddClassName,
      Title,
      BannerImage
    } = this.props;

    return (
      <MyContext.Consumer>
        {context => (
          <div className={"page-inner-header " + (AddClassName)}
            style={{
              backgroundImage: (BannerImage ? `url(${BannerImage})` : ''),
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          >
            <h2>{Title}</h2>
            <img
              src={ImgShown}
              alt=""
              className="header-bg"
            />
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

