import React from "react";
import { Row, Col, Button, Modal, FormGroup } from "react-bootstrap";
import FloatingLabel, { floatingStyles, focusStyles, inputStyles, labelStyles } from "floating-label-react";
import { Images, Header, Loader } from "../components";
import { MyContext } from "../context";
import Validation from "./../helper/validation";
import "react-phone-number-input/style.css";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown-now";
import { notify } from "react-notify-toast";


const md5 = require("md5");

const formInputStyle = {
  floating: {
    ...floatingStyles,
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "12px",
    borderBottomColor: "rgba(153, 153, 153, 0.5)",
    fontFamily: "MuliRegular"
  },
  focus: {
    ...focusStyles,
    borderColor: "rgba(153, 153, 153, 1)"
  },
  input: {
    ...inputStyles,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(153, 153, 153, 0.5)",
    width: "100%",
    fontSize: "16px",
    color: "#fff",
    fontFamily: "MuliBold",
    padding: "16px 0px 10px",
    backgroundColor: "transparent"
  },
  label: {
    ...labelStyles,
    paddingBottom: "0px",
    marginBottom: "0px",
    width: "100%",
    fontSize: "12px",
    color: "rgba(255, 255, 255, 0.8)",
    fontFamily: "MuliRegular"
  }
};

export default class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      username: "",
      password: "",
      submitClick: false,
      isShowPassword: false,
      isLoading: false,
      posting: false,
      refLogin: false,
      ref_id: '',
      otp: '',
      formValid: false,
      sentOtp: false,
      isCompleted: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  showPassword() {
    this.setState({
      isShowPassword: !this.state.isShowPassword
    });
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, this.IsFormValid);
  }

  IsFormValid() {
    const { username, password, ref_id, otp, refLogin } = this.state;
    var isValid = true;

    if (!refLogin) {
      if (Validation.validate("required", username) !== "success") {
        isValid = false;
      } else if (Validation.validate("required", password) !== "success") {
        isValid = false;
      }
    }

    if (refLogin) {
      if (ref_id === '') {
        isValid = false;
      } else if (otp === '' || otp.length !== 6) {
        isValid = false;
      }
    }

    this.setState({ formValid: isValid })
    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      submitClick: true,
    });
    if (this.IsFormValid()) {
      this.setState({
        isLoading: true,
        posting: true
      });

      let param = {
        device_type: NC.deviceType,
        ref_login:this.state.refLogin ? '1':'0'
      }

      if(this.state.refLogin){
        param['ref_id'] = this.state.ref_id;
        param['otp'] = this.state.otp;
      }
      else{
        param['user_name'] = this.state.username;
        param['password'] = md5(this.state.password);
      }
     

      WSManager.Rest(NC.baseURL + NC.LOGIN, param).then(responseJson => {
        this.setState({ isLoading: false, posting: false });
        if (responseJson.response_code === NC.successCode) {
          // this.props.IsLoginModalHide();
          WSManager.setToken(responseJson.data.session_key);
          WSManager.setProfile(responseJson.data.user_profile);
          WSManager.setProfileCompleted(
            responseJson.data.user_profile.is_profile_complete
          );

          if (responseJson.data.user_profile.is_profile_complete === 0) {
            this.props.IsLoginModalHide();
            Header.CompleteProfileShow();
          } 
          else {
            window.location.reload();
          }
        }
      });
    }
  }


  toggleLoginType = () => {
    this.setState({
      // username: '',
      // password: '',
      // ref_id: '',
      // otp: '',
      refLogin: !this.state.refLogin,
      submitClick: false
    }, this.IsFormValid)
  }

  onOtpChange = otp => {
    this.setState({ otp }, this.IsFormValid)
  }


  sendOtp = () => {
    if(this.state.ref_id === ''){
      notify.show('Please enter Ref Id', "error", 1000);
      return;
    };
    let param = {
      ref_id:this.state.ref_id
    }
    WSManager.Rest(NC.baseURL + NC.SEND_LOGIN_OTP, param).then(responseJson => {
      if (responseJson && responseJson.response_code === NC.successCode) {
        notify.show(responseJson.message, "success", 1000);
        this.setState({
          sentOtp: true
        });
      }
    })
   
  }

  ResendOtp = () => {

  }

  render() {
    const { IsLoginModalShow, IsLoginModalHide, isOpenFrom } = this.props;
    const {
      username,
      password,
      submitClick,
      isShowPassword,
      isLoading,
      posting,
      refLogin,
      ref_id,
      otp,
      sentOtp,
      formValid
    } = this.state;

    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            show={IsLoginModalShow}
            onHide={IsLoginModalHide}
            dialogClassName="custom-modal-with-bg"
          >
            {isLoading && <Loader />}
            <div className="modal-header-bg">
              <a href className="modal-close-btn" onClick={() =>
                (window.location.href == (NC.baseURL + 'login') ? window.location.assign('/dashboard/0') : IsLoginModalHide())
              }>
                <i className="icon-close" />
              </a>
              <img src={Images.LOGO} alt="" />
              <div className="modal-title">for the fans</div>
            </div>
            <div className="modal-overlay" />

            <form className="form-section" >
              <Modal.Body>


                <div className={`lg-sec ref-login-sec ${refLogin ? 'active' : ''}`}>
                  <div className="verification-block">
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          className="input-label-center ref_id_grp"
                          controlId="formBasicText"
                          validationState={
                            submitClick &&
                            Validation.validate("required", ref_id)
                          }
                        >
                          <FloatingLabel
                            styles={formInputStyle}
                            id="ref_id"
                            name="ref_id"
                            value={ref_id}
                            placeholder="Ref ID"
                            type="text"
                            onChange={this.onChange}
                          />
                          <span className={'r-con'}>R-</span>
                        </FormGroup>
                      </Col>
                      <Col xs={12} className="text-center">
                        <FormGroup>
                          {
                            sentOtp ?
                              <React.Fragment>
                                <a href className="link-txt mt-0" onClick={() => this.sendOtp()}>
                                  RESEND OTP
                                </a>
                                {/* {isCompleted ? (
                                <a href className="link-txt" onClick={() => this.ResendOtp()}>
                                  RESEND
                                </a>
                              ) : (
                                  <Countdown date={dataNow + 300000} renderer={renderer} />
                                )} */}
                              </React.Fragment> :
                              <React.Fragment>
                                <a href className="link-txt mt-0" onClick={() => this.sendOtp()}>
                                  SEND OTP
                                </a>
                              </React.Fragment>
                          }
                        </FormGroup>
                      </Col>

                    </Row>


                  </div>

                  {
                    sentOtp ?
                      <div className="verification-block">
                        <Row>
                          <Col xs={12} className="otp-field">
                            <label style={formInputStyle.label}>
                              OTP
                              </label>
                            <OtpInput
                              autoComplete="off"
                              shouldautofocus={true}
                              numInputs={6}
                              value={otp}
                              onChange={this.onOtpChange}
                              separator={<span />}
                            />
                          </Col>
                        </Row>
                      </div>
                      : ''}


                </div>
                <div className={`lg-sec simple-login-sec ${!refLogin ? 'active' : ''}`}>
                  <div className="verification-block">
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          className="input-label-center"
                          controlId="formBasicText"
                          validationState={
                            submitClick &&
                            Validation.validate("required", username)
                          }
                        >
                          <FloatingLabel
                            styles={formInputStyle}
                            id="MobileNumber/Username"
                            name="username"
                            value={username}
                            placeholder="Mobile Number/Username"
                            type="text"
                            onChange={this.onChange}
                          />
                        </FormGroup>
                      </Col>
                      {/* <button onClick={()=>this.setState({username:''})}>fdaf</button> */}
                    </Row>
                  </div>
                  <div className="verification-block">
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          className="input-label-center"
                          controlId="formBasicText"
                          validationState={
                            submitClick &&
                            Validation.validate("required", password) &&
                            Validation.validate("password", password)
                          }
                        >
                          <FloatingLabel
                            styles={formInputStyle}
                            id="password"
                            value={password}
                            name="password"
                            placeholder="Password"
                            type={isShowPassword ? "text" : "password"}
                            onChange={this.onChange}
                          />
                        </FormGroup>
                        <a
                          href
                          className="hidden-align"
                          onClick={() => this.showPassword()}
                        >
                          {this.state.isShowPassword ? (
                            <img src={Images.EYE_DARK_ICON} width="23px" alt='' className="opacity" />
                          ) : (
                              <img src={Images.EYE_CANCEL_DARK_GREEN_ICON} alt='' width="23px" />
                            )}
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>

              
                <div className="bottom-fixed">
                  <div className="text-center">
                    <a
                      href
                      className="forgot-pwd-link"
                      onClick={() => {
                        Header.ForgotPasswordShow("login");
                        IsLoginModalHide();
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>


                  <div className="text-center">
                    <Button
                      disabled={posting || !formValid}
                      className="btn btn-rounded btn-primary "
                      onClick={this.onSubmit}
                      type="submit"
                    >
                      Login
                    </Button>
                  </div>

                  <div className={'lg-wd-ref'}>
                    <div className={'hdng_1'}>
                      <span className={'ln l'}></span>
                      <span className={'tx'}>or lOGIN with {refLogin ? 'Email/Username' : 'REF ID'}</span>
                      <span className={'ln r'}></span>
                    </div>
                    <div className={'ref-bt-grp'}>
                      <Button
                        className="btn ref-id-btn "
                        onClick={this.toggleLoginType}
                      // type="submit"
                      >
                        <img src={Images.VISSEL} /> {refLogin ? 'Email/Username' : 'REF ID'}
                      </Button>
                    </div>

                  </div>

                  <div className="text-center user-help">
                    New to ReFPredictor League?{" "}
                    <a
                      href
                      onClick={() =>
                        isOpenFrom === "signup"
                          ? IsLoginModalHide()
                          : Header.SignupModalShow("login")
                      }
                    >
                      Sign Up
                    </a>
                  </div>
                </div>
              </Modal.Body>
            </form>
          </Modal>
        )
        }
      </MyContext.Consumer>
    );
  }
}
