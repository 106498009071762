import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../../context';
// import { Header, SubHeader, ComingSoon, Images } from '../components';
// import { Helmet } from "react-helmet";
// import MetaData from "../helper/MetaData";
// import Config from '../Config';
// import WSManager from "../helper/WSManager";


export default class GroupChatFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentWillMount() {
       
    }

    render() {
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container">
                     
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
