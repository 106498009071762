import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { Images } from "../components";

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount() {
        console.log(this.props)
    }

    getRefLeagueActive = () => {

        let refLeagueUrls = ["/dashboard/:tab_id", "/profile", "/leaderboard", '/create-contest', "/my-contest", "/private-contest"];
        let refPAyUrls = ["/refpay", "/add-funds", "/withdraw"];
        let refLiveUrls = ["/my-games/:tab_id"];

        let param = this.props.match.path;
        if (param && !refPAyUrls.includes(param) && !refLiveUrls.includes(param)) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <React.Fragment>

                <div className={'navigation-bar'}>
                    <Row className={'men-lnk-wrp'}>
                        {/* <Col xs={3}>
                            <Link className={this.getRefLeagueActive() ? 'active' : ''} to={'/dashboard/0'}>
                                <div className={'men-lnk'} >
                                    <img src={Images.REFPREDICTOR} />
                                    <span>ReFLeague</span>
                                </div>
                            </Link>

                        </Col>
                        <Col xs={3}>
                            <NavLink to={'/refSocial'}>
                                <div className={'men-lnk'} >
                                    <img src={Images.REFSOCIAL} />
                                    <span>ReFSocial</span>
                                </div>
                            </NavLink>
                        </Col>
                        <Col xs={3}>
                            <NavLink to={'/refpay'}>
                                <div className={'men-lnk'} >
                                    <img src={Images.REFPAY} />
                                    <span>ReFPay</span>
                                </div>
                            </NavLink>
                        </Col>
                        <Col xs={3}>
                            <NavLink to={'/my-games/0'}>
                                <div className={'men-lnk'} >
                                    <img src={Images.REFLIVE} />
                                    <span>ReFLive</span>
                                </div>
                            </NavLink>
                        </Col> */}

                        <div Style = {'display: flex; flex: 1;'}>
                            <div Style = {'display: flex; flex: 1; justify-content: center;'}>
                                <NavLink to={'/saved/0'}>
                                    <div className={'men-lnk'} >
                                        <i class="ic icon-Saved"></i>
                                        <span>Saved</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div Style = {'display: flex; flex: 1; justify-content: center;'}>
                                <NavLink to={'/streams/0'}>
                                    <div className={'men-lnk'} >
                                        <i class="ic icon-Streams"></i>
                                        <span>Streams</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div Style = {'display: flex; flex: 1; justify-content: center;'}>
                                <NavLink to={'/feeds'}>
                                    <div className={'men-lnk'} >
                                        <i class="ic icon-Timeline"></i>
                                        <span>Timeline</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div Style = {'display: flex; flex: 1; justify-content: center;'}>
                                <NavLink to={'/voices'}>
                                    <div className={'men-lnk'} >
                                        <i class="ic icon-Voices"></i>
                                        <span>Voices</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div Style = {'display: flex; flex: 1; justify-content: center;'}>
                                <NavLink to={'/calls'}>
                                    <div className={'men-lnk'} >
                                        <i class="ic icon-Calls"></i>
                                        <span>Calls</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div>

                        {/* <Col xs={5}>
                            <Row>

                            <Col xs={6}>
                                <NavLink to={'/saved/0'}>
                                    <div className={'men-lnk'} >
                                        <i class="ic icon-Saved"></i>
                                        <span>Saved</span>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col xs={6}>
                                <NavLink to={'/streams/0'}>
                                    <div className={'men-lnk'} >
                                        <i class="ic icon-Streams"></i>
                                        <span>Streams</span>
                                    </div>
                                </NavLink>
                            </Col>
                            </Row>
                        </Col>

                        <Col xs={2}>
                            <NavLink to={'/feeds'}>
                                <div className={'men-lnk'} >
                                    <i class="ic icon-Timeline"></i>
                                    <span>Timeline</span>
                                </div>
                            </NavLink>
                        </Col>

                        <Col xs={5}>
                            <Row>
                                <Col xs={6}>
                                    <NavLink to={'/voices'}>
                                        <div className={'men-lnk'} >
                                            <i class="ic icon-Voices"></i>
                                            <span>Voices</span>
                                        </div>
                                    </NavLink>
                                </Col>
                                <Col xs={6}>
                                    <NavLink to={'/calls'}>
                                        <div className={'men-lnk'} >
                                            <i class="ic icon-Calls"></i>
                                            <span>Calls</span>
                                        </div>
                                    </NavLink>
                                </Col>
                            </Row>
                        </Col> */}

                    </Row>
                </div>

            </React.Fragment >
        )
    }
}

export default NavigationBar;