import React from "react";
import { Row, Col, FormControl, FormGroup } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images ,TextEditor} from "../components";
import MetaData from "../helper/MetaData";
import Emojis from 'emoji-picker-react';
import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";

import Select from 'react-select';
import _ from "lodash";

export default class FeedCommentPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: WSManager.getProfile(),
        };
        
    }

   
  
  

 

    render() {
        
        const {  feedItem} = this.props
        
        let editorhandler = null;
      if (this.props.FeedCommentPostProps.showEditorwrapper) {
        editorhandler = (
        <div className="editor-div">
                <div className="create-post-footer-actions">
                      <div>
                        <i className={`icon-text ${!this.props.FeedCommentPostProps.isShown ? 'active' : ''}`} onClick={() => this.props.FeedCommentPostProps.showToolbar()} />
                        <i className="icon-aligment" />
                        <label htmlFor="upload-button">
                          <i className="icon-attach" />
                        </label>
                        {/* <input
                          type="file"
                          accept="image/x-png, image/jpeg,image/jpg"
                          ref={(ref) => this.uploadPostRef = ref}
                          multiple

                          onChange={this.onChangeOfMedia}
                          id="upload-button"
                          style={{ display: "none" }}
                        /> */}
                        
                        <i className={`icon-smile ${!this.props.FeedCommentPostProps.isShown ? 'active' : ''}`} onClick={() => this.props.FeedCommentPostProps.showToolbar()} />
                   
                      </div>
                     
                    </div>
        </div>
        );
      }
        return (
            <div className="feed-comment-box">
                <div className="user-comment">
                <div className="left-icon-div">
                    <i className="icon-plus" onClick={()=>this.props.FeedCommentPostProps.toggleCommentHandler()}></i>

                  </div>
                    {/* <img src={
                        profile.image != null && profile.image !== ""
                            ? profile.image
                            : Images.DEFAULT_USER
                    } alt="" /> */}
                     {/* <img src={Images.CHAT_SMILE} className='cursor-p' onClick={() => { this._hideEmojis() }} /> */}
                     {/* <i className="icon-plus" onClick={this.toggleCommentsHandler}></i> */}
              {/* {
                this.state.showEmojis && <div className='emojis' id='emojis'>
                  <img src={Images.CLOSE_ICON} className='cursor-p cross' onClick={() => {this._hideEmojis()}} />

                  <Emojis
                    disableSearchBar={true}
                    disableAutoFocus={true}
                    preload={false}
                    skinTone={'SKIN_TONE_LIGHT'}
                    onEmojiClick={this._onEmojisClicked}
                    id='emojis'
                  />
                </div>
              } */}
                </div>
                {/* <FormGroup controlId="formControlsTextarea"> */}
                
                    {/* <FormControl
                        componentClass="textarea"
                        rows={1}
                        placeholder="Write a comment..."
                        value={this.props.FeedCommentPostProps.postComment}
                        onChange={this.props.FeedCommentPostProps.onChangePostComment}
                        onFocus={() => {this.setState({showEmojis : false}) }} 
                    /> */}
                    <div className={`comments-area-section ${this.props.FeedCommentPostProps.showEditorwrapper ? 'fullheight':''}`}>
                    <TextEditor {...this.props.FeedCommentPostProps} />
                   <div className={(this.props.FeedCommentPostProps.showEditorwrapper ? ' editor-div-show ': ' editor-div-hide')}>
                     {editorhandler}
                   </div>
                    </div>
                    <div>
                    <i className={"icon-paper-plan " + (this.props.FeedCommentPostProps.checkValid() ? 'disable':'')} onClick={()=>this.props.FeedCommentPostProps.addComment(feedItem)}></i>

                    </div>
    
                {/* </FormGroup> */}
            </div>
        )
    }
}
