import React from 'react';
import { Loader } from "../components";
import * as NC from "../helper/NetworkingConstants";
import WSManager from "../helper/WSManager";

const queryString = require('query-string');
class Authenticate extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
        };
    }


    componentDidMount() {
        let location = this.props.location;
        const parsed = queryString.parse(location.search);
        if (parsed.token && Object.hasOwnProperty.bind(parsed)('token')) {
            WSManager.logout();
            WSManager.setToken(parsed.token);

            if(Object.hasOwnProperty.bind(parsed)('topage')){
                this.props.history.push({
                    pathname: `/${parsed.topage}`,
                    state: parsed
                }) 
            }else{
                window.location.assign("/feeds");
            }

        } else {
            console.log('Need to redirect on parent app', NC.ParentUrl);
            window.location.href = NC.ParentUrl
        }


    }
    render() {
        return (
            <React.Fragment>
                <Loader />
            </React.Fragment>
        )
    }
}

export default Authenticate;