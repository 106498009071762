import * as NC from "../helper/NetworkingConstants";

const Images = {
    S3_BUCKET_IMG_PATH: NC.S3_BUCKET_PATH + "assets/img/",
    COIN: NC.getImageBaseUrl('coins.png'),
    LOGO: NC.getImageBaseUrl('logo.png'),
    TEAM_HYDERABAD: NC.getImageBaseUrl('Hyderabad.png'),
    WINNING_ICON: NC.getImageBaseUrl('winning-ic.png'),
    USERIMG: NC.getImageBaseUrl('mark_zuckerberg.png'),
    DEFAULT_USER: NC.getImageBaseUrl('default-user.png'),
    MINUS_IMG: NC.getImageBaseUrl('remove-ic@1x.svg'),
    LOGO_PBL: NC.getImageBaseUrl('logo.png'),
    LOGO_VINFOTECH: NC.getImageBaseUrl('vinfotech-logo.png'),
    defaultPanImage: NC.getImageBaseUrl('default_Pan_Image.png'),
    clock_ic: NC.getImageBaseUrl('clock-ic.png'),
    no_data_bg_image: NC.getImageBaseUrl('No-data-page.svg'),
    CAPTAION_IC: NC.getImageBaseUrl('captain-ic.png'),
    VISSEL: NC.getImageBaseUrl('vissel.png'),
    SWEDEN: NC.getImageBaseUrl('sweden.png'),
    HEADER_BG: NC.getImageBaseUrl('header-bg-white.png'),
    THUMBSUP: NC.getImageBaseUrl('thumbs-up.png'),
    CONGRATS_BG: NC.getImageBaseUrl('congrats-bg.png'),
    BLOG_IMG: NC.getImageBaseUrl('blog-img.png'),
    BLOG_IMG2: NC.getImageBaseUrl('blog-img2.png'),
    BLOG_IMG3: NC.getImageBaseUrl('blog-img3.png'),
    TEAM_WIN: NC.getImageBaseUrl('win.png'),
    TEAM_TIE: NC.getImageBaseUrl('tie.png'),
    TEAM_LOSE: NC.getImageBaseUrl('lose.png'),
    P_PLUS: NC.getImageBaseUrl('PlusImg.png'),
    REFSOCIAL_HEADER_BG: NC.getImageBaseUrl('refsocail-header-bg.png'),
    REFPAY_HEADER_BG: NC.getImageBaseUrl('header-bg-white.png'),
    CARD_IMG: NC.getImageBaseUrl('card-img.png'),
    PAYMENT_METHOD: NC.getImageBaseUrl('payment-method.png'),
    FULL_LOGO: NC.getImageBaseUrl('full-logo.png'),
    USERIMG2: NC.getImageBaseUrl('user-img2.png'),
    USERIMG3: NC.getImageBaseUrl('user-img3.png'),
    MEMBERSHIP_BG: NC.getImageBaseUrl('membership-bg.png'),
    TEAM_IMG: NC.getImageBaseUrl('team-flag.png'),
    KEY: NC.getImageBaseUrl('Key.png'),
    STAR_IMG: NC.getImageBaseUrl('Star.png'),
    TEAM_LOGO: NC.getImageBaseUrl('team-logo.png'),
    PLAYER_IMG: NC.getImageBaseUrl('player-img.png'),
    SINGER_IMG: NC.getImageBaseUrl('singer-img.png'),
    SINGER_IMG2: NC.getImageBaseUrl('singer-img2.png'),
    PAUSE: NC.getImageBaseUrl('pause.png'),
    PLAY_BUTTON: NC.getImageBaseUrl('playbutton.png'),
    HEADER_BG_FULL: NC.getImageBaseUrl('header-bg-full.png'),
    C6RRECT: NC.getImageBaseUrl('c6rrect.png'),
    GOLDEN_STAR: NC.getImageBaseUrl('golden-star.png'),
    QUAD3RICK: NC.getImageBaseUrl('Quad3rick.png'),
    MEDALS: NC.getImageBaseUrl('medals.png'),
    CRSTALL_BALL: NC.getImageBaseUrl('crystall-ball-trophy.png'),
    RPL_CUP: NC.getImageBaseUrl('rpl-cup.png'),
    AWARD1: NC.getImageBaseUrl('awards1.png'),
    AWARD2: NC.getImageBaseUrl('awards2.png'),
    DEFAULT_FLAG_IMG: NC.getImageBaseUrl('default-team-img.svg'),
    DONE: NC.getImageBaseUrl('tick.png'),
    TROPHY_IC: NC.getImageBaseUrl('trophy-ic.png'),
    CIRCULAR_TICK: NC.getImageBaseUrl('circular-tick.png'),
    EYE_ICON: NC.getImageBaseUrl('eye.png'),
    EYE_CANCEL_ICON: NC.getImageBaseUrl('eye-cancel.png'),
    EYE_DARK_ICON: NC.getImageBaseUrl('eye-dark.png'),
    EYE_CANCEL_DARK_ICON: NC.getImageBaseUrl('eye-cancel-dark.png'),
    EYE_CANCEL_DARK_GREEN_ICON: NC.getImageBaseUrl('eye-cancel-dark-green.png'),
    COMING_SOON: NC.getImageBaseUrl('coming-soon-img.png'),
    SPONSERED_IMG: NC.getImageBaseUrl('sponsered-img.png'),
    WHATSAPP_IMG: NC.getImageBaseUrl('whatsapp.png'),
    TEXTMSG_IMG: NC.getImageBaseUrl('message.png'),
    WALLET: NC.getImageBaseUrl('wallet.svg'),
    USER: NC.getImageBaseUrl('user.svg'),
    CHECKED: NC.getImageBaseUrl('checked.svg'),
    CHECKED_W: NC.getImageBaseUrl('checked-w.svg'),
    LOBBY_BANNER: NC.getImageBaseUrl('lb-banner.png'),
    REFPAY: NC.getImageBaseUrl('refpay.svg'),
    REFLIVE: NC.getImageBaseUrl('reflive.svg'),
    REFPREDICTOR: NC.getImageBaseUrl('refpredictor.svg'),
    REFSOCIAL:NC.getImageBaseUrl('refsocial.svg'),
    COIN_ICON:NC.getImageBaseUrl('coins-refpay.svg'),
    LOGO_NEW:NC.getImageBaseUrl('logo-new.png'),
    CREATE_TAG_ICON: NC.getImageBaseUrl('CreateTagIcon.svg'),
    SEND_CHAT : NC.getImageBaseUrl('send.png'),
    SEND_PLUS : NC.getImageBaseUrl('chatadd.png'),
    CHAT_TEXT : NC.getImageBaseUrl('text.svg'),
    CHAT_SMILE : NC.getImageBaseUrl('smile.svg'),
    CHAT_PLUSROUND : NC.getImageBaseUrl('plusround.svg'),
    CHAT_MICE : NC.getImageBaseUrl('mice.svg'),
    CHAT_CAMERA : NC.getImageBaseUrl('camera.svg'),
    CHAT_ALIGN : NC.getImageBaseUrl('align.svg'),
    CHAT_MENU : NC.getImageBaseUrl('chatmenu.png'),
    BACK_ICON_CHAT : NC.getImageBaseUrl('Path.png'),
    CLOSE_ICON : NC.getImageBaseUrl('cross.png'),
    LOCATION_PIN : NC.getImageBaseUrl('location_pin.png'),
    MENUICON : NC.getImageBaseUrl('menuicon.svg'),
    GROUP_CHAT : NC.getImageBaseUrl('groups.svg'),
    REDADD : NC.getImageBaseUrl('redadd.png'),
    LOADER : NC.getImageBaseUrl('loader.gif'),
    JOINICON : NC.getImageBaseUrl('joinicon.svg'),
    RED_CROSS : NC.getImageBaseUrl('redcross.svg'),
    EMOJIS_BIG : NC.getImageBaseUrl('emojinew.svg'),
    UNCHECK_C : NC.getImageBaseUrl('uncheckcyan.svg'),
    CHECK_C : NC.getImageBaseUrl('checkcyan.svg'),
    DEMO_PROFILE :NC.getImageBaseUrl('demoprofile.jpeg'), 
    CROSS_ICON_DARK :NC.getImageBaseUrl('profile-cross.svg'), 
    BIG_NOTIFICATION_BELL :NC.getImageBaseUrl('big_notification_bell.svg'), 

    
    
    
  
}
export default Images;