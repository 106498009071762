import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { MyContext } from "./context";
import { Footer, Header, Loader } from "./components";
import {
   MemberShipPlan, TermsCondition, PrivacyPolicy, 
   FAQ, Help, Authenticate, Notifications,  Dashboard, 
   Feeds, CreatePost, UserProfile, PostDetail, ProfileEdit
} from "./views";
import OnetoOneChat from './views/OnetoOneChat';
import GroupChat from './components/Chat/GroupChatParent';
import CreateNewGroup from './components/Chat/CreateNewGroup';

import ScrollMemory from "react-router-scroll-memory";
import Notification from "react-notify-toast";
import * as Constants from "./helper/Constants";
import WSManager from "./helper/WSManager";
import * as NC from "./helper/NetworkingConstants";
import _ from "lodash";
import Config from './Config';


let lastScrollY = 0;
let ticking = false;

const queryString = require('query-string');

class Main extends Component {
  constructor(params) {
    super(params);
    this.state = {
      loading: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }


  isBottom(el) {
    return (el.getBoundingClientRect().top + 45) <= window.innerHeight;
  }

  nav = React.createRef();

  handleScroll = () => {
    lastScrollY = window.scrollY;

    if (!ticking) {
      if (Constants.WindowScrollY > 0 && lastScrollY > 0) {

      } else {
        window.requestAnimationFrame(() => {
          Constants.SetValues.yScroll(lastScrollY);
          ticking = false;
          if (lastScrollY > 0) {
            Header.updateHeaderShadow(true);
            // QuestionScreen.updatePredictionFixed(true);
          }
          else {
            Header.updateHeaderShadow(false);
            // QuestionScreen.updatePredictionFixed(false);
          }
        });

        ticking = true;
      }
    }

    const wrappedElement = document.getElementById('footerid');
    let element = document.querySelector(".page-footer");
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);
      element && element.classList.add('page-footer-absolute');
    }
    else {
      element && element.classList.remove('page-footer-absolute');
    }
  };

  static scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  componentWillMount() {
    let masterData = WSManager.getMasterData();
    this.callWSGetMasterData();
    if (masterData) {
      this.setMasterDataConst(masterData);
    }

    let location = this.props.location;
    const parsed = queryString.parse(location.search);
    if (parsed.token && Object.hasOwnProperty.bind(parsed)('token')) {
      WSManager.logout();
      WSManager.setToken(parsed.token);
    }
    if (WSManager.loggedIn()) {
      // // if(WSManager.getToken() )
      // Constants.SetUserBalance.callBalanceApi();
      // // Header.callGetMyProfileApi()
    }
  }
  callWSGetMasterData() {
    WSManager.Rest(NC.baseURL + NC.MASTER_DATA).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        WSManager.setMasterData(responseJson.data);
        this.setMasterDataConst(responseJson.data);
        this.setState({ loading: false })
      }
    });
  }
  setMasterDataConst(masterData) {
    if (!_.isUndefined(masterData) && masterData.languages.length > 0) {
      let tmpArray = [];
      _.map(masterData.languages, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.language(tmpArray);
    }

    if (masterData.networks.length > 0) {
      let tmpArray = [];
      _.map(masterData.networks, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.networks(tmpArray);
    }

    if (masterData.banks.length > 0) {
      let tmpArray = [];
      _.map(masterData.banks, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.banks(tmpArray);
    }

    if (masterData.leagues.length > 0) {
      // let tmpArray = [];
      // _.map(masterData.leagues, (item, idx) => {
      //   tmpArray.push({ label: item, value: item });
      // });

      Constants.SetValues.leagues(masterData.leagues);
    }

    if (masterData.scoring_rules.length > 0) {
      Constants.SetValues.scoring_rules(masterData.scoring_rules);
    }

    Constants.SetValues.membership_plans(masterData.membership_plans);
    Constants.SetValues.clubs(masterData.club);
    Constants.SetValues.current_round(masterData.current_round);
    if (masterData.categories.length > 0) {
      Constants.SetValues.set_categories(masterData.categories);
    }

    Constants.SetValues.set_sports(masterData.sports);


  }



  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          WSManager.loggedIn() === true ? (
            <Component {...props} />
          ) : 
          (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            )
        }
      />
    );

    if (this.state.loading) {
      return <div></div>;
      // return <Loader />
    }

    return (
      <MyContext.Consumer>
        {context => (
          <React.Fragment>
            <Router>
              <main className="Site">
                <div className="Site-content">
                  <ScrollMemory />
                  <Notification options={{ zIndex: 99999 }} />
                  <Switch>
                    {/* <Route path="/" component={Lobby} exact /> */}
                    <Route path="/" component={Dashboard} exact />
                    <Route path="/authenticate" component={Authenticate} exact />
                    <Route path="/help" component={Help} exact />
                    <Route path="/terms-condition" component={TermsCondition} exact />
                    <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                    <Route path="/faq" component={FAQ} exact />

                   
                    <PrivateRoute path="/membership-plan" component={MemberShipPlan} exact />
                    <PrivateRoute path="/notification" component={Notifications} exact />
                    <PrivateRoute path="/feeds" component={Feeds} exact />
                    <PrivateRoute path="/post-detail" component={PostDetail} exact />
                    <PrivateRoute path="/create-post" component={CreatePost} exact />
                    <PrivateRoute path="/user-profile" component={UserProfile} exact />
                    <PrivateRoute path="/user-profile/chat" component={OnetoOneChat} exact />
                    {/* <PrivateRoute path="/user-profile/:id" component={UserProfile} exact /> */}
                    <PrivateRoute path="/user-profile/group-chat" component={GroupChat} exact />
                    <PrivateRoute path="/user-profile/group-chat/new-group" component={CreateNewGroup} exact />
                    <PrivateRoute path="/user-profile/:id" component={UserProfile} exact />
                    <PrivateRoute path="/edit-profile/" component={ProfileEdit} exact />
                   
                    <Redirect from="*" to={"/"} />
                  </Switch>
                  <Footer />
                </div>
              </main>
            </Router>

          </React.Fragment>
        )}
      </MyContext.Consumer>
    );
  }
}
export default Main;
