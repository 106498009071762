import React from "react";
import { Row, Col, FormControl, FormGroup } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Header, Images, TextEditor } from "../components";
import MetaData from "../helper/MetaData";

import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";
import Emojis from 'emoji-picker-react';

import Select, { components } from "react-select";
import _ from "lodash";
import $ from "jquery";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import * as NC from '../helper/NetworkingConstants'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

var mContext = null
const { Option } = components;
var temp = false
//const profile = WSManager.getProfile();
export default class CreatePost extends React.Component {
  constructor(props) {
    super(props);

    const html = '';
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const postContent = EditorState.createWithContent(contentState);
    this.state = {
      postContent,
      mediaArray: [],
      loaderArry: [],
      tagsArray: [],
      tagsList: [],
      SelectedTagsOptionSend: [],
      selectedTags: [],
      imageLoading: false,
      Privacies: [
        { label: 'Everybody', value: 1, icon: 'icon-earth' },
        { label: 'Connections only', value: 2, icon: ' icon-connections-only' },
        { label: 'Group(s) only', value: 3, icon: 'icon-Group-only' },
        { label: 'Followers only', value: 4, icon: 'icon-followers-only' }
      ],
      selectedPrivacy: { label: 'Everybody', value: 1, icon: 'icon-earth' },
      showAddCreateTagField: false,
      profile: WSManager.getProfile(),
      SelectedTagsOption: [],
      isImageLoader: false,
      showTagView: false,
      isShown: true,
      showEmojis: false,
      onlyTxt: false,
      onlyEmoji: false,
      hiddenPost: false,
      words:0
    };
  }


  componentDidMount() {
    this.setState({
      profile: WSManager.getProfile()
    })

    this.getTagsList('')
  }

  _hideEmojis = (e) => {
    // console.log("object")
    this.setState({
      // showEmojis: !this.state.showEmojis
      onlyEmoji:!this.state.onlyEmoji,
      onlyTxt:false,
      isShown: !this.state.isShown
     })
    // var elementsArray = document.getElementsByClassName("rdw-emoji-wrapper")
    
    // elementsArray[0].setAttribute("aria-expanded", "true"); 
    
    // $(elementsArray[0]).trigger('click'); // Also Works

    
    //elementsArray.click()
   
}

_onEmojisClicked = (e, obj) => {
 
 
}

counter =()=> {
  
  let checkstr = draftToHtml(convertToRaw(this.state.postContent.getCurrentContent()))
  let content = checkstr.replace(/<(.|\n)*?>/g, '');
  let Litem = content.replace(/&nbsp;/g, '');
  var wordRegex = /[^a-z\d\s]+/gi;
  
  var wordCounter = (Litem.trim()//remove whitespace
                    .replace(/[\W]+/g, ' ')
                    .replace(/([a-z]+)\b[.,]/g, '')//remove commas & fullstops
                    .replace(wordRegex, '')
                    .split(' ')// split words into array elements
                    .filter(function(x){// remove empty array eements
                      return x !== '';
                    }) || []);
this.setState({
    words : wordCounter.length
  },()=>{});
}


// countText = () =>{
//   let checkstr = draftToHtml(convertToRaw(this.state.postContent.getCurrentContent()))
//   let content =  checkstr.split(" ")
//  // console.log(content,"contentcontentcontent")
//   //console.log(content.length,"contentcontentcontent")

//   //checkstr.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "")
  
//    temp = false
//   if(content.length > 60){
//     //console.log(content.length,"content.lengthcontent.length")

//     temp = true
//   } else {
//     temp = false
//   }
  

//   return content.length > 1 ? content.length : 0
// }

checkValid = ()=>{
  let checkstr = draftToHtml(convertToRaw(this.state.postContent.getCurrentContent()))
  let content =  checkstr.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "")
  //console.log(content.trim().length,"content.trim().lengthcontent.trim().length")

  if(this.state.mediaArray.length>0){
    return false
  }
  else if (content.trim().length == 0 || temp) {
    return true
  }
}

  onChangeOfPostContent = (event) => {
    this.setState({
      postContent: event.target.value
    })
  }

  onEditorStateChange = (postContent) => {
    this.setState({
      postContent,
    }, () => {

      this.counter()
    });
  };

  onChangeOfMedia = (event) => {
    
    event.preventDefault();
    var mfileArray = []

    let files = event.target.files

    if (files.length > 15 || this.state.mediaArray.length > 15)  {
      notify.show("Maximum files allowed is 15", "error", 2500);
      return false;
    }

    for (let index = 0; index < files.length; index++) {
      const mfile = files[index];
      let reader = new FileReader();
      
      if ( mfile.type=="image/jpg" || mfile.type=="image/jpeg" || mfile.type=="image/png" || mfile.type=="image/gif") {
        this.setState({ isImageLoader: true });
        reader.onloadend = () => {
      //    console.log('onChangeOfMedia', mfile);
          // this.uploadFeedImage(mfile);
          mfileArray.push(mfile)
          // console.log(files.length, mfileArray.length);
          if (files.length == mfileArray.length) {
            this.uploadFeedImage(mfileArray)
          }
        }
        reader.readAsDataURL(mfile)
      } else{
        notify.show("Invalid extestion. Only jpg, jpeg, png and gif has supported", "error", 2500);
      }

    }


  }


  getTagsList = (search_key) => {

    let params = {
      //is_activity_tag: 0,
      //offset: 0,
      //limit: 10,
      search_keyword: search_key
    }

    WSManager.Rest(NC.baseURL + NC.GET_TAG_LIST, params)
      .then((response) => {
        console.log('GET_TAG_LIST response', response);
        if (search_key == '') {
          this.setState({
            tagsArray: response.data.tags
          })
        }


        let tempList = [];

        response.data.tags.map((data, key) => {
          tempList.push({ name: data.name })
          return '';
        })
        this.setState({
          isLoading: false,
          TagsOption: tempList
        });
      })
      .catch((error) => {
        console.log('GET_TAG_LIST Error=======>', error);
      })
  }

  uploadFeedImage = (file) => {
    var data = new FormData();
    // data.append("tmp_media", file);
    let tempArr = []
    for (let index = 0; index < file.length; index++) {
      const element = file[index];
      tempArr.push(index)
      data.append("media[]", element);
    }
    mContext.setState({
      loaderArry: tempArr
    })
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log('Image Response', this.responseText);
        mContext.setState({ isImageLoader: false });
        var response = JSON.parse(this.responseText);

        if (response != '' && response.response_code === NC.successCode) {

          var tempId = [];
          tempId.push({ "media_unique_id": response.data.media_unique_id });
          var imagePath = response.data.image_path;

          // this.setState({ FeedimageUrl: imagePath, FeedimagePostId: tempId });
          mContext.state.mediaArray = [...mContext.state.mediaArray, ...response.data]
         
          mContext.setState({
            mediaArray: mContext.state.mediaArray
          },()=>{
            mContext.checkValid()
          })

        }
        else {
          if (response.global_error) {
            notify.show(response.message, "error", 5000);
          }
          notify.show(response.message, "error", 5000);
        }
      }
    });

    xhr.open("POST", NC.baseURL + NC.UPLOAD_MEDIA);
    xhr.setRequestHeader('session_key', WSManager.getToken())
    xhr.send(data);
  }


  addActivity = () => {
    let params = [{
      "post_content": draftToHtml(convertToRaw(this.state.postContent.getCurrentContent())),
      "media": this.state.mediaArray,
      "tags": this.state.SelectedTagsOptionSend,
      // "tags" : this.state.selectedTags,
      // "tags" : this.state.tagsArray,
      "privacy": this.state.selectedPrivacy.value
    }]


    WSManager.Rest(NC.baseURL + NC.ADD_ACTIVITY, params)
      .then((response) => {
        // console.log('addActivity Success', response);
        if (response.response_code == NC.successCode) {
          //notify.show(response.message, 'success', 5000)
          mContext.props.history.goBack()
        }
      })
      .catch((error) => {
        console.log('addActivity Error', error);
      })

  }


  selectUnselectTags = (item) => {
    if (this.state.selectedTags.length <= 0) {
      this.state.selectedTags.push(item)
      this.setState({ selectedTags: this.state.selectedTags })
    }
    else {
      let tagIndex = this.state.selectedTags.findIndex(element => element.tag_id == item.tag_id)
      if (tagIndex != -1) {
        let filteredTagsArray = this.state.selectedTags.filter(element => element.tag_id != item.tag_id)
        this.setState({
          selectedTags: filteredTagsArray
        })
      }
      else {
        this.state.selectedTags.push(item)
        this.setState({ selectedTags: this.state.selectedTags })
      }
    }
  }

  removeImageFromList = (item) => {

    let mediaArray = this.state.mediaArray.filter(ele => ele.media_id != item.media_id)
    this.setState({
      mediaArray: mediaArray
    },()=>{
      this.checkValid()
    })

  }

  handlePrivacyChange = (option) => {
    this.setState({
      selectedPrivacy: option
    });

  }

  showCustomTag = () => {
    this.setState({
      showTagView: !this.state.showTagView
    })
  }


  getActiveClass = (item) => {
    const { SelectedTagsOptionSend } = this.state

    let tempArr = SelectedTagsOptionSend
    let itemIndex = tempArr.findIndex(element => element.tag_id == item.tag_id);
    if (itemIndex == -1) {
      return false
    }
    else {
      return true
    }
  }

  showToolbar = () => {
    this.setState({
      onlyEmoji:false,
      onlyTxt:!this.state.onlyTxt,
      isShown: !this.state.isShown
    })
  }

  selectTag = (item) => {
    const { SelectedTagsOptionSend } = this.state

    let tempArr = SelectedTagsOptionSend
    let itemIndex = tempArr.findIndex(element => element.tag_id == item.tag_id);
    if (itemIndex == -1) {
      tempArr.push(item)
    }
    else {
      tempArr.splice(-1)
    }

    this.setState({
      SelectedTagsOptionSend: tempArr,
      SelectedTagsOption: tempArr
    }, () => {

    })
  }

  render() {
    const { profile, isImageLoader, showTagView, postContent, loaderArry, isShown, showEmojis, onlyTxt, onlyEmoji, hiddenPost, words } = this.state
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    mContext = this



    const TagAutoProps = {
      isLoading: this.state.isLoading,
      allowNew: true,
      selectHintOnEnter: true,
      flip: true,
      multiple: true,
      minLength: 3,
      labelKey: 'name',
      newSelectionPrefix: "Add a new tag: ",
      placeholder: 'Add Tag here…',
      options: this.state.TagsOption,
      selected: this.state.SelectedTagsOption,
      isInvalid: this.state.isTagInvalid,
      onSearch: this._handleTagSearch,
      onChange: this._handleAddTag,
      onKeyDown: this._onkeyhandleAddTag,
      onInputChange: this._handleAddTagInputChange,
    };

    const IconOption = props => (
      <div>
        <Option {...props}>
          <i className={`icon-  ${props.data.icon}`}></i>

          {props.data.label}
        </Option>
      </div>
    );
    const editorProps = {
      postContent: postContent,
      isShown: isShown,
      onlyTxt: onlyTxt,
      onlyEmoji: onlyEmoji,
      placeholder:"What's on your mind?",
      onEditorStateChange: this.onEditorStateChange
    }


    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container web-container-white">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.lobby.title}</title>
              <meta name="description" content={MetaData.lobby.description} />
              <meta name="keywords" content={MetaData.lobby.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showNavigation={false}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <div className="create-post-section">
                    <div className="create-post-header">
                      <div className="create-post-header-left">
                        <i onClick={() => this.props.history.goBack()} className={'icon-close'} ></i>
                        <div className="create-post-title">Create Post</div>
                      </div>
                      <div>
                        <a onClick={() => this.addActivity()} className={" btn btn-rounded " + (this.checkValid() || words > 60 || isImageLoader ? 'disbale' : '')}>
                          Post
                        </a>
                      </div>
                    </div>
                    <div className="user-info">
                      <img src={
                        profile.image != null && profile.image !== ""
                          ? profile.image
                          : Images.DEFAULT_USER
                      } alt="" />
                      <div className="user-name">
                        <span>{profile.display_name}</span>
                        <p className={'user-sub-name'}>{'@' + profile.user_name}</p>
                      </div>
                      <div className="post-count">{words}/60</div>
                    </div>
                    <div className={`create-post-body ${words > 60  ? 'disable':'tttt'}`}>
                      <TextEditor {...editorProps} />
                      {/* <FormGroup controlId="formControlsTextarea">
                        <FormControl
                          componentClass="textarea"
                          placeholder="What’s on your mind?"
                          value={this.state.postContent}
                          onChange={this.onChangeOfPostContent}
                        />
                      </FormGroup> */}
                    </div>

                    <div className="create-post-media">
                      {
                        this.state.mediaArray.length > 0
                          ?
                          <>




                            {this.state.mediaArray.map((item, index) => {
                              return (
                                <>
                                  <div className="create-post-media-item">
                                    <div className="remove-added-media" onClick={() => this.removeImageFromList(item)}>
                                      <i className='icon-close' ></i>
                                    </div>
                                    <img src={NC.s3_dev + item.image_path}></img>
                                  </div>

                                </>
                              )

                            })}


                          </>

                          :
                          null
                      }
                      {isImageLoader &&
                        _.map(loaderArry, (it, ix) => {
                          return (
                            <div className="img-loader" key={ix}>
                              <img src={Images.LOADER} alt="" />
                            </div>
                          )
                        })
                      }
                    </div>
                    {/* <div className="post-right-dropdown">
                          {
                            this.state.mediaArray.length > 0
                              ?
                              <>
                          <span>
                                <UncontrolledDropdown direction={'right'} className={'act-dropdown'}>
                                    <DropdownToggle> <i className={'icon-toggle-vertical'} /></DropdownToggle>
                                    <DropdownMenu>
                                        <div className="option-div"><i className={'icon-hide'} />Hide</div>
                                        <div className="option-div"><i className={'icon-block'} />Block</div>
                                        <div className="option-div"><i className={'icon-report'} />Report</div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </span>
                            </>
                          :
                          null
                          }
                      </div> */}
                    <div className="privacy-dropd">

                      <i className={` selectdicon ${this.state.selectedPrivacy.icon}`}></i>
                      <Select
                        onChange={this.handlePrivacyChange}
                        options={this.state.Privacies}
                        // menuIsOpen={true}
                        components={{ Option: IconOption }}
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                            marginTop: 5,
                            paddingLeft: 10
                          })
                        }}

                        classNamePrefix="secondary"
                        className="select-secondary minusML10"
                        placeholder="Everyone"
                        isSearchable={false}
                        isClearable={false}
                        value={this.state.selectedPrivacy}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: ""
                          }
                        })}
                      />
                    </div>

                    <div className="create-post-footer">
                      {/* <i className="icon-picture" /> */}
                      {/* <i className="icon-recorder" /> */}
                      {/* <i className="icon-record" /> */}
                      <div className="create-post-tag-wrapper">
                        <span className="tag-head">Trending</span>

                        <div className="create-post-tag-list" >

                          <div className="create-post-tag-list-row">
                            {
                              this.state.tagsArray.length > 0
                                ?
                                this.state.tagsArray.map((item, index) => {

                                  return (
                                    <div key={index} onClick={() => this.selectTag(item)} className={" create-post-tag-list-item " +
                                      (this.getActiveClass(item) ? 'active' : ' ')}>
                                      {'#' + item.name}
                                    </div>
                                  )
                                  // return (this.state.selectedTags.findIndex(ele => ele.tag_id == item.tag_id) != -1
                                  //   ?
                                  //     <div onClick = {() => this.selectUnselectTags(item)} Style = {'display: flex; text-align: center; background-color: #03F5E7; color: #000000; padding: 0px 10px; border-radius: 50px; margin-right: 5px; font-size: 12px; font-family: MuliSemiBold; cursor: pointer;'}>
                                  //           <span>{'#'+item.name}</span>
                                  //     </div>
                                  //   :
                                  //     <div onClick = {() => this.selectUnselectTags(item)} Style = {'display: flex; text-align: center; border-color: #03F5E7; border-style: solid; border-width: 1px; color: #03F5E7; padding: 0px 10px; border-radius: 50px; margin-right: 5px; font-size: 12px; font-family: MuliSemiBold; cursor: pointer;'}>
                                  //           <span>{'#'+item.name}</span>
                                  //     </div>
                                  // )

                                })
                                :
                                null
                            }
                          </div>
                          <div className="add-cust-tag" onClick={() => this.showCustomTag()}>
                            <img src={Images.CREATE_TAG_ICON} ></img>
                          </div>
                        </div>
                        {showTagView && <div className="create-post-tag-input">
                          <AsyncTypeahead
                            ref={(ref) => this._typeahead = ref}
                            className="tagauto"
                            {...TagAutoProps}
                          />
                        </div>}
                      </div>
                    </div>
                    {/* <div className="create-post-footer-actions"></div> */}
                    <div className="create-post-footer-actions">
                      <div>
                        <i className={`icon-text ${!isShown ? 'active' : ''}`} onClick={() => this.showToolbar()} />
                        <i className="icon-aligment" />
                        <label htmlFor="upload-button">
                          <i className="icon-attach" />
                        </label>
                        <input
                          type="file"
                          accept="image/x-png, image/jpeg,image/jpg"
                          ref={(ref) => this.uploadPostRef = ref}
                          multiple

                          onChange={this.onChangeOfMedia}
                          id="upload-button"
                          style={{ display: "none" }}
                        />
                        
                        <i className={`icon-smile ${!isShown ? 'active' : ''}`} onClick={() => this.showToolbar()} />
                        {
                          showEmojis && <div className='emojis' id='emojis'>
                            <img src={Images.CLOSE_ICON} className='cursor-p cross' onClick={() => { this._hideEmojis() }} />

                            <Emojis
                              disableSearchBar={true}
                              disableAutoFocus={true}
                              preload={false}
                              skinTone={'SKIN_TONE_LIGHT'}
                              onEmojiClick={this._onEmojisClicked}
                              id='emojis'
                            />
                          </div>
                        }
                      </div>
                      <div>
                        <i className="icon-camera1" />
                        <i className="icon-mick" />
                        <div className="add-multi-post">
                          <i className="icon-cross-circular" />
                        </div>

                      </div>
                    </div>
                    <div className="video-audio-streams">
                      <div className="streams-wrapper">
                        <i className="icon-Streams"></i>
                        <span>Create Video to Streams</span>
                      </div>
                      <div className="streams-wrapper">
                        <i className="icon-Voices"></i>
                        <span>Create Audio to Voices</span>
                      </div>

                    </div>


                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }

  _handleTagSearch = e => {
    console.log('11111111111111111', e)

    this.setState({ isLoading: true });
    this.getTagsList(e)
    // let param = JSON.stringify({
    //     "search_keyword": e,
    //     "activity_id": "",
    //     "offset": 0,
    //     "limit": 20,
    //     quick_tag: 0,
    // })
    // WSManager.Rest(NC.baseURL + NC.GET_TAGS, param)
    //     .then((responseJson) => {
    //         let tempList = [];

    //         responseJson.data.tags.map((data, key) => {
    //             tempList.push({ name: data.name })
    //             return '';
    //         })
    //         this.setState({
    //             isLoading: false,
    //             TagsOption: tempList
    //         });
    //     })

  };
  // _onkeyhandleAddTag(event) {

  //     console.log('event.keyCode', event.keyCode)
  //     if (event.keyCode == 13 && event && event.target&& event.target.value && event.target.value.trim().length>0) {
  //         let tempList = mContext.state.SelectedTagsOption;
  //         tempList.push({ name: event.target.value })
  //         mContext.setState({ SelectedTagsOption: tempList }, () => {
  //            mContext._typeahead.getInstance().clear()
  //            setTimeout(() => {
  //             mContext.setState({ SelectedTagsOption: tempList },()=>{
  //                 mContext._convertArrTag(tempList);
  //             })
  //            }, 100);
  //         });
  //     }
  // }

  _onkeyhandleAddTag(event) {

    console.log('event.keyCode', event.keyCode)
    if (event.keyCode == 13 && event && event.target && event.target.value && event.target.value.trim().length > 0) {
      let tempList = mContext.state.SelectedTagsOption;
      tempList.push({ name: event.target.value })
      mContext.setState({ SelectedTagsOption: tempList }, () => {
        //  mContext._typeahead.getInstance().clear()
        if (mContext._typeahead) {
          mContext._typeahead.clear()
        }
        setTimeout(() => {
          mContext.setState({ SelectedTagsOption: tempList }, () => {
            mContext._convertArrTag(tempList);
          })
        }, 100);
      });
    }
  }

  isAlreadyAdded() {
  }

  _handleAddTag = (SelectedTagsOption) => {
    // console.log('SelectedTagsOption', SelectedTagsOption)
    //   if(!isAlreadyAdded())
    //   {
    if (!this.state.isTagInvalid) {
      this.setState({ SelectedTagsOption }, () => {
        this._convertArrTag(SelectedTagsOption);
      })
    } else {
      this.setState({
        SelectedTagsOption,
        isTagInvalid: false
      }, () => {
        this._convertArrTag(SelectedTagsOption);
      })
    }
    // }else{
    //     notify.show('Maximum 5 tags can select.', 'error', 3000)
    // }

    setTimeout(() => {
      //   console.log('this.state.SelectedTagsOption', this.state.SelectedTagsOption);
    }, 2000);
  }
  _convertArrTag = (SelectedTagsOption) => {

    this.setState({
      SelectedTagsOptionSend: SelectedTagsOption
    }, () => {

    })
    // let tempList = this.state.SelectedTagsOptionSend

    // _.map(SelectedTagsOption, (item) => {
    //   let itemIndex = tempList.findIndex(element => element.name == item.name);
    //   let itemRemove = _.includes(tempList, item); 
    //   console.log(itemRemove,"itemRemoveitemRemoveitemRemoveitemRemove")
    //   if (itemIndex == -1) {
    //     tempList.push(item)
    //   }

    // })





    // this.setState({ SelectedTagsOptionSend: tempList },()=>{
    //   //console.log(this.state.SelectedTagsOptionSend,"11111111")
    // })



  }

  _handleAddTagInputChange = e => {
    if (e.trim() == '') {
      return
    }
    let objectContain = _.find(this.state.SelectedTagsOption, ['name', e.trim()])
    this.setState({ isTagInvalid: !_.isEmpty(objectContain) })
  }


}
