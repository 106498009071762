import React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { MyContext } from "../context";
import { notify } from "react-notify-toast";
import "react-phone-number-input/style.css";
import OtpInput from "react-otp-input";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";

export default class PhoneVerify extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      otp: "",
      postingOtp: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      postingOtp: true
    });

    let param = {
      phone_no: this.props.phoneNo,
      otp: this.state.otp
    };

    WSManager.Rest(NC.baseURL + NC.CHANGE_MOBILE_NO, param).then(
      responseJson => {
        this.setState({ isLoading: false, postingOtp: false });
        if (responseJson.response_code === NC.successCode) {
          this.props.IsPhoneVerifyModalHide();
          this.props.updatePhoneLocaly();
          notify.show(responseJson.message, "success", 5000);
        }
      }
    );
  }

  render() {
    const {
      IsPhoneVerifyModalShow,
      IsPhoneVerifyModalHide,
      isFromPin
    } = this.props;
    const { otp, postingOtp } = this.state;
    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            show={IsPhoneVerifyModalShow}
            onHide={IsPhoneVerifyModalHide}
            dialogClassName="custom-modal-with-bg"
          >
            <div className="modal-header-bg modal-header-without-bg">
              <a
                href
                className="modal-close-btn"
                onClick={() => IsPhoneVerifyModalHide()}
              >
                <i className="icon-close" />
              </a>

              <div className="otp-heading">Verify Your Mobile Number</div>
            </div>
            <div className="modal-overlay" />
            <Modal.Body>
              <React.Fragment>
                <form
                  className="form-section"
                  onSubmit={isFromPin ? this.props.onSubmit : this.onSubmit}
                  style={{ marginTop: -60 }}
                >
                  <div className="verification-block">
                    <Row>
                      <Col xs={12} className="otp-field">
                        <label>Enter OTP</label>
                        <OtpInput
                          autoComplete="off"
                          shouldAutoFocus={true}
                          numInputs={6}
                          value={otp}
                          onChange={this.handleOtpChange}
                          separator={<span />}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="text-center">
                    <Button
                      disabled={postingOtp || !otp}
                      className="btn btn-rounded btn-primary signup-btn"
                      type="submit"
                      onClick={isFromPin ? this.props.onSubmit : this.onSubmit}
                    >
                      {isFromPin ? "Reset Pin" : "Update Number"}
                    </Button>
                  </div>
                </form>
              </React.Fragment>
            </Modal.Body>
          </Modal>
        )}
      </MyContext.Consumer>
    );
  }
}
