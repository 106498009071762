import React from "react";
import { Row, Col, Button, Modal, FormGroup } from "react-bootstrap";
import { Images } from "../components";
import { inputStyle } from "../helper/input-style";
import { MyContext } from "../context";
import FloatingLabel from "floating-label-react";

export default class PredictionModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    const { IsPredictionModalShow, IsPredictionModalHide } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsPredictionModalShow}
              onHide={IsPredictionModalHide}
              bsSize="large"
              dialogClassName="prediction-modal"
              className="center-modal"
            >
              <Modal.Body>
                <div className="text-center m-t-60">
                  <img src={Images.LOGO} alt="" />
                </div>
                <div className="text-center">
                  <div className="user-coins-bal">
                    <div>Your Coins Balance</div>
                    <div className="bal-count">
                      <i className="icon-bal" />5,000
                    </div>
                  </div>
                </div>
                <div className="prediction-text m-b-md text-center">
                  Place your coins to make your prediction.
                </div>
                <div>
                  <div className="verification-block">
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          className="input-label-center"
                          controlId="formBasicText"
                        >
                          <FloatingLabel
                            styles={inputStyle}
                            id="otp"
                            name="otp"
                            placeholder="Enter Referral Code"
                            type="text"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                        <div className="min-coins">min 10 coins</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Modal.Body>
              <i
                className="icon-close icc"
                onClick={() => {
                  IsPredictionModalHide()
                }
                }
              />
              <Modal.Footer>
                <Button bsStyle="default">Make Your Prediction</Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
