import React from "react";
import { Row, Col, Button, Modal, ModalHeader } from "react-bootstrap";
import { MyContext } from "../context";
import { notify } from "react-notify-toast";
import "react-phone-number-input/style.css";
import OtpInput from "react-otp-input";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import { Images, Header } from '../components';


export default class JoinGroupModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }

    }

    componentWillMount = (e) => {
        console.log('props== ', this.props);
    }
    render() {

        return (
            <MyContext.Consumer>
                {context => (
                    <Modal
                        show={true}
                        className={
                            "verify-modal refpay-web-container center-modal"}
                    >


                        <Modal.Body>

                            {/* <img src={Images.RED_CROSS} className='cross-icon' onClick={()=>{this.props.modalHide()}}/> */}

                            <div>
                                <div className='center-data'>
                                    <div className='join-icon'>
                                        <img src={Images.JOINICON} />
                                    </div>
                                </div>

                                <div>
                                    <div className='join-title padding-1535'>
                                        Join Testing Group
                                    </div>
                                    <div className='center-data'>
                                        <div>
                                            <img src={Images.GROUP_CHAT} className='group-icon-s' />
                                        </div>
                                        <div className='title-2 m-l-10'>
                                            100 Members
                                        </div>

                                    </div>
                                    <div className='title-3 padding-2035'>
                                        <span>Pay <span className='amt'>100</span> to join Testing Group</span>
                                    </div>
                                </div>
                                <div className='center-data m-20-t-b'>
                                    <div className='continue-btn continue-btn'>
                                        CONTINUE
                                    </div>
                                </div>


                            </div>
                            <div className='center-data'>
                                <div className='cross-icon' onClick={()=>{this.props.modalHide()}}>
                                    <img src={Images.RED_CROSS} className='cross-icon-image'/>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                )}
            </MyContext.Consumer>
        );
    }
}
