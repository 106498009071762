import React from "react";

export default class RKFloatingLabelInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      className: "",
      value: this.props.value || ""
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.onInput = this.onInput.bind(this);
  }

  componentWillMount() {
    if (this.state.value != "") {
      this.setState({ className: "on" });
    }
  }

  handleBlur(evt) {
    if (evt.target.value !== "") {
      this.setState({ className: "on" });
    } else {
      this.setState({ className: "" });
    }
  }

  onInput(evt) {
    if (evt.target.validity.valid) {
      const value = evt.target.validity.valid
        ? evt.target.value
        : this.state.value;
      this.setState({ value });
      this.props.onChange(evt);
    }
  }

  render() {
    const {
      className,
      placeholder,
      type,
      autocomplete,
      name,
      id,
      maxLength,
      onChange,
      isNumberOnly
    } = this.props;

    return (
      <div className={className}>
        <span className={this.state.className}>{placeholder}</span>
        {isNumberOnly ? (
          <input
            autoComplete={autocomplete}
            value={this.state.value}
            pattern="[0-9]*"
            name={name}
            id={id}
            type={type}
            onChange={evt => onChange(evt)}
            onInput={this.onInput.bind(this)}
            onFocus={() => this.setState({ className: "on" })}
            onBlur={this.handleBlur}
            maxLength={maxLength}
          />
        ) : (
            <input
              autoComplete={autocomplete}
              value={this.state.value}
              name={name}
              id={id}
              type={type}
              onChange={evt => onChange(evt)}
              onFocus={() => this.setState({ className: "on" })}
              onBlur={this.handleBlur}
              maxLength={maxLength}
            />
          )}
      </div>
    );
  }
}
