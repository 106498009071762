import React from 'react';
import { MyContext } from "../context";
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class TextEditor extends React.Component {
    


    



    render() {
        const { postContent, isShown,placeholder } = this.props;

        return (

            <MyContext.Consumer>

                {context => (
                    <React.Fragment>
                        <Editor
                            toolbarHidden={isShown}
                            editorState={postContent}
                            onEditorStateChange={this.props.onEditorStateChange}
                            placeholder={placeholder}
                            toolbar={{
                                options:['inline','list','emoji','link'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                                list: {
                                    options: ['unordered', 'ordered'],
                                    
                                },
                                link: {
                                    options: ['link'],
                                }
                                    
                            }}
                        />
                    </React.Fragment>
                )}
            </MyContext.Consumer>

        )
    }
}

export default TextEditor;