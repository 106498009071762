import React from "react";
import { Row, Col, FormControl, FormGroup } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images } from "../components";
import MetaData from "../helper/MetaData";
import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";
import Select from 'react-select';
import _ from "lodash";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as NC from '../helper/NetworkingConstants'
import firebase from 'firebase';
import Moment from 'react-moment';
import Emojis from 'emoji-picker-react';
var mContext = null
const profile = WSManager.getProfile();

export default class OnetoOneChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNoMsg: false,
      fatchingMsg: true,
      showEmojis: false,
      messgae: []
    }
  }

  componentWillMount = () => {
    var chatRoomId=''
    try {
      if(WSManager.getProfile().user_id < this.props.location.state.user_id)
      {
        chatRoomId = WSManager.getProfile().user_id + this.props.location.state.user_id
      }else
      {
        chatRoomId = this.props.location.state.user_id + WSManager.getProfile().user_id
      }
    this.messageRef = firebase
      .database()
      .ref()
      .child(chatRoomId)
      .child("messages");
    this._listenMessages();
    } catch (e) {
      console.log('err = Chat')
    }
  }



  /**
   * LISTEN MESSAGES 
   */
  _listenMessages = async () => {
    this.messageRef.limitToLast(500).on("value", message => {
      if (message.val() != null) {
        let msgList = Object.values(message.val());
        this.setState({
          chatCount: msgList.length,
          messgae: msgList
        });
        setTimeout(() => {
          if (msgList.length == 0) {
            this.setState({
              showNoMsg: true
            })
          }
          try {
            let breakDownHeight = document.getElementById('messagebox')
            window.scroll({
              top: breakDownHeight.offsetHeight + 650 + 72,
              behavior: 'smooth'
            });
          } catch (e) {

          }

        }, 10);
      } else {
        this.setState({
          showNoMsg: true
        })
      }
    });
  }



  /**
   * SEND MESSGAE TO OTHEER SIDE
   */
  _handleSend = (msg) => {
   
    if (this.state.sendText && this.state.sendText.trim().length > 0) {
      var newItem = {
        name: WSManager.getProfile().first_name,
        userId: WSManager.getProfile().user_id,
        type: '1',
        userImage: WSManager.getProfile().image,
        time: new Date().toString(),
        message: this.state.sendText
      };
      this.messageRef.push(newItem);
      this.setState({ sendText: "" });
    }
  }


  /**
   * BODDY CLICK 
   * 
   */
  _isMyBody = (e) => {

  }

  _hideEmojis = (e) => {
    this.setState({
      showEmojis: !this.state.showEmojis
    })
  }


  /**
   * GET EMOJIS CLICK FUNCTION 
   */
  _onEmojisClicked = (e, obj) => {

    let isInputText = this.state.sendText;
    if (isInputText) {
      this.setState({
        sendText: isInputText + obj.emoji
      })
    } else {
      this.setState({
        sendText: obj.emoji
      })
    }

  }

  render() {

    const { messgae } = this.state;
    const HeaderOption = {
      menu: false,
      Notification: false,
      Logo: true,
      isShowHeader: false

    };

    return (
      <div className="web-container web-container-white no-padding-t" onClick={() => { this._isMyBody() }} id='main-holder'>
        <Helmet titleTemplate={`${MetaData.template} | %s`}>
          <title>{MetaData.Chat.title}</title>
          <meta name="description" content={MetaData.Chat.description} />
          <meta name="keywords" content={MetaData.Chat.keywords} />
        </Helmet>
        <Header
          {...this.props}

          showNavigation={false}
          HeaderOption={HeaderOption}
          showFilter={this.showFilter}
          
        />
        <div className='chat-header d-f'>
          <div className='div-left d-f'>
            <div className='div-back center-data cursor-p' onClick={() => { this.props.history.goBack() }}>
              <img src={Images.BACK_ICON_CHAT} />
            </div>
            <div className='div-userpic-name'>
              <div className='user-pic-header'>
                <img src={this.props.location.state.image != '' ? this.props.location.state.image : Images.DEFAULT_USER} className='chat-profle-pic' />
              </div>
              <div className='m-l-10'>
                <div className='user-name-'>
                  {this.props.location.state.display_name}
                </div>
                <div className='user-sub'>
                  @ {this.props.location.state.user_name}
                </div>
              </div>
            </div>
          </div>
          {/* <div className='div-right d-f cursor-na'>
            <div className='more-add center-data'>
              +
            </div>
            <div>
              <img src={Images.CHAT_MENU} className='menu' />

            </div>

          </div> */}

        </div>

        <div>
          <div className='message-box' id='messagebox'>
            {
              this.state.messgae.length == 0 && !this.state.showNoMsg && <div className='fetching'>
                Fetching Message Please wait...
              </div>
            }
            {
              this.state.messgae.length == 0 && this.state.showNoMsg && <div className='fetching'>
                No Message Found
              </div>
            }
            {
              this.state.messgae && this.state.messgae.map((msg, i) => {

                return (
                  <div key={i}>
                    {
                      msg.userId == WSManager.getProfile().user_id ?
                        <div className={'d-f p15'}>
                          <div className='message-container-r'>
                            <div className='d-f float-r'>
                              <div className='user-name float-r'>{msg.name}</div>
                              <div className='time-right-r'>
                                <Moment fromNow>{msg.time}</Moment>
                              </div>
                            </div>
                            <div>
                              <div className='user-message-r float-r'>{msg.message}</div>
                            </div>
                          </div>
                          <div className='profile-div'>
                            <img src={msg.userImage != '' && msg.userImage != undefined ? msg.userImage : Images.DEFAULT_USER} className='chat-profle-pic' />
                          </div>
                        </div> : <div className={'d-f p15'}>
                          <div className='profile-div'>
                            <img src={msg.userImage != '' && msg.userImage != undefined ? msg.userImage : Images.DEFAULT_USER} className='chat-profle-pic' />
                          </div>
                          <div className='message-container'>
                            <div className='d-f'>
                              <div className='user-name'>{msg.name}</div>
                              <div className='time-right'>
                                <Moment fromNow>{msg.time}</Moment>
                              </div>
                            </div>
                            <div>
                              <div className='user-message'>{msg.message}</div>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
                )

              })
            }


          </div>
        </div>

        <div className='sender-box d-grid'>
          <div className='d-f'>
            <div className='left-btn'>
              {/* <img src={Images.SEND_PLUS} className='h30 cursor-na' />
               */}
              <img src={Images.CHAT_SMILE} className='cursor-p' onClick={() => { this._hideEmojis() }} />
              {
                this.state.showEmojis && <div className='emojis' id='emojis'>
                  <img src={Images.CLOSE_ICON} className='cursor-p cross' onClick={() => {this._hideEmojis()}} />

                  <Emojis
                    disableSearchBar={true}
                    disableAutoFocus={true}
                    preload={false}
                    skinTone={'SKIN_TONE_LIGHT'}
                    onEmojiClick={this._onEmojisClicked}
                    id='emojis'
                  />
                </div>
              }


            </div>
            <div className='sender-div'>
              <input type='text' className='sender-box-input' onChange={(e) => { this.setState({ sendText: e.target.value }) }} value={this.state.sendText} onFocus={() => {this.setState({showEmojis : false}) }} />

            </div>
            {
              // ((this.state.messgae.length < 1 && this.state.showNoMsg) && (this.props.location.state.is_following == 1 && this.props.location.state.is_followed == 1))
              // ?
                <div className='right-btn'>
                  <img src={Images.SEND_CHAT} onClick={(e) => { this._handleSend(e.target.value) }} className='h30 cursor-p' />
                </div>
              // :
              //   null
            }
          </div>
          {/* <div className='d-f cursor-na'>
            <div className='d-f'>
              <div className='lower-tem'>
                <img src={Images.CHAT_TEXT} />
              </div>
              <div className='lower-tem'>

                <img src={Images.CHAT_ALIGN} />
              </div>
              <div className='lower-tem'>

                <img src={Images.CHAT_SMILE} />
              </div>
            </div>
            <div className='lower-right'>
              <div className='lower-tem'>
                <img src={Images.CHAT_CAMERA} />
              </div>
              <div className='lower-tem'>
                <img src={Images.CHAT_MICE} />
              </div>
            </div>

          </div> */}
        </div>
      </div>
    );

  }
}