import React from "react";
import { Modal } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";
import * as Constants from "../helper/Constants";

export default class ScoringRules extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ScoringRules: Constants.scoring_rules
    };
  }

  render() {
    const { IsScoringModalShow, IsScoringModalHide } = this.props;
    const { ScoringRules } = this.state;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsScoringModalShow}
              onHide={IsScoringModalHide}
              bsSize="large"
              dialogClassName="contest-detail-modal modal-scoring-body"
            >
              <Modal.Header>
                <Modal.Title>
                  <img src={Images.LOGO} alt="" className="vissel-img" />
                  <a href onClick={IsScoringModalHide} className="modal-close">
                    <i className="icon-close" />
                  </a>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: "100vh", overflow: "auto" }}>
                <div className="rules-scoring-body">
                  <div className="type-heading">Scoring Rules</div>
                  <ul className="scoring-chart">
                    {ScoringRules.map((item, idx) => {
                      return (
                        <li key={idx}>
                          <div className="display-table">
                            <div className="text-block">
                              {item.question_name}
                            </div>
                            <div className="value-block">{item.score}</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
