import React from "react";
import { MyContext } from "../context";
import { Row, Col, Button, Modal, FormGroup, Checkbox } from "react-bootstrap";
import FloatingLabel, { floatingStyles, focusStyles, inputStyles, labelStyles } from "floating-label-react";
import { notify } from "react-notify-toast";
import { Images, Header, Loader, RKFloatingLabelInput, ValidateInput } from "../components";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import "react-phone-number-input/style.css";
import OtpInput from "react-otp-input";
import DatePicker from "react-date-picker";
import Countdown from "react-countdown-now";
import Validation from "./../helper/validation";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";

const md5 = require("md5");
const today = new Date(moment().subtract(18, "years"));
const formInputStyle = {
  floating: {
    ...floatingStyles,
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "12px",
    borderBottomColor: "#c5c0c0",
    fontFamily: "MuliRegular"
  },
  focus: {
    ...focusStyles,
    borderColor: "rgba(153, 153, 153, 1)"
  },
  input: {
    ...inputStyles,
    borderBottomWidth: 1,
    borderBottomColor: "#c5c0c0",
    width: "100%",
    fontSize: "16px",
    color: "#fff",
    fontFamily: "MuliBold",
    padding: "16px 0px 10px",
    backgroundColor: "transparent"
  },
  label: {
    ...labelStyles,
    paddingBottom: "0px",
    marginBottom: "0px",
    width: "100%",
    fontSize: "12px",
    color: "rgba(255, 255, 255, 0.8)",
    fontFamily: "MuliRegular"
  }
};
const Genders = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" }
];

const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    globalThis.setState({ isCompleted: completed });
    return false;
  } else {
    return (
      <span className="timer-resend">
        {" "}
        Expires in {minutes}:{seconds}
      </span>
    );
  }
};

var globalThis = this;

export default class Signup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gender: "",
      phoneNo: "",
      phoneNetwork: "",
      username: "",
      dobdate: new Date(moment().subtract(18, "years")),
      otp: "",
      referralCode: "",
      errors: {},
      emailValid: false,
      passwordValid: false,
      firstNameValid: false,
      lastNameValid: false,
      genderValid: false,
      phoneNoValid: false,
      phoneNetworkValid: false,
      usernameValid: false,
      dobdateValid: false,
      showDatePicker: false,
      showOtpScreen: false,
      showReferralSection: false,
      checkboxChecked1: false,
      formValid: false,
      checkboxChecked2: false,
      submitClick: false,
      isValidVar: false,
      user_type: this.props.user_type,
      isLoading: false,
      posting: false,
      postingOtp: false,
      PhoneCode: null,
      country_list: [],
      selectedCountry: ''
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  showPassword = () => {
    this.setState({
      isShowPassword: !this.state.isShowPassword
    });
  };
  handleChange = date => {
    this.setState({
      dobdate: date,
      showDatePicker: false
    });
  };
  isValid() {
    const { errors, isValid } = ValidateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  checkboxCheckedFn1 = () => {
    this.setState({
      checkboxChecked1: !this.state.checkboxChecked1
    });
  };

  checkboxCheckedFn2 = () => {
    this.setState({
      checkboxChecked2: !this.state.checkboxChecked2
    });
  };

  showReferralSectionFn = () => {
    this.setState({
      showReferralSection: !this.state.showReferralSection
    });
  };

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  handleGenderChange = selectedOption => {
    this.setState({
      gender: selectedOption.value,
      selectedGender: selectedOption
    });
  };
  handleNetworkChange = selectedNetwork => {
    this.setState({
      phoneNetwork: selectedNetwork.value,
      selectedNetwork: selectedNetwork
    });
  };

  selectChange = (val, key) => {
    this.setState({
      [key]: val,
      [`selected${key}`]: val
    })
  }


  componentDidMount() {
    WSManager.Rest(NC.baseURL + NC.GET_ALL_COUNTRY, {}).then(responseJson => {
      if (responseJson.response_code === 200) {

        let getNigeria = responseJson.data.country_list.find(c => c.phonecode === '232');
        let phone_code = null;
        if (getNigeria) {
          phone_code = getNigeria
        }
        this.setState({
          country_list: responseJson.data.country_list, PhoneCode: phone_code
        })
      }
    })
  }

  IsFormValid() {
    const {
      email,
      password,
      firstName,
      lastName,
      gender,
      phoneNo,
      phoneNetwork,
      username,
      dobdate,
      PhoneCode
    } = this.state;

    var isValid = true;

    if (
      Validation.validate("required", firstName) !== "success" ||
      Validation.validate("first_name", firstName) !== "success"
    ) {
      isValid = false;
    } else if (
      Validation.validate("required", lastName) !== "success" ||
      Validation.validate("last_name", lastName) !== "success"
    ) {
      isValid = false;
    } else if (
      Validation.validate("required", phoneNo) !== "success" ||
      Validation.validate("phone_no", phoneNo) !== "success"
    ) {
      isValid = false;
    } 
    // else if (
    //   Validation.validate("required", username) !== "success" ||
    //   Validation.validate("userName", username) !== "success"
    // ) {
    //   isValid = false;
    // } 
    else if (gender === "select" || gender === "") {
      isValid = false;
    } 
    // else if (phoneNetwork === "Network" || phoneNetwork === "") {
    //   isValid = false;
    // } 
    // else if (
    //   Validation.validate("required", password) !== "success" ||
    //   Validation.validate("password", password) !== "success"
    // ) {
    //   isValid = false;
    // } 
    else if (
      email !== "" &&
      Validation.validate("email", email) !== "success"
    ) {
      isValid = false;
    } 
    else if (dobdate === "") {
      isValid = false;
    }
    else if (PhoneCode === "" || PhoneCode === null) {
      isValid = false;
    }
    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      submitClick: true,
      showDatePicker: false
    });

    if (this.IsFormValid()) {
      this.setState({ isLoading: true, posting: true });
      let param = {
        terms: this.state.checkboxChecked1,
        shareinfo: this.state.checkboxChecked2,
        email: this.state.email,
        // password: md5(this.state.password),
        device_type: NC.deviceType,
        device_id: NC.deviceID,
        source_type: "5",
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        gender: this.state.gender,
        phone_no: this.state.phoneNo,
        // phone_network: this.state.phoneNetwork,
        // user_name: this.state.username,
        referral_code: this.state.referralCode,
        dob: moment(this.state.dobdate).format("MMM DD, YYYY"),
        user: this.state.user_type,
        phone_code: this.state.PhoneCode ? this.state.PhoneCode.phonecode : '',
        master_country_id: this.state.selectedCountry.master_country_id,
      };

      WSManager.Rest(NC.baseURL + NC.SIGNUP_OTP, param).then(responseJson => {
        this.setState({ isLoading: false, posting: false });
        if (responseJson && responseJson.response_code === NC.successCode) {
          notify.show(responseJson.message, "success", 5000);
          this.setState({
            showOtpScreen: true
          });
        }
      });
    }
  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };



  onOtpSubmit(e) {
    e.preventDefault();
    if (this.IsFormValid()) {
      this.setState({
        isLoading: true,
        postingOtp: true
      });

      let param = {
        terms: this.state.checkboxChecked1,
        shareinfo: this.state.checkboxChecked2,
        email: this.state.email,
        password: md5(this.state.password),
        device_type: NC.deviceType,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        gender: this.state.gender,
        phone_no: this.state.phoneNo,
        phone_network: this.state.phoneNetwork,
        // user_name: this.state.username,
        referral_code: this.state.referralCode,
        otp: this.state.otp,
        dob: moment(this.state.dobdate).format("MMM DD, YYYY"),
        user: this.state.user_type,
        phone_code: this.state.PhoneCode ? this.state.PhoneCode.phonecode : '',
        master_country_id: this.state.selectedCountry.master_country_id,
      };

      WSManager.Rest(NC.baseURL + NC.SIGNUP, param).then(responseJson => {
        this.setState({ isLoading: false, postingOtp: false });
        if (responseJson.response_code === NC.successCode) {
          this.props.IsSignupModalHide();
          WSManager.setToken(responseJson.data.session_key);
          WSManager.setProfile(responseJson.data.user_profile);
          WSManager.setProfileCompleted(
            responseJson.data.user_profile.is_profile_complete
          );
          Header.CompleteProfileShow();
        }
      });
    }
  }

  EditPhoneNo = () => {
    this.setState({
      showOtpScreen: false
    });
  };

  render() {
    const { IsSignupModalShow, IsSignupModalHide, isOpenFrom } = this.props;

    const {
      email,
      password,
      firstName,
      lastName,
      gender,
      phoneNo,
      phoneNetwork,
      username,
      isShowPassword,
      isLoading,
      dobdate,
      otp,
      referralCode,
      showReferralSection,
      submitClick,
      posting,
      postingOtp,
      country_list,
      PhoneCode,
      selectedCountry
    } = this.state;




    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            show={IsSignupModalShow}
            onHide={IsSignupModalHide}
            dialogClassName={
              "custom-modal-with-bg" +
              (this.state.showOtpScreen ? " custom-otp-modal" : "")
            }
            className="signup-modal"
          >
            {isLoading && <Loader />}
            <div
              className={
                "modal-header-bg" +
                (this.state.showOtpScreen ? " modal-header-without-bg" : "")
              }
            >
              {this.state.user_type === "pundit" && (
                <a
                  href="/dashboard/0"
                  className="modal-close-btn"
                  onClick={IsSignupModalHide}
                >
                  <i className="icon-close" />
                </a>
              )}

              {this.state.user_type !== "pundit" && (
                <a
                  href
                  className="modal-close-btn"
                  onClick={() =>
                    this.state.showOtpScreen
                      ? this.EditPhoneNo()
                      : window.location.href == NC.baseURL + "signup"
                        ? window.location.assign("/dashboard/0")
                        : IsSignupModalHide()
                  }
                >
                  <i className="icon-close" />
                </a>
              )}

              {!this.state.showOtpScreen && (
                <React.Fragment>
                  <img src={Images.LOGO} alt="" />
                  <div className="modal-title">for the fans</div>
                </React.Fragment>
              )}
            </div>
            <div className="modal-overlay" />

            <Modal.Body>
              {!this.state.showOtpScreen && (
                <React.Fragment>
                  <form className="form-section" onSubmit={this.onSubmit}>
                    <div className="verification-block double-input-container">
                      <Row>
                        <Col xs={6} className="position-relative">
                          <FormGroup
                            validationState={
                              submitClick &&
                              Validation.validate("required", firstName) &&
                              Validation.validate("first_name", firstName)
                            }
                            className="input-label-center "
                            controlId="formBasicText"
                          >
                            <FloatingLabel
                              styles={formInputStyle}
                              id="first-name"
                              name="firstName"
                              placeholder="First Name"
                              type="text"
                              value={firstName}
                              maxLength="50"
                              autocomplete="off"
                              onChange={this.onChange}
                            />
                          </FormGroup>
                          <div className="dual-input-separater" />
                        </Col>
                        <Col xs={6}>
                          <FormGroup
                            validationState={
                              submitClick &&
                              Validation.validate("required", lastName) &&
                              Validation.validate("last_name", lastName)
                            }
                            className="input-label-center "
                            controlId="formBasicText"
                          >
                            <FloatingLabel
                              styles={formInputStyle}
                              id="last-name"
                              name="lastName"
                              placeholder="Last Name"
                              type="text"
                              maxLength="50"
                              value={lastName}
                              autocomplete="off"
                              onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="verification-block">
                      <Row>
                        <Col sm={12}>
                          <FormGroup className="input-label-center input-transparent font-14 dob-date-picker">
                            <div className="datepicker_display">
                              <label>Birthday</label>
                              <div className="dobField">
                                <DatePicker
                                  className="date-picker-custom"
                                  required={true}
                                  activeStartDate={today}
                                  minDetail="decade"
                                  locale="en-IN"
                                  onChange={this.handleChange}
                                  maxDate={today}
                                  value={dobdate}
                                  isOpen={this.state.showDatePicker}
                                />

                                <label
                                  onClick={() =>
                                    this.setState({
                                      showDatePicker: !this.state.showDatePicker
                                    })
                                  }
                                  className="dob-text"
                                >
                                  <Moment
                                    date={dobdate}
                                    format="MMM DD, YYYY"
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              className={
                                "dob-border col-sm-12" +
                                (dobdate !== "" ? "" : " has-error")
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="verification-block mt-0 p-0">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className={
                              "input-label-center input-transparent font-14 " +
                              (submitClick
                                ? gender !== "select" && gender !== ""
                                  ? "success"
                                  : "has-error"
                                : "")
                            }
                          >
                            <div className="select-gender">
                              <label style={formInputStyle.label}>Gender</label>
                              <div className="genderStyle">
                                <Select
                                  onChange={this.handleGenderChange}
                                  options={Genders}
                                  classNamePrefix="secondary"
                                  className="select-secondary minusML10"
                                  placeholder="Select Gender"
                                  isSearchable={false}
                                  isClearable={false}
                                  value={this.state.selectedGender}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#023320"
                                    }
                                  })}
                                />
                              </div>
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                              <div className="gender-border col-sm-12" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    

                    {/* <div className="verification-block">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className={
                              "input-label-center input-transparent font-14 " +
                              (submitClick
                                ? PhoneCode !== "select" && PhoneCode !== "" && PhoneCode !== null
                                  ? "success"
                                  : "has-error"
                                : "")
                            }
                          >
                            <div className="select-gender">
                              <label style={formInputStyle.label}>Country Code</label>
                              <div className="genderStyle">
                                <Select
                                  onChange={e => this.selectChange(e, 'PhoneCode')}
                                  options={country_list}
                                  classNamePrefix="secondary"
                                  className="select-secondary minusML10"
                                  placeholder="Select Country Code"
                                  isSearchable={true}
                                  isClearable={false}
                                  value={PhoneCode}
                                  getOptionLabel={(option) => `${option.phonecode} (${option.country_name})`}
                                  getOptionValue={(option) => option.phonecode}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#023320"
                                    }
                                  })}
                                />
                              </div>
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                              <div className="gender-border col-sm-12" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div> */}

                    <div className="verification-block">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className="input-label-center phone-grp-reg"
                            validationState={
                              submitClick &&
                              Validation.validate("required", phoneNo) &&
                              Validation.validate("phone_no", phoneNo)
                            }
                            controlId="formBasicText"
                          >

                            <div className={`phone_code_sel ${(submitClick
                              ? PhoneCode !== "select" && PhoneCode !== "" && PhoneCode !== null
                                ? "success"
                                : "has-error"
                              : "")}`}>
                              <Select
                                onChange={e => this.selectChange(e, 'PhoneCode')}
                                options={country_list}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                placeholder="+232"
                                isSearchable={true}
                                isClearable={false}
                                value={PhoneCode}
                                getOptionLabel={(option) => option.phonecode}
                                getOptionValue={(option) => option.phonecode}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#023320"
                                  }
                                })}
                              />
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                            </div>

                            <RKFloatingLabelInput
                              className="rkfloatingLabelInput phone_num"
                              id="phoneno"
                              autocomplete="off"
                              type="text"
                              isNumberOnly={true}
                              name="phoneNo"
                              value={phoneNo}
                              placeholder="Phone Number"
                              onChange={this.onChange}
                            />
                          </FormGroup>
                          <Col xs={12}>
                            {submitClick ? (
                              Validation.validate("required", phoneNo) !==
                                "success" ||
                                Validation.validate("phone_no", phoneNo) !==
                                "success" ? (
                                  <div className="help-text">
                                    Please provide valid phone number as account
                                    verification link will be sent to you via SMS.
                                    Eg. 1234567892
                                  </div>
                                ) : (
                                  <div className="help-text">Eg. 1234567892</div>
                                )
                            ) : (
                                <div className="help-text">Eg. 1234567892</div>
                              )}
                          </Col>
                        </Col>
                      </Row>
                    </div>

                    <div className="verification-block mt-0 p-0">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            validationState={submitClick && selectedCountry === "" ? "error" : null}
                            className="input-label-center input-transparent font-14 "
                          >
                            <div className="select-field-default">
                              <label style={formInputStyle.label}>Country</label>
                              <div className="genderStyle">
                                <Select
                                  onChange={(e) => this.selectChange(e, 'selectedCountry')}
                                  options={country_list}
                                  classNamePrefix="secondary"
                                  className="select-secondary minusML10"
                                  placeholder="Select Country"
                                  value={selectedCountry}
                                  getOptionLabel={(option) => option.country_name}
                                  getOptionValue={(option) => option.master_country_id}
                                  isSearchable={true}
                                  isClearable={false}
                                  menuPlacement="auto"
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#023320"
                                    }
                                  })}
                                />
                              </div>
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                              <div className="gender-border col-sm-12" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    {/* <div className="verification-block">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className="input-label-center"
                            validationState={
                              submitClick &&
                              Validation.validate("required", username) &&
                              Validation.validate("userName", username)
                            }
                            controlId="formBasicText"
                          >
                            <FloatingLabel
                              styles={formInputStyle}
                              id="Username"
                              name="username"
                              placeholder="Username"
                              type="text"
                              value={username}
                              maxLength="12"
                              autocomplete="off"
                              onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12}>
                          <div className="help-text">
                            Username can’t be greater than 12 characters
                          </div>
                        </Col>
                      </Row>
                    </div> */}

                    {/* <div className="verification-block mt-0 p-0">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className={
                              "input-label-center input-transparent font-14 " +
                              (submitClick
                                ? phoneNetwork !== "Network" &&
                                  phoneNetwork !== ""
                                  ? "success"
                                  : "has-error"
                                : "")
                            }
                          >
                            <div className="select-gender">
                              <label style={formInputStyle.label}>
                                Phone Network
                              </label>
                              <div className="genderStyle">
                                <Select
                                  onChange={this.handleNetworkChange}
                                  options={Constants.networks}
                                  classNamePrefix="secondary"
                                  className="select-secondary minusML10"
                                  placeholder="Select Network"
                                  isSearchable={false}
                                  isClearable={false}
                                  value={this.state.selectedNetwork}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#023320"
                                    }
                                  })}
                                />
                              </div>
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                              <div className="gender-border col-sm-12" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div> */}


                    {/* <div className="verification-block">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            validationState={
                              submitClick &&
                              Validation.validate("required", password) &&
                              Validation.validate("password", password)
                            }
                            className="input-label-center "
                            controlId="formBasicText"
                          >
                            <FloatingLabel
                              styles={formInputStyle}
                              id="Password"
                              name="password"
                              placeholder="Password"
                              value={password}
                              type={isShowPassword ? "text" : "password"}
                              required
                              maxLength="50"
                              autocomplete="off"
                              onChange={this.onChange}
                            />
                          </FormGroup>
                          <a
                            href
                            className="hidden-align"
                            onClick={() => this.showPassword()}
                          >
                            {this.state.isShowPassword ? (
                              <img
                                src={Images.EYE_DARK_ICON}
                                width="23px"
                                className="opacity"
                                alt=""
                              />
                            ) : (
                                <img
                                  src={Images.EYE_CANCEL_DARK_GREEN_ICON}
                                  width="23px"
                                  alt=""
                                />
                              )}
                          </a>
                        </Col>
                        <Col xs={12}>
                          <div className="help-text">
                            Password is case sensitive, minimum 4 characters
                          </div>
                        </Col>
                      </Row>
                    </div> */}


                    <div className="verification-block">
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className="input-label-center "
                            controlId="formBasicText"
                            validationState={
                              submitClick && email !== ""
                                ? Validation.validate("email", email)
                                : "success"
                            }
                          >
                            <FloatingLabel
                              maxLength={100}
                              required
                              styles={formInputStyle}
                              id="email"
                              name="email"
                              placeholder="Email (Optional)"
                              autocomplete="off"
                              onChange={this.onChange}
                              type="email"
                              value={email}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>


                    {!showReferralSection && (
                      <div
                        className="text-center"
                        style={{ marginTop: 26, marginBottom: 65 }}
                      >
                        <a
                          href
                          onClick={this.showReferralSectionFn}
                          className="forgot-pwd-link mt-0"
                        >
                          Referral Code?
                        </a>
                      </div>
                    )}

                    {showReferralSection && (
                      <div
                        className="verification-block"
                        style={{
                          height: 82,
                          marginTop: 10,
                          marginBottom: 20,
                          minHeight: "auto"
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <FormGroup
                              className="input-label-center "
                              controlId="formBasicText"
                            >
                              <FloatingLabel
                                styles={formInputStyle}
                                id="referral-code"
                                name="referralCode"
                                placeholder="Referral Code"
                                value={referralCode}
                                type="text"
                                required
                                autocomplete="off"
                                onChange={this.onChange}
                              />
                              <i
                                className="icon-close input-hide"
                                onClick={() =>
                                  this.setState({
                                    showReferralSection: false,
                                    referralCode: ""
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <div className="user-mandatory">
                      <FormGroup className="custom-checkbox-wrap">
                        <Checkbox
                          checked={this.state.checkboxChecked1 ? true : false}
                          onChange={this.checkboxCheckedFn1}
                          className="custom-checkbox"
                          value=""
                          name="18Plus"
                          id="18Plus"
                        >
                          <span>I am 18+</span>
                        </Checkbox>
                      </FormGroup>
                      <FormGroup className="custom-checkbox-wrap">
                        <Checkbox
                          checked={this.state.checkboxChecked2 ? true : false}
                          onChange={this.checkboxCheckedFn2}
                          className="custom-checkbox i-agree-text"
                          value=""
                          name="terms"
                          id="terms"
                        >
                          <span>
                            {" "}
                            I agree to ReFPredictor
                            <a
                              target="__blank"
                              href={
                                NC.pageURL +
                                "public/app/app/views/ReFPredictorLeague-TermsService.pdf"
                              }
                            >
                              Terms of Service
                            </a>
                            &
                            <a
                              target="__blank"
                              href={
                                NC.pageURL +
                                "public/app/app/views/ReFPredictorLeague-PrivacyPolicy.pdf"
                              }
                            >
                              Privacy Policy.
                            </a>
                          </span>
                        </Checkbox>
                      </FormGroup>
                    </div>
                    <div className="text-center">
                      <Button
                        disabled={
                          posting ||
                          !this.state.checkboxChecked1 ||
                          !this.state.checkboxChecked2
                        }
                        className="btn btn-rounded btn-primary signup-btn"
                        type="submit"
                        onClick={this.onSubmit}
                      >
                        BECOME A REFPREDICTOR
                      </Button>
                    </div>
                    <div className="text-center user-help m-b-40">
                      Already a ReFPredictor?
                      <a
                        href
                        onClick={() =>
                          isOpenFrom === "login"
                            ? IsSignupModalHide()
                            : Header.LoginModalShow("signup")
                        }
                      >
                        {" "}
                        Login
                      </a>
                    </div>
                  </form>
                </React.Fragment>
              )}
              {this.state.showOtpScreen && (
                <OtpScreen
                  onChange={this.onChange.bind(this)}
                  phoneNo={phoneNo}
                  PhoneCode={PhoneCode}
                  otp={otp}
                  postingOtp={postingOtp}
                  formInputStyle={formInputStyle}
                  onOtpSubmit={this.onOtpSubmit.bind(this)}
                  handleOtpChange={this.handleOtpChange}
                  EditPhoneNo={() => this.EditPhoneNo()}
                />
              )}
            </Modal.Body>
          </Modal>
        )}
      </MyContext.Consumer>
    );
  }
}

export class OtpScreen extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dataNow: Date.now(),
      isCompleted: false
    };
  }

  componentWillMount = () => {
    globalThis = this;
  };

  ResendOtp = () => {
    let param = {
      phone_no: this.props.phoneNo,
      phone_code: this.props.PhoneCode.phonecode
    };
    WSManager.Rest(NC.baseURL + NC.RESEND_OTP, param).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        this.setState({ dataNow: Date.now() });
        this.setState({ isCompleted: false });
        notify.show(responseJson.message, "success", 5000);
      }
    });
  };

  render() {
    const { otp, postingOtp } = this.props;
    const { dataNow, isCompleted } = this.state;
    return (
      <MyContext.Consumer>
        {context => (
          <React.Fragment>
            <div className="otp-heading">Verify Your Mobile Number</div>
            <form
              className="form-section otp-form-section"
              onSubmit={this.props.onOtpSubmit}
            >
              <div className="verification-block">
                <Row>
                  <Col xs={12} className="otp-field">
                    <label>Enter OTP</label>
                    <OtpInput
                      autoComplete="off"
                      shouldautofocus={true}
                      numInputs={6}
                      value={otp}
                      onChange={this.props.handleOtpChange}
                      separator={<span />}
                    />
                  </Col>
                </Row>
              </div>

              <Col xs={12} className="text-center">
                {isCompleted ? (
                  <a href className="link-txt" onClick={() => this.ResendOtp()}>
                    RESEND
                  </a>
                ) : (
                    <Countdown date={dataNow + 300000} renderer={renderer} />
                  )}
              </Col>
              <div className="txt-verify-no">
                OTP is sent to <span>({this.props.PhoneCode.phonecode}) {this.props.phoneNo}</span>
                <a href onClick={() => this.props.EditPhoneNo()}>
                  <i className="icon-edit-pen" />
                </a>
              </div>

              <div className={'wp-mod-sh'}>
                <span className={'ic-grp'}>
                  <span>
                    <img src={Images.TEXTMSG_IMG} />
                  </span>
                  <span>
                    <img src={Images.WHATSAPP_IMG} />
                  </span>
                </span>
              </div>
              <div className="text-center">
                <Button
                  disabled={postingOtp || !otp}
                  className="btn btn-rounded btn-primary signup-btn"
                  type="submit"
                  onClick={() => this.props.onOtpSubmit}
                >
                  Verify
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
      </MyContext.Consumer>
    );
  }
}
