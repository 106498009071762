import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../../context';
import { Images, Header } from '../../components';
import { Helmet } from "react-helmet";
import MetaData from "../../helper/MetaData";
import Config from '../../Config';
import WSManager from "../../helper/WSManager";
import GroupChatHeader from './GroupChatHeader';
import GroupChatFooter from './GroupChatFooter';
import Slider from "react-slick";
import _ from 'lodash';

const users = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]
const users2 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]

export default class GroupChatParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            whichTab: 1,
            myGroups: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,],
            allGroups: [1, 1, 1, 1, 1, 1, 1],
        }
    }


    componentWillMount() {
        this.setState({
            propsData: this.props.location.state
        })
        // Header.updateHeaderShadow(false);
    }

    _isActiveTab = (tab) => {
        this.setState({
            whichTab: tab
        })
    }

    /**
     *HEADER NEW GROUP 
     */
    _newGroupHeader = () => {
        return <div>
            <div className='header-parent-new-group'>
                <div className='cursor-p'>
                    <img src={Images.BACK_ICON_CHAT}  onClick={()=>{this.props.history.goBack()}}/>
                </div>
                <div className='new-group-'>
                    New Group
                </div>
                <div className='m-l-15'>
                    <img src={Images.CHAT_MENU} />
                </div>


            </div>

        </div>

    }

    /***
     * NEW GROUP ADD DETAIL VIEW 
     */

    _addDetailView = () => {
        return <div className='add-group-detail'>
            <div className='center-data-2'>
                <div>
                    <img src={Images.DEMO_PROFILE} className='profile-holder' />
                </div>
                <div className='input-holder bottom-border'>
                    <input type='text' placeholder='Group Name' className='group-name-input' maxLength={25} />
                    <img src={Images.EMOJIS_BIG} className='emojis-big' />
                </div>
            </div>
            <div className='m-t-20'>
                <div className='description-title'>
                    Description
                </div>
                <div className='bottom-border'>
                    <input type='text' placeholder='Group Name' className='des-input' maxLength={30} placeholder={'30 words max'} />
                </div>
            </div>
            <div className='m-t-20 d-f'>
                <div className='fee-holder'>
                    <img src={Images.UNCHECK_C} className='cursor-p' />
                    <span className='fee-text m-l-10 cursor-p'>Free</span>
                </div>
                <div>
                    <img src={Images.CHECK_C} className='cursor-p' />
                    <span className='fee-text m-l-10 cursor-p'>Paid</span>
                </div>

            </div>
            <div className='m-t-20'>
                <div className='description-title'>
                    Entry fee
                </div>
                <div className='bottom-border'>
                    <input type='text' placeholder='Entry fee' className='des-input-2' maxLength={6} />
                </div>
            </div>

        </div>
    }


    /**
     *  SELECTED USER UI 
     */

    _selectedUsers = () => {
        return <div className='selected-user-parent'>
            <div className='layer-1'>
                {
                    _.map(this.state.myGroups, (item, idx) => {
                        return (

                            < div className='profile' >
                                <img src={Images.CROSS_ICON_DARK} className='cross-icon-right' />
                                <img src={Images.DEMO_PROFILE} className='profle-pic' />
                                <span className='username m-t-5'>@Villain</span>
                            </div>
                        )
                    })
                }

            </div>

        </div >
    }

    /**
     * ALL USER LIST CHECK WTIH TAB CLICK 
     */

    _allUsers = () => {
        return <div className='unselected-user-parent'>
            <div className='layer-1'>
                {
                    _.map(users2, (item, idx) => {
                        return (

                            < div className='profile' >
                                <img src={Images.DEMO_PROFILE} className='profle-pic' />
                                <span className='username m-t-5'>@Villain</span>
                            </div>
                        )
                    })
                }

            </div>

        </div >
    }


    /**
     * TAB 
     */
    _customeTab = () => {
        return <div className='tab-parent'>
            <div className='t1-active cursor-p'>
                <div className='title'>
                    CONNECTION
                </div>
                <div className='hr-line'>
                </div>
            </div>
            <div className='t1-deactive cursor-p'>
                <div className='title'>
                    FOLLOWERS
                </div>
                <div className='hr-line cursor-p'>
                </div>
            </div>
            <div className='t1-active'>
                <div className='title'>
                    FOLLOWING
                </div>
                <div className='hr-line'>
                </div>
            </div>
        </div>
    }

    render() {
        const HeaderOption = {
            menu: false,
            Notification: false,
            Logo: true,
            isShowHeader: false
        };
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container no-padding-b">
                        {/* <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.Chat.title}</title>
                            <meta name="description" content={MetaData.Chat.description} />
                            <meta name="keywords" content={MetaData.Chat.keywords} />
                        </Helmet> */}
                        <Header
                            {...this.props}
                            showNavigation={false}
                            HeaderOption={HeaderOption}
                            showFilter={this.showFilter}
                        />
                        {
                            this._newGroupHeader()
                        }
                        {
                            this._addDetailView()
                        }
                        {
                            this._selectedUsers()
                        }
                        {
                            this._customeTab()
                        }
                        {
                            this._allUsers()
                        }
                        <div className='center-data create-btn'>
                            <div className='continue-btn continue-btn'>
                                Create Group
                                    </div>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
