import React from "react";
import { Modal } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";

export default class TeamViewModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    const { IsTeamViewShow, IsTeamViewHide } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsTeamViewShow}
              onHide={IsTeamViewHide}
              bsSize="large"
              dialogClassName="modal-full-screen"
              className="center-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <a href onClick={IsTeamViewHide} className="modal-close">
                    <i className="icon-close" />
                  </a>
                  <div className="match-heading text-center">
                    <h4 className="header-title">WinFantasy</h4>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="lineup-header-action with-modal">
                  <ul>
                    <li>
                      <a href>
                        <i className="icon-pitch" />
                      </a>
                    </li>
                    <li>
                      <a href>
                        <i className="icon-listview" />
                      </a>
                    </li>
                  </ul>
                  <div className="lineup-header-action-right">
                    <p className="multi-txt">
                      <b>3</b> <span>/400 Rank</span>
                    </p>
                    <p className="multi-txt">
                      <b>2,000</b> <span>Pts</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="field-view cricket-ground">
                  <ul className="player-field-list">
                    <li>
                      <a href className="btn-remove-player">
                        <i className="icon-remove" />
                      </a>
                      <img src={Images.TEAM_JERSY} alt="" className />
                      <div className="player-name">K.S.Rang</div>
                      <div className="player-postion">WK | CSK | ₹8.5</div>
                    </li>
                    <li>
                      <a href className="btn-remove-player">
                        <i className="icon-remove" />
                      </a>
                      <img src={Images.TEAM_JERSY} alt="" className />
                      <div className="player-name">K.S.Rang</div>
                      <div className="player-postion">WK | CSK | ₹8.5</div>
                    </li>
                  </ul>
                </div>
              </Modal.Body>
              <i
                className="icon-close icc"
                onClick={() => {
                  IsTeamViewHide()
                }
                }
              />
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
